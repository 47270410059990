import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@bit/together-price.core.components.button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { FC, ReactNode } from 'react';
import { Trans } from 'react-i18next';

const useStyle = makeStyles((theme) => ({
  root: {},
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transform: 'translateY(-10%)',
  },
  contentGridRoot: {
    maxWidth: theme.breakpoints.values.sm,
  },
  body: {
    marginBottom: theme.spacing(12),
  },
  button: {
    textTransform: 'none',
    boxShadow: 'none',
    height: 55,
    borderRadius: theme.spacing(2),
    borderWidth: '1.5px !important',
  },
}));

export type FullPageErrorProps = {
  open: boolean;
  onClose?: () => void;
  icon: ReactNode;
  description?: ReactNode;
  header?: ReactNode;
};

const FullPageError: FC<FullPageErrorProps> = ({
  open = false,
  onClose,
  icon,
  description,
  header,
}) => {
  const classes = useStyle();

  return (
    <Dialog open={open} onClose={onClose} className={classes.root} fullScreen>
      <DialogContent className={classes.content}>
        <Grid
          container
          spacing={4}
          direction='column'
          alignItems='center'
          className={classes.contentGridRoot}
        >
          {header && <Grid item>{header}</Grid>}
          <Grid item>{icon}</Grid>
          <Typography
            variant='caption'
            color='textSecondary'
            align='center'
            className={classes.body}
          >
            {description}
          </Typography>
          <Button
            onClick={onClose}
            fullWidth
            color='primary'
            variant='outlined'
            className={classes.button}
          >
            <Trans i18nKey='NoConnection.button' />
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default FullPageError;
