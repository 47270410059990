
export type PixelData = {
  [key: string]: string,
}
export const dispatchPixel = <D = PixelData, >(pixelName: string, data?: D) => {
  const eventDetail = { detail: { pixelName, data } };
  window.dispatchEvent(new CustomEvent('pixel', eventDetail));
};
type PixelEvent<D = PixelData> = Event & {
  detail: {
    pixelName: string,
    data: D,
  },
}

export type PixelEventListener<D = PixelData> = (event: PixelEvent<D>) => void;
export const addPixelListener = <D = PixelData, > (
  pixelName: string,
  listener: PixelEventListener<D>,
) => window.addEventListener('pixel', (event: CustomEvent) => {
    if (event.detail.pixelName === pixelName) listener(event);
  });

window.addEventListener('pixel', (event: PixelEvent) => {
  console.info(`pixel ${event.detail.pixelName}`, event.detail.data);
});
