import { Component } from 'react';
import { observer } from 'mobx-react';
import navigatorStore from '../stores/navigator';
import modalStore from '../stores/modal';

@observer
class DevNavigator extends Component {
  componentDidMount = () => {
    document.addEventListener('keypress', ({ ctrlKey, shiftKey, code }) => {
      if (!ctrlKey || !shiftKey || code !== 'KeyN') return false;
      if (modalStore.isPending('form')) return modalStore.idle('form');

      modalStore.setForm('DevNavigator', this.entries, this.forms, this.validators, navigatorStore, null, this.onSubmit);
    });
  }

  onSubmit = () => {
    navigatorStore.stack(navigatorStore.route);
    modalStore.idle('form');
  }

  render() {
    return false;
  }

  get forms() {
    return [
      {
        entry: 'route',
        form: 'input',
        title: 'DevNavigator.route',
        placeholder: 'DevNavigator.routePlaceholder',
      },
    ];
  }

  get entries() {
    return ['route'];
  }

  get validators() {
    return [{ min: 1 }];
  }
}

export default DevNavigator;
