import { action, observable } from 'mobx';
import { ProfileStatus } from '../various/enums';
import Err from '../utils/error';
import authStore from './auth';
import networkStore from './network';
import Store from '../various/store';
import debounce from 'lodash/debounce';
import Ums from '../utils/ums';
import userStore from './user';

class ProfileStore extends Store {
  @observable filters;
  @observable history;
  @observable posts;
  @observable profile;
  @observable status;

  factory() {
    this.profile = {};
    this.posts = [];
    this.filters = {
      posts: 4,
      connections: 3,
    };
    this.history = [];
    this.status = {
      profile: ProfileStatus.PROFILE.PENDING,
      posts: ProfileStatus.POSTS.PENDING,
    };
  }

  getProfileFunc = async (id) => {
    let profile;

    this.set('status.profile', ProfileStatus.PROFILE.PENDING);

    profile = await Ums.get(`user/${id}`, authStore.token);
    if (Err.check(profile))
      return this.set('status.profile', ProfileStatus.PROFILE.ERROR);

    profile.relation.status =
      userStore?.user?.id === profile?.id
        ? 'me'
        : profile.relation?.status.toLowerCase();
    this.set('profile', profile);

    this.push(id);
    this.set('status.profile', ProfileStatus.PROFILE.SUCCESS);

    return true;
  };

  /**
   * Gets an user profile
   *
   * @param {number} id
   * @returns {boolean}
   */
  getProfile = debounce((id) => this.getProfileFunc(id), 200);
  /**
   * Gets the user posts
   *
   * @param {number} id
   * @returns {boolean}
   */
  async getPosts(id) {
    let posts;

    // This is a placeholder till the real back-end endpoint exists
    posts = { items: networkStore.network.data.posts.slice() };
    posts.count = posts.items.length;

    this.set('posts', posts);

    this.push(id, 'posts');

    this.set('status.posts', ProfileStatus.POSTS.SUCCESS);
  }

  /**
   * Pushes the current profile to the history array
   *
   * @param {number} id
   */
  @action push(id) {
    this.history.push({
      profile: this.profile,
      posts: this.posts,
      status: this.status,
    });
  }

  /**
   * Pop an item from the history
   *
   * @param {number} id
   */
  @action pop(id) {
    let index;

    index = this.history.findIndex((h) => h.id === id);
    if (index === -1) return false;

    this.history.splice(index, 1);
  }
}

const profileStore = new ProfileStore();
export default profileStore;
