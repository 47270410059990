import { has, isBoolean, isEmpty, isFunction, isString, last } from 'lodash';
import { action, observable } from 'mobx';
import recorder from '../utils/recorder';
import { Routes } from '../various/enums';
import Store from '../various/store';
import headerStore from './header';

class NavigatorStore extends Store {
  @observable blocked;

  @observable callbacks;

  @observable from;

  @observable history;

  @observable navigator;

  @observable params;

  @observable route;

  factory() {
    this.blocked = false;
    this.navigator = {};
    this.history = [];
    this.route = Routes.HOME;
    this.params = {};
    this.callbacks = {};
    this.from = '';
  }

  /**
   * Updates the route variable and does various checks at a graphical level
   */
  @action init() {
    // Get the route from the location pathname
    this.route = this.stripTrailingSlash(this.navigator.location.pathname);

    // Calculate the header shadow based on the current route
    headerStore.calculateShadow(this.route);

    // Calculate the header type based on the current route
    headerStore.calculateType(this.route);

    // Update hotjar tracking with the current route
    recorder.route(this.route);

    // Kill the toast if present
    // toastStore.kill()
  }

  /**
   * Navigates to a new route
   *
   * @param {string} route
   * @param {?object} params
   * @param {?boolean} clear
   */
  @action stack(route, params = null, clear = false) {
    if (this.isBlocked) return false;

    // Set the params inside the params store entry
    if (!isEmpty(params)) this.params = params;

    // Push inside our internal history array the just pushed route
    this.history.push(route);

    // Push a new route inside the specified stack navigator
    this.navigator.push(route);

    if (isBoolean(clear) && clear) this.runCallbacks();
    return true;
  }

  /**
   * Navigates to a new route without altering the stack history
   *
   * @param {string} route
   * @param {object} params
   */
  @action bottomTab(route, params) {
    // Push inside the bottom tab history the just pushed tab
    this.history = [route];

    // Navigate through the react router push history method
    this.navigator.replace(route);

    this.runCallbacks();
  }

  @action topTab(route, params) {
    // Change the last item in the history that is for sure the TopTab
    this.history[this.history.length - 1] = route;

    // Navigate through the react router push history method
    this.navigator.replace(route);
  }

  /**
   * Navigates the the previous route
   */
  @action back() {
    if (this.isBlocked) return false;

    // Check if the history stack has at least two items to avoid a terrible crash
    if (this.history.length < 2) return false;

    // Execute the callback in the callbacks array
    if (has(this.callbacks, last(this.history)))
      this.callbacks[last(this.history)]();

    // Pop the last item from the history
    this.history.pop();

    // Remove the last element from the stack history
    this.navigator.goBack();
  }

  /**
   * Replaces the native redirect method of react-router
   *
   * @param {string} to
   * @param {string} replace
   */
  @action redirect(to, replace = '') {
    if (this.isBlocked) return false;

    // Check if the history stack has at least one item to avoid a terrible crash
    if (this.history.length < 1) return false;

    let route;

    if (to) route = to;
    if (replace) route = this.route.replace(replace, '');

    if (!route) return false;

    this.history[this.history.length - 1] = route;

    this.navigator.replace(route);

    return true;
  }

  /**
   * Wipes the stack history with an optional starting point
   *
   * @param {string} route
   */
  @action root(stack) {
    if (stack === 'boot') this.history = ['/login'];
    if (stack === 'onboarding') this.history = ['/welcome'];
    if (stack === 'auth') this.history = ['/home/full'];
  }

  /**
   * Execute all callbacks
   */
  @action runCallbacks() {
    Object.values(this.callbacks).forEach(
      (callback) => isFunction(callback) && callback()
    );
  }

  get isFromPresent() {
    if (this.isBlocked || !isString(this.from) || isEmpty(this.from))
      return false;
    return true;
  }

  @action gotoFrom() {
    if (
      this.isBlocked ||
      !isString(this.from) ||
      isEmpty(this.from) ||
      /**
       * ? INFO(suley):
       * ? avoid to go to same page, this bad behaviour breaks all back buttons
       * ? if user refresh page and click back page, user stays in same page
       */
      this.from === this.route
    ) {
      return false;
    }

    this.root('auth');
    // Clear the history stack
    this.history = [];
    // Navigate to the from route
    this.stack(this.from + window.location.search);

    // Clear it from the store
    this.from = '';
    return true;
  }

  @action block() {
    this.blocked = true;
  }

  @action unblock() {
    this.blocked = false;
  }

  stripTrailingSlash(pathname = '') {
    return pathname.replace(/\/$/, '');
  }

  setFrom(pathname = '') {
    this.from = this.canSetFrom(pathname) ? pathname.replace(/\/$/, '') : null;
    return this.from;
  }

  canSetFrom(pathname = '') {
    return (
      !pathname.replace(/\/$/, '').includes('welcome') &&
      !pathname.replace(/\/$/, '').includes('disney-plus-landing') &&
      !pathname.replace(/\/$/, '').includes('login') &&
      !pathname.replace(/\/$/, '').includes('sign-up') &&
      !pathname.replace(/\/$/, '').includes('forgot-password') &&
      !pathname.replace(/\/$/, '').includes('reset-password') &&
      !pathname.replace(/\/$/, '').includes('complete-sign-up') &&
      !pathname.replace(/\/$/, '').includes('i/') &&
      !pathname.replace(/\/$/, '').includes('signup') &&
      !pathname.replace(/\/$/, '').includes('public') &&
      pathname !== '' &&
      pathname !== '/'
    );
  }

  get isBlocked() {
    return this.blocked === true;
  }

  get isFromPost() {
    return this.from.match(/\/post\/\d+/gm);
  }
}

const navigatorStore = new NavigatorStore();
export default navigatorStore;
