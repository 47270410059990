import { computed, observable } from 'mobx';
import { cloneDeep, map } from 'lodash';
import Server from '../utils/server';
import authStore from './auth';
import Err from '../utils/error';
import { TrustStatus } from '../various/enums';
import Store from '../various/store';
import Ums from '../utils/ums';

class TrustStore extends Store {
  @observable achievements

  @observable details

  @observable level

  /**
   * @typedef Object Mission
   * @property missionName {string}
   * @property points {number}
   * @property imagePath {string}
   * @property description {string}
   */
  /**
   * @property missions {Mission[]}
   */
  @observable missions

  @observable status

  factory() {
    this.level = 0;
    this.achievements = [];
    this.missions = [];
    this.details = {};
    this.status = TrustStatus.PENDING;
  }

  /**
   * Gets the trust level, achievements and missions of the logged user
   *
   * @returns {boolean}
   */
  async get() {
    let response;

    // Get the full user profile with level, achievements and missions
    response = await Server.get('users', authStore.token);
    if (Err.check(response)) return this.set('status', TrustStatus.ERROR);

    response.data.achievements = map(response.data.achievements, (v, i) => ({ ...v, id: i }));
    response.data.missions = map(response.data.missions, (v, i) => ({ ...v, id: i }));

    // Save everything we obtained to the store and convert the objectified arrays to real arrays for later use
    this.set('level', response.data.trust);
    this.set('achievements', response.data.achievements);
    this.set('details', response.data.trustDetails);

    // If the code arrived here then it is a success
    this.set('status', TrustStatus.SUCCESS);

    return true;
  }

  async getMissions() {
    const missions = await Ums.get('/missions/getMissionsDescriptions', authStore.token);
    if (Err.check(missions)) return this.set('status', TrustStatus.ERROR);
    this.set('missions', missions);
    this.set('status', TrustStatus.SUCCESS);
  }

  @computed get _missions() {
    return cloneDeep(this.missions);
  }
}

const trustStore = new TrustStore();
export default trustStore;
