import { action, observable } from 'mobx';
import { isString } from 'lodash';
import Err from '../utils/error';
import Store from '../various/store';

class ToastStore extends Store {
  @observable color;

  @observable hint;

  @observable icon;

  @observable inject;

  @observable link;

  @observable opacity;

  @observable translateY;

  @observable ms;

  factory() {
    this.color = 'black';
    this.icon = 'md-checkmark';
    this.hint = 'This is a placeholder text';
    this.inject = '';
    this.link = '';
    this.opacity = 0;
    this.translateY = 84;
    this.ms = 5000;
  }

  /**
   * Initializes and show a custom Toast
   *
   * @param {string} color
   * @param {string} icon
   * @param {string | import('react').ReactNode} hint
   * @returns {boolean}
   */
  @action custom(color, icon, hint, inject = '', link = '', ms = 5000) {
    // If one of the parameters is not valid then just refuse to proceed
    if (!(isString(color) && isString(icon))) return false;

    // Save the new values to the store
    this.color = color;
    this.icon = icon;
    this.hint = hint;
    this.inject = inject || '';
    this.link = link || '';
    this.ms = ms;

    // And animate the toast
    this.animate();
  }

  /**
   * Shows an Error Toast with a specific message
   *
   * @param {string} code
   * @param inject
   * @param link
   * @param prefix
   * @returns {boolean}
   */
  @action error(code, inject = null, link = null, prefix = 'Error', ms = 5000) {
    if (!isString(code)) return Err.type('ToastStore', 'error');

    this.color = 'red';
    this.hint = code.includes('.') && prefix ? `${prefix}.${code}` : code;
    this.icon = 'ios-alert';
    this.inject = inject || '';
    this.link = link || '';
    this.ms = ms;

    this.animate();

    return false;
  }

  /**
   * Shows a Success Toast with a specific message
   *
   * @param {string | import('react').ReactNode} code
   * @returns {boolean}
   */
  @action success(
    code,
    inject = null,
    link = null,
    prefix = 'Success',
    ms = 5000
  ) {
    if (!code) return Err.type('ToastStore', 'success');

    this.color = 'violet';
    this.hint =
      typeof code === 'string' && code.includes('.') && prefix
        ? `${prefix}.${code}`
        : code;
    this.icon = 'ios-checkmark-circle';
    this.inject = inject || '';
    this.link = link || '';
    this.ms = ms;

    this.animate();

    return true;
  }

  /**
   * Shows a Warn Toast with a specific message
   *
   * @param {string | import('react').ReactNode} code
   * @returns {boolean}
   */
  @action warn(code, inject = null, link = null, ms = 5000) {
    if (!code) return Err.type('ToastStore', 'warn');

    this.color = 'yellow';
    this.hint = code;
    this.icon = 'ios-alert';
    this.inject = inject || '';
    this.link = link || '';
    this.ms = ms;

    this.animate();

    return true;
  }

  /**
   * Shows an Informative Toast with a specific message
   *
   * @param {string} code
   * @returns {boolean}
   */
  @action info(code, inject, link, ms = 5000) {
    if (!isString(code)) return Err.type('ToastStore', 'info');

    this.color = 'black';
    this.hint = `Info.${code}`;
    this.icon = 'ios-information-circle';
    this.inject = inject || '';
    this.link = link || '';
    this.ms = 5000;

    this.animate();

    return true;
  }

  /**
   * Shows the toast for 5s and then hides it
   */
  @action animate() {
    // REFACTOR: I can not animate this bitch without css here
    this.opacity = 1;
    this.translateY = 0;
    setTimeout(() => {
      this.set('opacity', 0);
      this.set('translateY', 84);
    }, this.ms || 5000);
  }

  /**
   * Hides the toast
   */
  @action kill() {
    this.opacity = 0;
    this.translateY = 84;
  }
}

const toastStore = new ToastStore();
export default toastStore;
