import React, { FunctionComponent } from 'react';
import { Hidden } from '@material-ui/core';
import Header, {
  MobileHeaderProps as HeaderProps,
} from '@bit/together-price.core.components.mobile-header';
import PageHeader from '@bit/together-price.core.components.page-header';
import { useHistory } from 'react-router-dom';
import navigatorStore from '../stores/navigator';

export type MobileHeaderProps = { noBack?: boolean } & Partial<HeaderProps>;
const MobileHeader: FunctionComponent<MobileHeaderProps> = ({
  noBack,
  ...props
}) => {
  const history = useHistory();

  const defaultOnBack = () => {
    if (history.length > 0) {
      return history.goBack();
    }
    return history.push('/');
  };

  return (
    <Hidden mdUp>
      <PageHeader>
        <Header
          navigateToUrl={(url) => navigatorStore.stack(url)}
          {...props}
          onBack={!noBack && defaultOnBack}
        />
      </PageHeader>
    </Hidden>
  );
};

export default MobileHeader;
