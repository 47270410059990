import React, { Component } from 'react';
import {
  filter,
  get,
  has,
  isArray,
  isFunction,
  isObject,
  isString,
} from 'lodash';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import Span from '../natives/Span';
import Validate from '../utils/validate';
import FormItem from './FormItem';
import TextInput from '../natives/TextInput';
import Row from '../layout/Row';
import TouchableSpan from '../touchables/TouchableSpan';
import Button from '../components/Button';

@observer
class FormInput extends Component {
  state = {
    focused: false,
    hints: [],
  };

  onChangeTextInputMask = (ref) => {
    this.textInputMask = ref;
  };

  onFocus = () => {
    this.setState({ focused: true });
  };

  onBlur = () => {
    this.setState({ focused: false });
  };

  onChangeText = (e, raw) => {
    let value;

    const { store, entry, mask, hint, callback, type } = this.props;

    if (isString(mask)) value = raw;
    else if (type === 'date') value = e[0];
    else if (type === 'city') value = has(e, 'gmaps.name') ? e.gmaps.name : '';
    else value = e.target.value;

    switch (this.props.textCase) {
      case 'uppercase':
        value = String(value).toUpperCase();
        break;
      case 'lowercase':
        value = String(value).toLowerCase();
        break;
    }
    store.set(entry, value);

    if (isArray(hint)) this.getHints(e.target.value);

    if (isFunction(callback)) callback();
  };

  getHints = (value) => {
    let data;
    let hints;

    data = this.props.hint;

    hints =
      value.length >= 3
        ? filter(data, (_h) => {
            let h;
            let v;

            if (!isString(_h)) return false;

            h = _h.toLowerCase();
            v = value.toLowerCase();

            return h.includes(v);
          })
        : [];

    hints = hints.sort((a, b) => a.length - b.length);
    hints = hints.slice(0, 5);

    this.setState({ hints });
  };

  onPressHint(hint) {
    const { store, entry } = this.props;

    store.set(entry, hint);

    this.setState({ hints: [] });
  }

  render() {
    const {
      id,
      store,
      entry,
      disabled,
      mask,
      placeholder,
      style,
      width,
      stripe,
      secureTextEntry,
      autoComplete,
      readOnly,
      maxLength,
      textarea,
      type,
      hint,
      disabledStyle,
      min,
      max,
      dateOptions,
      button,
      stripeOptions,
      onClick,
    } = this.props;
    const { focused, hints } = this.state;

    // Check if the TextInput value is valid through this handy Validate method
    const valid = Validate.exec(this.props.validator, store, entry, true);

    // Get the value with lodash get function
    const value = get(store, entry);

    // Check if there is an error for this input
    const hasError = isObject(valid) && value && !focused;

    const color = this.props.color || 'violet';
    const pColor = this.props.placeholderStyle || '';
    // Define the TextInput props
    const className = clsx(
      'ff',
      pColor,
      {
        'mt3 mnh80': textarea,
        mnh40: !textarea,
      },
      'bw0 ts14',
      {
        [color]: !!value,
      },
      (!value || disabled) &&
        !stripe &&
        (disabledStyle || (!pColor.includes('ph-o') && 'o30'))
    );
    const textInputProps = {
      disabled,
      style: className,
      onChange: this.onChangeText,
      placeholder,
      value,
      mask,
      onFocus: this.onFocus,
      onBlur: this.onBlur,
      onChangeRef: this.onChangeTextInputMask,
      stripe,
      secureTextEntry,
      autoComplete,
      readOnly,
      onClick,
      id,
      textarea,
      maxLength,
      type,
      min,
      max,
      dateOptions,
      stripeOptions,
    };

    return (
      <FormItem
        {...this.props}
        style={`${style} bwb1 b-${
          focused ? 'violet' : hasError ? 'red' : color
        } ${width || 'wp100'}`}
      >
        <Row style='jcsb'>
          <TextInput {...textInputProps} />
          {button && (
            <Button
              title={button.title}
              color={button.color || 'white'}
              style='p2 ml4'
              textStyle='ts12'
              clipboard={button.clipboard}
              onPress={button.onPress}
              {...(button.icon ? { icon: button.icon } : {})}
            />
          )}
        </Row>
        {hint && (
          <Row style='fw'>
            {hints.map((hint, index) => (
              <TouchableSpan
                key={index}
                value={hint}
                style='bw1 b-violet ph2 pv1 mnh0 br1 mr2 mb2'
                textStyle='violet ts12'
                onPress={() => this.onPressHint(hint)}
              />
            ))}
          </Row>
        )}
        {hasError ? (
          <Span
            id={valid.id}
            inject={valid.inject}
            style={`ts12 br1 ${color === 'white' ? 'white' : 'red'}`}
            outerStyle='mb2 asfe'
          />
        ) : null}
      </FormItem>
    );
  }
}

export default FormInput;
