import jwtDecode from 'jwt-decode';

const getTokenExpirationTime = (token: string): number => {
  if (!token) return 0;
  const { exp = 0 } = jwtDecode(token);
  const currentDate = new Date().getTime();
  const expirationDate = exp * 1000;
  return expirationDate - currentDate;
};

export default getTokenExpirationTime;
