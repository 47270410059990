import React, { PureComponent } from 'react';
import _ from 'lodash';
import { Clipboard } from '@capacitor/clipboard';
import Div from './Div';
import toastStore from '../stores/toast';

class Touchable extends PureComponent {
  onPress = async (e) => {
    e.stopPropagation();

    const {
      disabled, clipboard, onPress, onPressDisabled,
    } = this.props;

    try {
      // If the touchable is not disabled and it has a valid onPress function then exec this function
      if (!disabled && _.isFunction(onPress)) await onPress(e);
      if (disabled && _.isFunction(onPressDisabled)) await onPressDisabled(e);
    } catch (e) {
      console.error(e);
    }

    // If the clipboard prop is defined try to copy the given text
    try {
      if (_.isString(clipboard) && !_.isEmpty(clipboard)) {
        await Clipboard.write({
          string: clipboard,
        });
        this.onSuccess();
      }
    } catch (e) {
      this.onError(e);
    }
  }

  onSuccess = () => toastStore.success('Touchable.clipboard')

  onError = () => {}

  render() {
    const {
      disabled, style, animated, children,
    } = this.props;

    const props = {
      onPress: this.onPress,
      disabled,
      style: `touchable ${style}`,
      animated,
    };

    return <Div {...props}>{children}</Div>;
  }
}

export default Touchable;
