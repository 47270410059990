const convertToSharingPost = (posts) =>
  Object.values(posts).map((post: any) => ({
    admin: {
      id: post.admin.userId,
      image: String(post.admin.imagePath).includes('https:')
        ? post.admin.imagePath
        : 'https://d1ug1wtffjdh7z.cloudfront.net/' + post.admin.imagePath,
      name: post.admin.adminName,
      surname: post.admin.adminSurname,
      trust: post.trust,
      ...post.admin,
    },
    details: {
      createdAt: post.createdAt,
      freeSlots: post.freeAvailability,
      frequency: post.frequency,
      id: post.sharingPostId,
      image: post.imagePath,
      isPublic: !post.hide,
      fee: post.feePrice,
      price: post.monthlyPrice,
      serviceGroupId: post.version,
      serviceId: post.serviceId,
      serviceTag: post.tags[0],
      status: post.active,
      subscription: String(post.frequency).toUpperCase(),
      title: post.postTitle,
      totalSlots: post.totalAvailability,
      category: post.category,
      categoryId: post.categoryId,
      joiners: post.joiners,
      marketCountry: post.marketCountry,
      points: post.points,
      postDescription: post.postDescription,
      relation: post.relation,
      secretStatus: post.secretStatus,
      updatedAt: post.updatedAt,
      version: post.updatedAt,
    },
    me: {},
    rating: post.secretStatus !== 'MISSING' && post.secretStatus,
  }));

export default convertToSharingPost;
