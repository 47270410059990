import { observable } from 'mobx';
import authStore from './auth';
import Store from '../various/store';
import Paging from '../various/paging';
import Network from '../utils/network';
import trackingStore from './tracking';
import convertToSharingPost from '../functions/convertToSharingPost';
import userStore from './user';

class SearchStore extends Store {
  @observable posts;

  @observable query;

  factory() {
    this.posts = new Paging(() => this.getPosts());
    this.query = '';
  }

  search(query) {
    this.set('query', query);
  }

  /**
   * Searches for the posts that match the query variable
   *
   * @returns {boolean}
   */
  async getPosts() {
    if (userStore.configurations['frontEnd.query.on.es.enabled']) {
      const result = await Network.get(
        `carousel/search?direction=desc&props=admin.trust&q=${this.query}&page=${this.posts.current}`,
        authStore.token
      );
      return { ...convertToSharingPost(result) };
    } else {
      return await Network.get(
        `search/byTitle?title=${this.query}&page=${this.posts.current}`,
        authStore.token
      );
    }
  }
}

const searchStore = new SearchStore();
export default searchStore;
