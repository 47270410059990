import React, { PureComponent } from 'react';
import { isBoolean, isEmpty, isFunction, isNumber, isObject } from 'lodash';
import { CircularProgress } from '@material-ui/core';
import NotFound from '../components/NotFound';
import Div from '../natives/Div';

class List extends PureComponent {
  mounted = true;
  ref = {};
  state = {
    spinner: false,
    limit: 25,
  };

  setRef = (ref) => {
    if (isFunction(this.props.getRef)) this.props.getRef(ref);

    this.ref = ref;
  };

  componentDidMount = () => {
    if (isFunction(this.props.onEndReached))
      window.addEventListener('scroll', this.onScrollEnd);
    if (isObject(this.ref) && this.ref.scrollHeight < window.innerHeight)
      this.onEndReached();
  };

  componentWillUnmount = () => {
    this.mounted = false;
    if (isFunction(this.props.onEndReached))
      window.removeEventListener('scroll', this.onScrollEnd);
  };

  onScrollEnd = (e) => {
    if (
      window.scrollY + 50 >= document.body.clientHeight - window.innerHeight &&
      !this.state.spinner
    )
      this.onEndReached();
  };

  onEndReached = async () => {
    if (!this.mounted) return false;

    this.setState({ spinner: true });
    try {
      await this.props.onEndReached();
      this.setState({ limit: this.state.limit + 25 });
      this.setState({ spinner: false });
    } catch (e) {
      this.setState({ spinner: false });
    }
  };

  ListEmptyComponent = () => {
    if (isBoolean(this.props.renderEmpty) && !this.props.renderEmpty)
      return false;
    if (isFunction(this.props.renderEmpty)) return this.props.renderEmpty();
    // If there are no elements on the list we just return the NotFound component
    return <NotFound {...this.props.notFound} />;
  };

  ListHeaderComponent = () => {
    if (isFunction(this.props.renderHeader)) return this.props.renderHeader();
  };

  render() {
    const {
      horizontal,
      style,
      outerStyle,
      renderItem,
      onEndReached,
      overflow,
      header,
    } = this.props;
    const { spinner, limit } = this.state;
    const data = !isEmpty(this.props.data)
      ? isFunction(onEndReached)
        ? this.props.data.slice(0, limit)
        : isNumber(this.props.limit)
        ? this.props.data.slice(0, this.props.limit)
        : this.props.data.slice()
      : [];
    return (
      <Div style={`wp100 fc aic ${outerStyle}`}>
        {(isBoolean(header) ? header : !isEmpty(this.props.data)) &&
          this.ListHeaderComponent()}
        {!isEmpty(this.props.data) ? (
          <Div
            setRef={this.setRef}
            style={`wp100 fr ${
              overflow || (horizontal ? 'ofxa' : 'fw ofya')
            } ${style}`}
          >
            {data.map((data, index) => renderItem({ item: data, index }))}
          </Div>
        ) : (
          this.ListEmptyComponent()
        )}
        {spinner ? (
          <CircularProgress color='primary' size={24} className='mv4' />
        ) : null}
      </Div>
    );
  }
}

export default List;
