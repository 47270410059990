import { isEmpty, isNumber, isObject, isString } from 'lodash';
import { action, computed, observable } from 'mobx';
import Err from '../utils/error';
import Server from '../utils/server';
import Ums from '../utils/ums';
import { ContactStatus } from '../various/enums';
import { ContactPreviewModel, ProviderContactModel } from '../various/models';
import Paging from '../various/paging';
import Store from '../various/store';
import authStore from './auth';
import toastStore from './toast';

// Possible errors for social merge
const errors = [
  'FACEBOOK_ID_ALREADY_CONNECTED',
  'IMPORT_LIMITER_EXCEPTION_FACEBOOK',
  'GOOGLE_ID_ALREADY_CONNECTED',
  'IMPORT_LIMITER_EXCEPTION_GOOGLE',
];

class ContactStore extends Store {
  @observable contacts;

  @observable facebook;

  @observable google;

  @observable socialContactsStatus;

  @observable incoming;

  @observable invited;

  @observable preview;

  @observable status;

  @observable suggestions;

  @observable searchContacts;

  @observable query;

  @observable providerContacts;

  factory() {
    this.facebook = false;
    this.google = false;
    this.query = '';
    this.providerContacts = {
      facebook: ProviderContactModel,
      google: ProviderContactModel,
      phone: ProviderContactModel,
    };
    this.searchContacts = new Paging(() => this.getSearchContacts());
    this.contacts = new Paging(() => this.getContacts());
    this.contacts = new Paging(() => this.getContacts());
    this.incoming = new Paging(() => this.getIncoming());
    this.invited = new Paging(() => this.getInvited());
    this.suggestions = new Paging(() => this.getSuggestions());
    this.preview = ContactPreviewModel;
    this.status = {
      preview: ContactStatus.PREVIEW.PENDING,
      incoming: ContactStatus.INCOMING.PENDING,
      contacts: ContactStatus.CONTACTS.PENDING,
      suggestions: ContactStatus.SUGGESTIONS.PENDING,
      invited: ContactStatus.INVITED.PENDING,
    };
  }

  /**
   * Gets a preview of all the contacts sections
   *
   * @returns {boolean}
   */
  async getPreview() {
    this.set('status.preview', ContactStatus.PREVIEW.PENDING);

    // Get from the server this things: Incoming Requests, My Contacts, People I May Know and Sent Requests
    const collection = await Ums.get(
      'social/v2/contacts?showedContactsSize=15&peopleMayKnowSize=4&incomingRequestsSize=4&requestsSentSize=4',
      authStore.token
    );
    if (Err.check(collection))
      return this.set('status.preview', ContactStatus.PREVIEW.ERROR);

    // Setup the preview object with easier names
    const preview = {
      contacts: {
        items: collection.myContacts.contacts,
        count: collection.myContacts.count,
      },
      suggestions: {
        items: collection.peopleMayKnows.contacts,
        count: collection.peopleMayKnows.count,
      },
      requests: {
        items: [
          ...collection.incomingRequests.requests.map((item) => ({
            ...item,
            type: 'incoming',
          })),
        ],
        count:
          collection.sentRequests.count + collection.incomingRequests.count,
      },
    };

    // Update preview object
    this.set('preview', preview);

    // Set the status to success if the code arrived here
    this.set('status.preview', ContactStatus.PREVIEW.SUCCESS);

    return true;
  }

  /**
   * Gets all the incoming friend requests
   *
   * @returns {boolean}
   */
  async getIncoming() {
    // Get the full incoming requests from the server
    const incomings = await Ums.get(
      `social/incomingRequests?page=${this.incoming.current}`,
      authStore.token
    );
    if (Err.check(incomings))
      return this.set('status.incoming', ContactStatus.INCOMING.ERROR);
    return incomings.requests;
  }

  /**
   * Gets all the user connections (contacts)
   *
   * @returns {boolean}
   */
  async getContacts() {
    // Get the full contacts from the server
    const contacts = await Ums.get(
      `/social/v2/showContacts?page=${this.contacts.current}`,
      authStore.token
    );
    if (Err.check(contacts))
      return this.set('status.contacts', ContactStatus.CONTACTS.ERROR);

    return contacts;
  }

  /**
   * Gets filtered contacts
   *
   * @returns {boolean}
   */
  async getSearchContacts() {
    // Get the filtered contacts from the server
    const contacts = await Ums.get(
      `/social/searchContacts?q=${this.query}&page=${this.searchContacts.current}&size=20`,
      authStore.token
    );
    if (Err.check(contacts)) {
      return this.set('status.contacts', ContactStatus.CONTACTS.ERROR);
    }

    return contacts;
  }

  async getSocialContactsStatus() {
    const socials = await Ums.get('/getSocialButtonsStatus', authStore.token);
    if (Err.check(socials)) return this.set('socialContactsStatus', {});
    this.set('socialContactsStatus', socials);
    return true;
  }

  /**
   * Gets all the people the user may know, so to expand its social network
   *
   * @returns {boolean}
   */
  async getSuggestions() {
    // Get the full suggestions from the server
    const suggestions = await Ums.get(
      `social/peopleMayKnow?page=${this.suggestions.current}`,
      authStore.token
    );
    if (Err.check(suggestions))
      return this.set('status.suggestions', ContactStatus.SUGGESTIONS.ERROR);
    return suggestions.contacts;
  }

  /**
   * Gets all the requests you have sent to other people
   *
   * @returns {boolean}
   */
  async getInvited() {
    // Get the full invited from the server
    const inviteds = await Ums.get(
      `social/requestsSent?page=${this.invited.current}`,
      authStore.token
    );
    if (Err.check(inviteds))
      return this.set('status.invited', ContactStatus.INVITED.ERROR);

    return inviteds.requests;
  }

  /**
   * Accepts a friend request
   *
   * @param {number} friendId
   * @param {string} target
   * @param {string} relation
   * @returns {boolean}
   */
  async accept(friendId, target, relation = '') {
    const requestId =
      this[target]?.data?.find?.((request) => request.userId === friendId)
        ?.relationId ||
      this.preview[target]?.items?.find(
        (request) => request.userId === friendId
      )?.relationId;
    switch (target) {
      case 'suggestions':
        return this.sendRelationRequest({
          relation,
          friendId,
        });
      default:
        return this.acceptRelationRequest(
          {
            requestId,
            friendId,
          },
          target
        );
    }
  }

  async sendRelationRequest({ friendId, relation }) {
    const response = await Ums.post(
      '/social/sendRelationRequest',
      {
        friendId,
        relation,
        source: 'SocialPage',
      },
      authStore.token
    );
    if (Err.check(response)) return false;
    // Preview suggestions list is updated
    const newSuggestions = [...this.preview.suggestions.items];
    const suggestionIndex = newSuggestions.findIndex(
      (item) => item.userId === friendId
    );
    if (suggestionIndex !== -1) {
      newSuggestions[suggestionIndex].invited = true;
      this.set('preview.suggestions.items', newSuggestions);
      this.set('preview.suggestions.count', this.preview.suggestions.count - 1);
      this.set('preview.requests.count', this.preview.requests.count + 1);
    }

    // suggestions list is updated
    const newData = [...this.suggestions.data];
    const friend = newData.find((item) => item.userId === friendId);
    if (friend) {
      friend.invited = true;
      this.set('suggestions.data', newData);
    }

    return true;
  }

  async acceptRelationRequest({ friendId, requestId }, target) {
    const response = await Ums.post(
      '/social/acceptRelationRequest',
      {
        requestId,
        accepted: true,
        source: 'SocialPage',
      },
      authStore.token
    );
    if (Err.check(response)) return false;
    await this.removeUser(friendId, target);
    return true;
  }

  /**
   * Declines a friend request
   *
   * @param {number} id
   * @param {string} target
   * @param {string} type
   * @returns {boolean}
   */
  async decline(userId, relationId, target, type = '') {
    let endpoint;
    let method;
    let payload;
    let headers;
    let api = Server;
    // The decline request is a bit fucked up since it differs for all 4 kind of contacts, we handle the differences with a switch
    switch (target) {
      case 'requests':
      case 'invited':
      case 'incoming':
        if (type === 'incoming' || target === 'incoming') {
          endpoint = '/social/acceptRelationRequest';
          method = 'post';
          payload = { requestId: relationId, accepted: false };
          headers = { 'content-type': 'application/json' };
          api = Ums;
        } else {
          endpoint = `social/deleteContactRequest/${userId}`;
          method = 'delete';
          headers = { 'content-type': 'application/json' };
          api = Ums;
        }
        break;
      case 'suggestions':
        endpoint = `social/deleteSuggestedContact/${userId}`;
        method = 'delete';
        headers = { 'content-type': 'application/json' };
        api = Ums;
        break;
      case 'contacts':
        endpoint = `social/deleteContact/${userId}`;
        method = 'delete';
        headers = { 'content-type': 'application/json' };
        api = Ums;
        break;
      default:
        return false;
    }
    // Make the request with the variable type of request and endpoint
    const response = await api[method](
      endpoint,
      payload,
      authStore.token,
      headers
    );
    if (Err.check(response)) {
      if (
        response.__e__?.response?.data?.error?.trim() ===
          'Non puoi eliminare un contatto che fa parte di una tua condivisione' ||
        response.__e__?.response?.data?.error?.trim() ===
          'Non puoi eliminare un contatto se fai parte di una sua condivisione'
      ) {
        return toastStore.error('ContactsModule.refuseInGroup');
      }
      return toastStore.error('ContactsModule.refuse');
    }

    // Remove the user from the local array inside the store to give the user a feedback of what he has done with redownloading all the data
    await this.removeUser(userId, target);

    return true;
  }

  /**
   * Locally removes the user (id) given its section (target)
   *
   * @param {number} id
   * @param {string} target
   * @returns {boolean}
   */
  @action removeUser(id, target) {
    if (!isNumber(id) || !isString(target) || isEmpty(target))
      return Err.type('ContactStore', 'removeUser');

    let previewIndex;
    let listIndex;

    // This line of code finds the user inside the preview target array based on its id
    previewIndex =
      this.preview?.[target]?.items?.findIndex?.(
        (user) => user.id === id || user.userId === id
      ) || -1;
    // If it successfully finds the user then it proceeds to remove it from the preview target array and decrease the count variable for it
    // if (previewIndex > -1) { this.preview[target].items.splice(previewIndex, 1); this.preview[target].count-- }
    if (previewIndex !== -1) {
      this.getPreview();
    }
    // This line of code checks if the full target array is valid (it may happen that the user never opened the full list), and finds the user based on the provided id
    listIndex = isObject(this[target])
      ? this[target].data.findIndex(
          (user) => user.id === id || user.userId === id
        )
      : -1;
    // If it successfully finds the user then it proceeds to remove it from the full target array and decrease the count variable for it
    if (listIndex > -1) {
      const newData = [...this[target].data];
      newData.splice(listIndex, 1);
      this.set(target, {
        ...this[target],
        data: newData,
      });
    }
    return true;
  }

  /**
   * Socially connects the user facebook data to this account
   *
   * @returns {boolean}
   */
  async connectFacebook() {
    // Use the prepareFacebook function from the authStore to get the payload
    const payload = await authStore.prepareFacebook();
    if (Err.check(payload)) return false;

    // To connect facebook we have to merge the account,
    // we do it through the server like a normal signup
    const response = await Ums.post(
      `findFacebookContacts?token=${payload.socialAccessToken}`,
      {},
      authStore.token
    );
    const errMsg = errors.filter((error) =>
      response?.__e__?.message.includes(error)
    )[0];
    if (errMsg) return toastStore.error(`SocialMergeErrors.${errMsg}`);
    if (!Array.isArray(response) || !response?.length)
      return toastStore.error('SocialMergeErrors.NO_SOCIAL_CONTACTS');
    if (Err.check(response)) return false;

    // Create relationship between users
    await Ums.post(
      'createRelations',
      response.map((user) => ({
        friendId: user.userId,
        relation: null,
        socialProvider: user.provider,
      })),
      authStore.token
    );

    // If everything went fine then the facebook variable should be the id of its account
    this.set('facebook', response.socialId);

    window.location.reload();

    return true;
  }

  /**
   * Socially connects the user google data to this account
   *
   * @returns {boolean}
   */
  async connectGoogle() {
    // Use the prepareGoogle function from the authStore to get the apyload
    const payload = await authStore.prepareGoogleContacts();
    if (Err.check(payload)) return false;

    // To connect google we have to merge the account,
    // we do it through the server like a normal signup
    const response = await Ums.post(
      `findGoogleContacts?token=${payload.socialAccessToken}`,
      {},
      authStore.token
    );
    const errMsg = errors.filter((error) =>
      response?.__e__?.message.includes(error)
    )[0];
    if (errMsg) return toastStore.error(`SocialMergeErrors.${errMsg}`);
    if (!Array.isArray(response) || !response?.length)
      return toastStore.error('SocialMergeErrors.NO_SOCIAL_CONTACTS');
    if (Err.check(response)) return false;

    // Create relationship between users
    await Ums.post(
      'createRelations',
      response.map((user) => ({
        friendId: user.userId,
        relation: null,
        socialProvider: user.provider,
      })),
      authStore.token
    );

    // If everything went fine then the google variable should be the id of its account
    this.set('google', response.socialId);

    window.location.reload();

    return true;
  }

  getSocialMergeErrorCode(message) {
    let errCode = 'SocialMerge.genericError';
    switch (true) {
      case message.includes('already connected'):
        errCode = 'SocialMerge.alreadyConnected';
        break;
      case message.includes('Wrong email passed'):
        errCode = 'SocialMerge.wrongEmail';
        break;
      default:
        errCode = 'SocialMerge.genericError';
        break;
    }
    return errCode;
  }

  @computed get _previewContacts() {
    return { data: this.preview.contacts.items.slice() };
  }

  @computed get _previewRequests() {
    return { data: this.preview.requests.items.slice() };
  }

  @computed get _previewSuggestions() {
    return { data: this.preview.suggestions.items.slice() };
  }
}

const contactStore = new ContactStore();
export default contactStore;
