import React, { PureComponent } from 'react'
import Span from '../natives/Span'
import Touchable from '../natives/Touchable'

class TouchableSpan extends PureComponent {
  render() {
    const {
      id,
      value,
      style,
      textStyle,
      inject,
      casing,
      useNative,
      onPress,
      clipboard,
      link,
      disabled,
      align
    } = this.props

    return (
      <Touchable
        disabled={disabled}
        onPress={onPress}
        style={`${align || 'asc'} ${style}`}
        useNative={useNative}
        clipboard={clipboard}
      >
        <Span id={id} value={value} inject={inject} casing={casing} style={textStyle} link={link} />
      </Touchable>
    )
  }
}

export default TouchableSpan
