import { observable } from 'mobx';
import Store from '../various/store';
import toastStore from './toast';
import userStore from './user';

const __COMMENTS__ = process.env.REACT_APP_COMMENTS === 'true';

export type RecaptchaResponse = {
  status: 'EXPIRED' | 'SUCCESS' | 'DISABLED';
  token: string;
};

class CaptchaStore extends Store {
  @observable loaded: boolean;

  @observable instance: any;

  factory() {
    super.factory();
    this.instance = null;
    this.loaded = false;
  }

  setLoaded = () => {
    this.set('loaded', true);
  };

  resetRecaptchaHandler = () => {
    if (__COMMENTS__) console.log('ReCAPTCHA RESETTING');
    this.instance.reset();
  };

  setRecaptchaHandler = (ref) => {
    if (ref) {
      if (__COMMENTS__) console.log('ReCAPTCHA REF VALID');
      this.set('instance', ref);
      this.set('loaded', true);
    } else if (__COMMENTS__) console.log('ReCAPTCHA REF INVALID');
  };

  async executeAsync() {
    const original = this.instance.executeAsync();
    if (!('MutationObserver' in window)) return original;
    return new Promise((resolve, reject) => {
      const frame = document.querySelector(
        'iframe[src*="google.com/recaptcha/api2/bframe"]'
      )?.parentNode?.parentNode as HTMLElement;
      if (!frame) return original.then(resolve).catch(reject);
      const observer = new MutationObserver(() => {
        if (Number(frame.style.opacity) === 0) {
          reject(new Error('User clicked outside'));
          observer.disconnect();
        }
      });
      observer.observe(frame, { attributes: true, attributeFilter: ['style'] });
      return original
        .then((result) => {
          observer.disconnect();
          resolve(result);
        })
        .catch((e) => {
          observer.disconnect();
          reject(e);
        });
    });
  }

  isEnabled = () => (
      Number(
        userStore.publicConfigurations?.[
          'frontEnd.public.login.recaptcha.enabled'
        ]
      ) > 0 && !navigator.userAgent.includes('GabriPi')
    );

  async getRecaptcha(isEnabled: boolean): Promise<RecaptchaResponse> {
    if (__COMMENTS__) console.log('getRecaptcha', this.instance);
    const reCaptchaResult = { status: null, token: null };
    if (isEnabled) {
      reCaptchaResult.token = await this.executeAsync();
      if (reCaptchaResult.token == null) {
        toastStore.error('ReCaptcha Expired');
        reCaptchaResult.status = 'EXPIRED';
      } else {
        reCaptchaResult.status = 'SUCCESS';
      }
    } else {
      reCaptchaResult.token = null;
      reCaptchaResult.status = 'DISABLED';
    }
    if (__COMMENTS__) console.log('getRecaptcha', reCaptchaResult);
    return reCaptchaResult;
  }
}

export default new CaptchaStore();
