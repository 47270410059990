import React, { useCallback, useEffect, useState } from 'react';
import useRegisterTranslation from '@bit/together-price.core.hooks.use-register-translation';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Skeleton from '@material-ui/lab/Skeleton';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Typography from '@material-ui/core/Typography';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import FullPageError from '../FullPageError';
import en from './i18n/en.json';
import es from './i18n/es.json';
import it from './i18n/it.json';
import pt from './i18n/pt.json';

const useStyles = makeStyles((theme) => ({
  image: {
    width: '100%',
    objectFit: 'cover',
    maxHeight: 300,
    borderRadius: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  try: {
    marginTop: theme.spacing(2),
  },
  warning: {
    color: theme.palette.warning.main,
    fontSize: 50,
    marginBottom: theme.spacing(5),
  },
}));
const ServerError = () => {
  const classes = useStyles();
  const { t } = useRegisterTranslation('ServerError', { en, es, it, pt });
  const [icon, setIcon] = useState('');

  const handleClose = useCallback(() => window.location.reload(), []);

  useEffect(() => {
    fetch('https://api.thecatapi.com/v1/images/search')
      .then((res) => res.json())
      .then((img) => setIcon(img[0]?.url))
      .catch(null);
  }, []);

  return (
    <FullPageError
      open
      header={<ReportProblemOutlinedIcon className={classes.warning} />}
      icon={
        <LazyLoadImage
          className={classes.image}
          src={icon}
          alt='Cat'
          placeholder={<Skeleton width={200} height={200} variant='rect' />}
          visibleByDefault
          delayMethod='throttle'
        />
      }
      description={
        <>
          <Typography variant='body1' color='textSecondary'>
            {t('description')}
          </Typography>
          <Typography
            className={classes.try}
            variant='body1'
            color='textSecondary'
          >
            {t('try')}
          </Typography>
        </>
      }
      onClose={handleClose}
    />
  );
};

export default ServerError;
