import { observable } from 'mobx';
import authStore from './auth';
import Err from '../utils/error';
import Store from '../various/store';
import walletStore from './wallet';
import Financial from '../utils/financial';
import toastStore from './toast';

class RefundStore extends Store {
  @observable message

  factory() {
    this.message = '';
  }

  /**
   * Lets the logged user ask for a refund
   *
   * @param {string} postId
   * @param {string} walletActivityId
   * @returns {boolean}
   */
  async askRefund(postId, walletActivityId) {
    let payload; let
      response;

    payload = {
      message: this.message,
      walletActivityId,
    };

    response = await Financial.post('askRefund', payload, authStore.token);
    if (Err.check(response)) return toastStore.error('RefundModal.ask');

    walletStore.history.getFirstPage();

    return toastStore.success('RefundModal.ask');
  }

  async cancelRefund(walletActivityId) {
    let payload; let
      response;

    payload = {
      walletActivityId,
    };

    response = await Financial.post('cancelRefund', payload, authStore.token);
    if (Err.check(response)) return toastStore.error('RefundStore.cancelRefund');

    walletStore.history.getFirstPage();

    return toastStore.success('RefundStore.cancelRefund');
  }
}

const refundStore = new RefundStore();
export default refundStore;
