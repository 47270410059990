import { Preferences } from '@capacitor/preferences';

const setStorage = async <T extends any >(key: string, value: T): Promise<void> => {
  await Preferences.set({
    key,
    value: JSON.stringify(value),
  });
};

const getStorage = async <T extends any >(key: string): Promise<T | null> => {
  const { value } = await Preferences.get({ key });
  if (!value || value === 'undefined') return null;
  return JSON.parse(value);
};

const removeStorage = async (key: string): Promise<void> => {
  await Preferences.remove({ key });
};

const SecureStorage = {
  set: setStorage,
  get: getStorage,
  remove: removeStorage,
};

export default SecureStorage;
