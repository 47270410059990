// eslint-disable-next-line no-undef
// @ts-ignore
import memoize from 'lodash/memoize';
import userStore from '../shared/stores/user';
import { addPixelListener } from '../shared/utils/pixel';

const digestMessage = memoize(async (message) => {
  const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  // convert bytes to hex string
  return hashArray.map((b) => b.toString(16)
    .padStart(2, '0'))
    .join('');
});

// @ts-ignore
const track = async (event, data = {}) => fbq('trackCustom', event, {
  ...data,
  external_id: userStore.user.id ? await digestMessage(userStore.user.id) : undefined,
});

const registerPixel = () => {
  addPixelListener('page-view-sign-up', () => {
    track('visualizzano form di registrazione');
  });
  addPixelListener('page-view-login', () => {
    track('visualizzano form di login');
  });
  addPixelListener('button-click-complete-sign-up', () => {
    // eslint-disable-next-line no-undef
    // @ts-ignore
    window.fbq('track', 'CompleteRegistration');
  });
  addPixelListener('page-view-welcome-share', () => {
    track('welcome share');
  });
  addPixelListener('page-view-home-full', () => {
    track('Network');
  });
  addPixelListener<any>('click-sharing-card', ((event) => {
    track(`Visualizza Gruppo ${event.detail?.data?.post?.details?.serviceTag}`);
  }));
  addPixelListener('page-view-checkout-success', () => {
    track('Invio Richiesta');
  });
  addPixelListener('page-view-save-card', () => {
    track('visualizza pagina salvataggio carta');
  });
  addPixelListener('save-card', () => {
    track('salvataggio carta');
  });
  addPixelListener('page-view-create-group', () => {
    track('crea gruppo');
  });
  addPixelListener('page-view-create-group-service', (event) => {
    track(`crea gruppo ${event.detail?.data?.service}`);
  });
  addPixelListener('button-click-publish-group', () => {
    track('pubblicazione gruppo di condivisione');
  });
  addPixelListener('page-view-referral', () => {
    track('visualizzazione link refferal');
  });
  addPixelListener('button-click-copy-share', () => {
    track('copia link refferal');
  });
};

registerPixel();
