import { remove } from 'lodash';
import { Relation } from '../various/enums';

// Derives the disabled relationships
function getDisabledRelationships(value) {
  switch (value) {
    case Relation.ROOMMATE:
      return [Relation.FRIEND, Relation.TEAMMATE];
    case Relation.FAMILY:
      return [Relation.ROOMMATE, Relation.FRIEND, Relation.TEAMMATE];
    case Relation.FRIEND:
      return [Relation.ROOMMATE, Relation.FAMILY, Relation.TEAMMATE];
    case Relation.TEAMMATE:
      return [Relation.ROOMMATE, Relation.FAMILY, Relation.FRIEND];
    default:
      return [];
  }
}

function getDisabledRelationshipsCustom(value) {
  return remove([Relation.ROOMMATE, Relation.FAMILY, Relation.FRIEND, Relation.TEAMMATE], (a) => a !== value);
}

// We need to know the disabled timeframes for the dropdown selector
function getDisabledTimeframe(value) {
  // The check is simple, if the server provided sharingPeriod is monthly then the disabled one shall be annual and so the opposite
  return value === 'monthly' ? ['annual'] : value === 'annual' ? ['monthly'] : [];
}

// Gets the number of members in a hardcoded way for the given service
function getServiceCount(service) {
  switch (service) {
    case 'custom':
      return false;
    case 'netflix':
      return '44.000';
    case 'disney_plus':
    case 'disney_plus_annual_preorder':
      return '32.000';
    case 'spotify':
      return '57.000';
    case 'amazon_music':
      return '8.000';
    case 'now_tv':
      return '26.000';
    case 'apple_music':
      return '22.000';
    case 'google_play_music':
      return '5.000';
    case 'tidal':
      return '6.000';
    case 'office_365':
      return '8.000';
    case 'steam':
      return '12.000';
    case 'xbox_live':
      return '14.000';
    case 'dropbox':
      return '9.000';
    case 'kaspersky':
      return '7.000';
    case 'nintendo_switch_online':
      return '13.000';
    case 'invictus':
      return '3.000';
    default:
      return false;
  }
}

const Post = {
  disabledRelationships: getDisabledRelationships,
  disabledRelationshipsCustom: getDisabledRelationshipsCustom,
  disabledTimeframe: getDisabledTimeframe,
  serviceCount: getServiceCount,
};
export default Post;
