import React, { Component } from 'react';
import _, { isNil } from 'lodash';
import { observer } from 'mobx-react';
import Span from '../natives/Span';
import TouchableRow from '../touchables/TouchableRow';
import FormItem from './FormItem';
import Icon from '../natives/Icon';
import appStore from '../stores/app';

@observer
class FormDropdown extends Component {
  onPressItem = (item, callback) => {
    const { store, entry } = this.props;

    // The store entry will have the new value that is the item passed down as a parameter to this function
    store.set(entry, item);

    // Run callback if present
    if (_.isFunction(callback)) callback();

    // Close the modal once the item has been selected
    this.toggleSelector();

    if (_.isFunction(this.props.callback)) this.props.callback();
  };

  toggleSelector = () => {
    if (this.props.onToggleSelector) {
      const { onToggleSelector } = this.props;
      return onToggleSelector();
    }
    if (!appStore.dropdown.visible) {
      appStore.set('dropdown', {
        ...this.props,
        visible: true,
        onPressItem: this.onPressItem,
      });
    } else if (appStore.dropdown.visible)
      appStore.set('dropdown.visible', false);
  };

  render() {
    const {
      data,
      placeholder,
      color,
      arrowStyle,
      customStyle,
      iconColor,
      iconSize,
      store = {},
      entry = '',
    } = this.props;
    const value = _.get(store, entry);

    const active = data.findIndex((object) =>
      isNil(object.output) ? undefined : object.output === value
    );

    const preview = active < 0 ? placeholder : data[active]?.input;

    return (
      <FormItem {...this.props}>
        <TouchableRow
          onPress={this.toggleSelector}
          style={customStyle || 'h40 jcsb'}
        >
          <Span
            id={preview}
            value={preview}
            style={`tfr ts14 ${active < 0 && 'black o30'} ${color || 'violet'}`}
          />
          <Icon
            name='ios-arrow-forward'
            color={iconColor || color || 'violet'}
            size={iconSize || 20}
            style={`${arrowStyle}  tr90`}
          />
        </TouchableRow>
      </FormItem>
    );
  }
}

export default FormDropdown;
