import React, { PureComponent } from 'react'
import Touchable from '../natives/Touchable'
import Icon from '../natives/Icon'

class TouchableIcon extends PureComponent {
  render() {
    const { name, containerSize, size, color, style, iconStyle, useNative, disabled, onPress } = this.props

    return (
      <Touchable
        disabled={disabled}
        onPress={onPress}
        style={`jcc aic cn${containerSize || size} ${style}`}
        useNative={useNative}
      >
        <Icon name={name} size={size} color={color} style={iconStyle} />
      </Touchable>
    )
  }
}

export default TouchableIcon
