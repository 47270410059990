import { action } from 'mobx'
import { set } from 'lodash'

class Store {
  constructor() {
    this.factory()
  }

  factory() {}

  /**
   * Universal action which lets you set store variables easily even in async functions
   *
   * @param {string} entry
   * @param value
   */
  @action set(entry, value) {
    set(this, entry, value)
  }

  /**
   * Reset this store through the factory method
   */
  @action reset() {
    this.factory()
  }
}

export default Store
