import { withStyles } from '@material-ui/core';
import { Rating } from '@material-ui/lab';

const StyledRating = withStyles({
  iconFilled: {
    color: '#ffa600',
  },
  icon: {
    // margin: '0 .5rem',
  },
})(Rating);

export default StyledRating;
