import isNull from 'lodash/isNull';
import { Component } from 'react';
import { withRouter, matchPath } from 'react-router-dom';
import { InstallReferrer } from '@togetherprice/capacitor-plugin-install-referrer';
import { Preferences } from '@capacitor/preferences';
import appStore from '../stores/app';
import navigatorStore from '../stores/navigator';
import trackingStore from '../stores/tracking';
import Retention from '../utils/retention';

const removeNullValues = (object) =>
  Object.fromEntries(
    Object.entries(object).filter(([, value]) => !isNull(value))
  );

const getParams = (params) => ({
  email: params.get('email'), // Used by verifyEmail and resetPassword
  token: params.get('token'), // Used by resetPassword
  verifyToken: params.get('verify_token'), // Used by verifyEmail
  sharingRequestId: params.get('sharing_request_id'),
  promo: {
    source: params.get('utm_source'),
    medium: params.get('utm_medium'),
    content: params.get('utm_content'),
    url: params.get('utm_url'),
    lang: params.get('utm_lang'),
    page: params.get('utm_page'),
    term: params.get('utm_term'),
    campaign: params.get('utm_campaign'),
  },
  referral: removeNullValues({
    tag: params.get('tag'),
    channel: params.get('channel'),
    referrer_id: params.get('referrer_id'),
    promotions: params.get('promotions')?.split(','),
    promoCode: params.get('promo_code'),
  }),
  utm: removeNullValues({
    utm_source: params.get('utm_source'),
    utm_medium: params.get('utm_medium'),
    utm_content: params.get('utm_content'),
    utm_url: params.get('utm_url'),
    utm_lang: params.get('utm_lang'),
    utm_page: params.get('utm_page'),
    utm_term: params.get('utm_term'),
    utm_campaign: params.get('utm_campaign'),
  }),
  affiliate: {
    aff_click_id: params.get('aff_click_id'),
    aff_provider: params.get('aff_provider'),
    aff_landing: params.get('aff_landing'),
  },
  post: {
    title: params.get('post_title'),
    admin: params.get('post_referrer'),
    token: params.get('token_referrer'),
  },
  ab: {
    ab: params.get('ab'),
    abTime: new Date().toISOString(),
    referrer: document.referrer,
  },
  experiment: {
    name: params.get('experiment_name'),
    variant: params.get('experiment_variant'),
  },
});

const isInstallReferrerLoggedOne = async () =>
  Preferences.get({ key: 'InstallReferrer.usedOnce' }).then(
    ({ value }) => value === 'true'
  );

const setInstallReferrerLoggedOne = () =>
  Preferences.set({
    key: 'InstallReferrer.usedOnce',
    value: JSON.stringify(true),
  });

const getRawParamsFromInstallReferrer = async () => {
  const { referrerUrl } = await InstallReferrer.getReferrerDetails();
  if (!referrerUrl) return null;
  try {
    const uri = decodeURIComponent(referrerUrl);
    const url = new URL(uri);
    return url.search;
  } catch (e) {
    console.error(
      `the install referrer "${referrerUrl}" is not a proper url`,
      e
    );
  }
  return null;
};

const updateParameters = async (params) => {
  let last;
  // Get everything from the storage
  await trackingStore.fromStorage();
  // Execute the land method with the utm params and set them in the auth store for later use
  if (params.affiliate.aff_click_id && params.affiliate.aff_provider) {
    trackingStore.set('affiliate', params.affiliate);
    last = 'affiliate';
  }
  // Execute the land method with the utm params and set them in the auth store for later use
  if (params.utm.utm_source) {
    trackingStore.set('utm', params.utm);
    trackingStore.set('promo', params.promo);
    last = 'utm';
  }
  // Set the referral variables to the store
  if (
    params.referral.tag &&
    params.referral.channel &&
    (params.referral.referrer_id || params.referral.promoCode)
  ) {
    trackingStore.set('referral', params.referral);
    last = 'referral';
  }
  if (params.experiment?.name) {
    trackingStore.set('experiment', params.experiment);
  }
  // Save both the utm and referral objects
  await trackingStore.toStorage(last);
  // ab test tracking
  if (params.ab.ab) {
    trackingStore.set('ab', params.ab);
    await trackingStore.toStorage('ab');
  }
  // Get everything again from the storage with the correct createdAt
  await trackingStore.fromStorage();
};

const processInstallReferrer = async () => {
  if (await isInstallReferrerLoggedOne()) {
    return;
  }
  const raw = await getRawParamsFromInstallReferrer();
  const params = getParams(new URLSearchParams(raw));
  await updateParameters(params);
  await setInstallReferrerLoggedOne();
};

const hasValidReferralId = async ({ tag, channel, referrer_id }) =>
  tag === 'viral_loop' &&
  channel &&
  referrer_id &&
  (await Retention.get(`/referral_promo/isReferrerIdValid/${referrer_id}`));

class Spy extends Component {
  componentDidMount = async () => {
    const { history } = this.props;
    navigatorStore.set('navigator', history);
    navigatorStore.set('history', [
      navigatorStore.stripTrailingSlash(history.location.pathname),
    ]);
    navigatorStore.init();
    await trackingStore.fromStorage();
    await processInstallReferrer();
    await this.readParams();
    this.getInviteLink();
    history.listen((location, action) => {
      // location is an object like window.location
      if (action === 'PUSH') {
        this.readParams();
      }
    });
  };

  getInviteLink() {
    const { history } = this.props;
    const urlSearchParams = new URLSearchParams(history.location.search);
    const path = matchPath(history.location.pathname, {
      path: '/post/:postid',
    });
    // ? INFO: if a user is invited by the admin then show the invite landing
    if (
      urlSearchParams.get('trk') === 'inviter' &&
      path &&
      path.params &&
      path.params.postid &&
      urlSearchParams.get('token_referrer')
    ) {
      urlSearchParams.delete('trk');
      urlSearchParams.set('postId', path.params.postid);
      navigatorStore.redirect(`/invite?${urlSearchParams.toString()}`);
    } else {
      navigatorStore.setFrom(history.location.pathname);
    }
  }

  getRawParams = async () => {
    const { location } = this.props;
    const { search } = location;
    return search.replace(/amp;/gm, '');
  };

  readParams = async () => {
    const rawParams = await this.getRawParams();
    if (!rawParams) return null;
    const params = getParams(new URLSearchParams(rawParams));

    // TODO move in another place
    if (params.sharingRequestId) {
      navigatorStore.stack('/feed/requests', params);
      return null;
    }

    await updateParameters(params);

    if (params.affiliate.aff_landing) {
      this.affiliateRedirect(params.affiliate.aff_landing);
      return null;
    }
    if (await hasValidReferralId(params.referral)) {
      this.referralRedirect();
      return null;
    }
    this.utmRedirect(
      params.utm.utm_url,
      params.utm.utm_lang,
      params.utm.utm_content,
      new URLSearchParams(params.utm).toString()
    );
    appStore.set('params', params);
    return null;
  };

  affiliateRedirect = (affUrl) => {
    if (affUrl) {
      window.location.href = affUrl.includes('https://')
        ? affUrl
        : `https://${affUrl}/`;
    }
  };

  utmRedirect = (url, lang = 'it', content = '', search = '') => {
    const home = `https://www.togetherprice.com/${lang}`;

    switch (url) {
      case 'home':
        window.location.href = `${home}/?${search}`;
        break;
      case 'landing':
        window.location.href = `${home}/${content}/?${search}`;
        break;
      default:
        switch (true) {
          case content.includes('signup'):
            return navigatorStore.stack('/sign-up');
          case content.includes('gratis'):
            return navigatorStore.stack('/free4ever');
          default:
            break;
        }
    }
  };

  referralRedirect = () => {
    window.location.href = 'https://www.togetherprice.com/landing-viral-loop/';
  };

  componentDidUpdate = () => {
    const { history } = this.props;
    navigatorStore.set('navigator', history);
    navigatorStore.init();
  };

  render() {
    return false;
  }
}

export default withRouter(Spy);
