import React, { CSSProperties, PureComponent } from 'react';
import { isEmpty, isFunction, isString } from 'lodash';
import { Clipboard } from '@capacitor/clipboard';
import toastStore from '../../stores/toast';
import Div from '../Div';

export type TouchableProps = {
    disabled?: boolean;
    style?: string;
    animated?: CSSProperties;
    clipboard?: string;
    onPress?: <T>(arg1?: T) => void;
    onPressDisabled?: <T>(arg1?: T) => void;
}

class Touchable extends PureComponent<TouchableProps> {
    onPress = async (e) => {
      e.stopPropagation();

      const {
        disabled, clipboard, onPress, onPressDisabled,
      } = this.props;

      try {
        // If the touchable is not disabled and it has a
        // valid onPress function then exec this function
        if (!disabled && isFunction(onPress)) await onPress(e);
        if (disabled && isFunction(onPressDisabled)) await onPressDisabled(e);
      } catch (e) {
        console.error(e);
      }

      // If the clipboard prop is defined try to copy the given text
      try {
        if (isString(clipboard) && !isEmpty(clipboard)) {
          await Clipboard.write({
            string: clipboard,
          });
          this.onSuccess();
        }
      } catch (e) {
        this.onError(e);
      }
    }

    onSuccess = () => toastStore.success('Touchable.clipboard', {}, '');

    onError = (e) => console.error(e);

    render() {
      const {
        disabled, style, animated, children,
      } = this.props;

      const props = {
        onPress: this.onPress,
        disabled,
        style: `touchable ${style}`,
        animated,
      };

      return <Div {...props}>{children}</Div>;
    }
}

export default Touchable;
