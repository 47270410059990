import { observable } from 'mobx';
import { filter, find, findIndex, has, isEmpty } from 'lodash';
import Store from '../various/store';
import Ums from '../utils/ums';
import postStore from './post';
import authStore from './auth';
import Err from '../utils/error';
import toastStore from './toast';
import {
  TPasswordJoinerStatus,
  TPasswordRating,
  TPasswordStatus,
} from '../various/enums';
import userStore from './user';
import appStore from './app';
import navigatorStore from './navigator';
import modalStore from './modal';
import cryptoStore from './crypto';
import createStore from './create';
import t from './translate';

export class TPasswordStore extends Store {
  @observable _joiners;

  @observable email;

  @observable emailSent;

  @observable rating;

  @observable status;

  @observable openModal;

  factory() {
    this._joiners = [];
    this.email = '';
    this.emailSent = true;
    this.openModal = false;
    this.rating = {
      user: TPasswordRating.UNKNOWN,
      global: TPasswordRating.UNKNOWN,
    };
    this.status = {
      joiners: TPasswordStatus.JOINERS.PENDING,
      rating: TPasswordStatus.RATING.PENDING,
    };
  }

  async getJoiners() {
    let response;

    this.set('status.joiners', TPasswordStatus.JOINERS.PENDING);

    response = await Ums.get(
      `sharing/secrets/getSharingSecretsPostJoiners/${postStore.id}`,
      authStore.token
    );
    if (Err.check(response)) response = [];

    this.set('_joiners', response);
    this.set('status.joiners', TPasswordStatus.JOINERS.SUCCESS);
    this.set('rating.user', this.loggedRating);

    return true;
  }

  async getRating() {
    let response;

    this.set('status.rating', TPasswordStatus.RATING.PENDING);

    response = await Ums.post(
      'sharing/secrets/getStatus',
      [postStore.id],
      authStore.token
    );
    if (Err.check(response))
      return this.set('status.rating', TPasswordStatus.RATING.ERROR);

    this.set('rating.global', response[postStore.id]);
    this.set('status.rating', TPasswordStatus.RATING.SUCCESS);

    return true;
  }

  async setRating() {
    let response;

    response = await Ums.post(
      `sharing/secrets/markSecretStatus/${postStore.id}/${this.rating.user}`,
      {},
      authStore.token
    );
    if (Err.check(response))
      return toastStore.error('TPassword.Store.setRating');

    this.getJoiners();
    this.getRating();

    return toastStore.success('TPassword.Store.setRating');
  }

  async sendJoinerEmailToAdmin() {
    let payload;
    let response;

    payload = {
      email: this.email,
      sharingPostId: postStore.id,
    };

    response = await Ums.post(
      'sharing/secrets/handleJoinerEmail',
      payload,
      authStore.token
    );
    if (Err.check(response))
      return toastStore.error('TPassword.Store.sendJoinerEmailToAdmin');

    return toastStore.success('TPassword.Store.sendJoinerEmailToAdmin');
  }

  async hasEmailBeenSentToAdmin() {
    if (!this.isEmailBasedService) return false;

    const response = await Ums.get(
      `sharing/secrets/hasEmailBeenSentToAdmin/${postStore.id}`,
      authStore.token
    );
    if (Err.check(response)) return false;

    this.set('emailSent', response);

    return true;
  }

  openManager() {
    if (appStore.mobile)
      navigatorStore.stack(`/post/${postStore.id}/tpassword`);
    if (appStore.desktop) modalStore.pending('tpassword');
  }

  get authorizable() {
    return filter(
      this._joiners,
      (j) => j.joinerStatus === TPasswordJoinerStatus.NOT_AUTHORIZED
    );
  }

  get hasJoinerLeft() {
    return false;
  }

  get hasJoinersToAuthorize() {
    return (
      findIndex(
        this._joiners,
        (j) => j.joinerStatus === TPasswordJoinerStatus.NOT_AUTHORIZED
      ) > -1
    );
  }

  get hasLoggedAsked() {
    return has(this.logged, 'askSecretUuid') || cryptoStore.uuid;
  }

  get hasLoggedRated() {
    return has(this.logged, 'secretStatus');
  }

  get isAuthorized() {
    return postStore.isUserOwned || postStore.isUserJoinedOrSuspended;
  }

  get isEmailBasedService() {
    return ['spotify'].some(
      (serviceTag) => postStore.post.serviceTag === serviceTag
    );
  }

  get isRatingBad() {
    return this.rating.global === TPasswordRating.WRONG;
  }

  get isRatingMissing() {
    return this.rating.global === TPasswordRating.MISSING;
  }

  get isVisible() {
    return !isEmpty(postStore?._service)
      ? postStore?._service
      : createStore?._plan;
  }
  get hasMultipleTpasswordTypes() {
    return this._tpasswordTypes.length > 1;
  }

  get _tpasswordTypes() {
    return Object.values(createStore._currentService?.secretsAllowed || {}).map(
      (tp) => ({
        input: t.get(`ServiceForm.tpasswordType.${tp}`),
        output: tp,
      })
    );
  }

  get joiners() {
    return this._joiners.slice();
  }

  get logged() {
    return find(this._joiners, (j) => j.id === userStore.user.id) || {};
  }

  get loggedRating() {
    return this.hasLoggedRated
      ? this.logged.secretStatus
      : TPasswordRating.UNKNOWN;
  }

  get numberOfRatings() {
    return (filter(this._joiners, (j) => has(j, 'secretStatus')) || []).length;
  }

  get ratings() {
    return filter(
      this._joiners,
      (j) => j.joinerStatus !== TPasswordJoinerStatus.NOT_AUTHORIZED
    );
  }

  get service() {
    return find(['spotify'], (s) => s === postStore._service.tag) || 'default';
  }
}

const tpasswordStore = new TPasswordStore();
export default tpasswordStore;
