import React from 'react';
import FormInput from './FormInput';
import Span from '../natives/ts/Span';
import Column from '../layout/Column';
import Container from '../layout/Container';
import HelpSection from '../components/HelpSection';
import Row from '../layout/Row';

class PromoInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };
  }

  onPressPromo = () => this.setState({ checked: true });

  get isChecked() {
    const { checked } = this.state;
    return checked;
  }

  get isDisabled() {
    const { disabled } = this.props;
    return disabled;
  }

  render() {
    const { helpTitle, ...props } = this.props;

    if (this.isDisabled) {
      return (
        <Column style="wp100 jcc aic">
          <Span id="Error.PostSignUp.disabledPromoInput" style="red ts13 tfb" outerStyle="tal" />
        </Column>
      );
    }

    if (!this.isChecked) {
      return (
        <Container>
          <HelpSection
            rowStyle="jcc ais"
            helpWidth="wa ff lg-ffr"
            onPressHelp={this.onPressPromo}
            text={helpTitle}
            textStyle="tac ts16 violet tdu"
          />
        </Container>
      );
    }
    return <FormInput {...props} />;
  }
}

export default PromoInput;
