import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { App as CapApp } from '@capacitor/app';

const AppUrlListener: React.FC = () => {
  const history = useHistory();
  useEffect(() => {
    const handler = CapApp.addListener('appUrlOpen', (data) => {
      console.log('appUrlOpen', data);
      const url = new URL(data.url);
      const slug = url.pathname + url.search + url.hash;
      if (slug) {
        history.push(slug);
      }
    });
    return () => handler.remove();
  }, [history]);

  return null;
};

export default AppUrlListener;
