import React, { Component } from 'react';
import Span from '../natives/Span';
import CheckBox from '../components/CheckBox';
import { observer } from 'mobx-react';
import Row from '../layout/Row';
import _ from 'lodash';
import Image from '../natives/Image';

@observer
class FormCheckbox extends Component {
  onPress = () => {
    let value;

    const { store, entry, singular, callback } = this.props;

    // Get the current value of the store entry we are targeting
    value = _.get(store, entry);

    // Since we have to work with 0 and 1 and javascript does not understand them very well, we will just reverse them manually
    if (_.isNumber(value)) value = value === 0 ? 1 : value === 1 ? 0 : value;
    else if (_.isBoolean(value)) value = !value;

    // The singular prop means that there can only be one active checkbox at a time, it will cycle through the store entry object and set all the entries to 0 (false)
    if (_.isString(singular))
      Object.keys(_.get(store, singular)).forEach((key) => {
        store.set(`${singular}.${key}`, _.isNumber(value) ? 0 : false);
      });
    // Set the new value to the store entry target
    store.set(entry, value);
    if (_.isFunction(callback)) callback(value);
  };

  render() {
    const {
      store,
      title,
      entry,
      useNative,
      disabled,
      color,
      style,
      inject,
      link,
    } = this.props;

    // If the image prop is defined then look for it inside the specified position
    const image = this.props.image?.startsWith('http')
      ? this.props.image
      : this.props.image
      ? _.get(store, this.props.image)
      : false;

    // Get the value with the lodash get function
    const value = _.get(store, entry);

    return (
      <Row
        disabled={disabled}
        style={`pv3 jcsb ${style}`}
        useNative={useNative}
      >
        <Row style='wa ff pr3'>
          {this.props.image ? (
            <Image uri={image} style='brp100 mr3 cn40' />
          ) : null}
          <Span
            id={title}
            inject={inject}
            value={entry}
            link={link}
            style={`${disabled ? 'normal-gray' : color || 'black'}`}
          />
        </Row>
        <CheckBox
          color={'violet'}
          disabled={disabled}
          value={value}
          onPress={this.onPress}
          useNative={useNative}
        />
      </Row>
    );
  }
}

export default FormCheckbox;
