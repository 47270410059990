import { SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';

// @ts-ignore
const login = (): Promise<SignInWithAppleResponse> => Capacitor.Plugins.SignInWithApple.authorize({
  clientId: 'com.togetherprice',
  scopes: 'email name'
});

export default {
  login,
};
