import React, { Component } from 'react'
import Span from '../natives/Span'
import Column from '../layout/Column'
import Image from '../natives/Image'
// import Icon from '../natives/Icon'

class NotFound extends Component {
  render() {
    const { image, message, styles = '' } = this.props

    return (
      <Column style={`wp100 mt3 ff jcc ${styles}`}>
        {image && <Image source={image} style='cn128' contain />}
        <Span id={message || 'NotFound.message'} />
      </Column>
    )
  }
}

export default NotFound
