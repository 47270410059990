import { Rest } from './rest';

const headers = ({ token, language }) => {
  const params = {};

  if (token) params.Authorization = `Bearer ${token}`;
  if (language) params['X-localization'] = language;

  return params;
};
const Server = new Rest(process.env.REACT_APP_SERVER, headers);

export default Server;
