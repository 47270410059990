import { matchPath, useLocation } from 'react-router-dom';
import { MobileHeaderProps } from 'src/shared/overlays/MobileHeader';

type SettingOption = {
  path: string;
  exact?: boolean;
  strict?: boolean;
  settings: MobileHeaderProps;
};
const pageSettings: Array<SettingOption> = [
  {
    path: '/social',
    exact: true,
    settings: {
      noBack: true,
      titleLine: true,
    },
  },
  {
    path: '/social/:target',
    settings: {
      titleLine: true,
    },
  },
];

const useRouteSettings = (): MobileHeaderProps => {
  const location = useLocation();
  const page = pageSettings?.find(({ settings: s, ...otherProps }) => {
    const match = matchPath(location.pathname, otherProps);
    if (match) return s;
    return false;
  });
  return page?.settings || {};
};

export default useRouteSettings;
