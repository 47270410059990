import { isEmpty } from 'lodash';
import Queue from 'queue';
import { observable } from 'mobx';
import { StatusModel } from './enums';
import Store from './store';
import Err from '../utils/error';

class Paging extends Store {
  @observable blocked

  @observable current

  @observable data

  @observable queue

  @observable status

  constructor(fetcher = () => {}, callback = () => {}) {
    super();

    this.fetcher = fetcher;
    this.callback = callback;
  }

  factory() {
    this.blocked = false;
    this.current = 0;
    this.data = [];
    this.queue = Queue({ autostart: true, concurrency: 1 });
    this.status = StatusModel.IDLEABLE.IDLE;
    this.fetcher = () => {};
    this.callback = () => {};
  }

  async getFirstPage() {
    this.reset();
    this.set('status', StatusModel.IDLEABLE.PENDING);
    await this.getNextPage();
  }

  async getNextPage() {
    this.queue.push(async (resolve) => {
      if (!this.blocked) {
        let data;

        data = await this.fetcher();
        if (Err.check(data)) return this.set('status', StatusModel.IDLEABLE.ERROR);

        this.set('data', this.data.concat(this.getSafeData(data)));

        if (typeof data === 'object' && !this.blocked) this.set('current', this.current + 1);
        if (typeof data === 'undefined') this.set('blocked', true);

        this.set('blocked', isEmpty(data));
        this.set('status', StatusModel.IDLEABLE.SUCCESS);
        this.callback();

        resolve();
      }
    });

    return this.blocked ? await Promise.resolve() : this.awaitQueue();
  }

  getSafeData(data) {
    switch (true) {
      case data instanceof Object:
        return Object.values(data);
      case data instanceof Array:
        return data;
      default:
        return [];
    }
  }

  async awaitQueue() {
    return new Promise((resolve) => {
      setInterval(() => this.queue.length === 0 && resolve(), 100);
    });
  }

  reset() {
    this.queue.push((resolve) => {
      this.set('blocked', false);
      this.set('current', 0);
      this.set('data', []);
      this.set('status', StatusModel.IDLEABLE.PENDING);

      resolve();
    });
  }

  get isBlocked() {
    return this.blocked === true;
  }

  get isError() {
    return this.status === StatusModel.IDLEABLE.ERROR;
  }

  get isIdle() {
    return this.status === StatusModel.IDLEABLE.IDLE;
  }

  get isPending() {
    return this.status === StatusModel.IDLEABLE.PENDING;
  }

  get isSuccess() {
    return this.status === StatusModel.IDLEABLE.SUCCESS;
  }

  get length() {
    return this.data.length || 0;
  }
}

export default Paging;
