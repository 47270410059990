import { observer } from 'mobx-react';
import React from 'react';
import { Trans } from 'react-i18next';
import { Route, Switch, useParams, withRouter } from 'react-router-dom';
import t from '../stores/translate';

/** the reason of using translate store is that
 * if user change language in /account/language page due the fact that
 * we are not updating i18n during the usage of the app,
 * the only way to see the correct translations is using translate store */
const AccountTitle = withRouter(
  observer(
    ({
      match: {
        params: { route },
      },
    }: any) => t.get(`NewHeader.account.${route}`)
  )
);
//
const SocialTitle = () => {
  const { route = '' } = useParams();
  return <Trans i18nKey={`NewHeader.social.${route}`} />;
};

const PathTitles = () => (
  <Switch>
    <Route path='/account/:route'>
      <AccountTitle />
    </Route>
    <Route path='/social/:route'>
      <SocialTitle />
    </Route>
    <Route path='/social'>
      <Trans i18nKey='NewHeader.social.title' />
    </Route>
    <Route path='/referral/activated'>
      <Trans i18nKey='NewHeader.referral.activated' />
    </Route>
    <Route path='/referral'>
      <Trans i18nKey='NewHeader.referral.title' />
    </Route>
    <Route path='/payment-history'>
      <Trans i18nKey='NewHeader.payment-history' />
    </Route>
    <Route path='/promo-history'>
      <Trans i18nKey='NewHeader.promo-history' />
    </Route>
    <Route path='/credit-card'>
      <Trans i18nKey='NewHeader.credit-card' />
    </Route>
    <Route path='/faqs'>
      <Trans i18nKey='NewHeader.support' />
    </Route>
    <Route path='/profile/:id'>
      <Trans i18nKey='NewHeader.profile' />
    </Route>
    <Route path='/feed'>
      <Trans i18nKey='NewHeader.feed' />
    </Route>
    <Route path='/migration'>
      <Trans i18nKey='NewHeader.migration' />
    </Route>
    <Route path='/rules'>
      <Trans i18nKey='NewHeader.rules' />
    </Route>
    <Route path='/free4ever'>
      <Trans i18nKey='PaymentHistory.promo.free_4_ever' />
    </Route>
  </Switch>
);

export default PathTitles;
