import React, { Component } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import Span from '../natives/Span';
import modalStore from '../stores/modal';
import BaseModal from './BaseModal';
import Row from '../layout/Row';
import Button from './Button';


@observer
class DialogModal extends Component {
    onPressCancel = () => {
      modalStore.idle('dialog');
    }

    render() {
      const {
        radix, image, onPress, inject, closable,
      } = modalStore.dialog;

      let injectTitle; let
        injectDescription;

      injectTitle = _.has(inject, 'title') && inject.title;
      injectDescription = _.has(inject, 'description') && inject.description;

      return (
        <BaseModal
          target="dialog"
          title={`${radix}.title`}
          image={image}
          inject={injectTitle}
          closable={closable}
        >
          <Span
            id={`${radix}.description`}
            inject={injectDescription}
            style={`tfsb mb4 ${image ? 'tac' : 'asfs'}`}
          />
          <Row style="jcsb">
            {!closable && (
              <>
                <Button title="DialogModal.submit" onPress={onPress} style="wp99 h40" />
              </>
            )}
            {closable && (
              <>
                <Button title="DialogModal.confirm" onPress={onPress} style="wp49 h40" />
                <Button title="DialogModal.cancel" onPress={this.onPressCancel} style="wp49 h40" color="white" />
              </>
            )}
          </Row>
        </BaseModal>
      );
    }
}

export default DialogModal;
