import to from 'await-to-js';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { isUndefined } from 'lodash';
import { install } from 'ga-gtag';
import Err from './error';
import { injectScript } from './script';

class Google {
  static gtagID = process.env.REACT_APP_GTAG_ANALYTICS;

  static gApiKey = process.env.REACT_APP_GOOGLE_API_KEY;

  static init() {
    injectScript({
      src: `https://maps.googleapis.com/maps/api/js?key=${this.gApiKey}&libraries=places&language=en`,
    });
    GoogleAuth.initialize();
    install('GTM-WQX3J2L');
  }

  static async auth() {
    const [error, response] = await to(GoogleAuth.signIn());
    if (error || isUndefined(response)) return Err.create(error, true);
    const { idToken } = response.authentication;
    const profile = response;
    return {
      credentials: {
        accessToken: idToken,
      },
      user: {
        id: profile.id,
        givenName: profile.givenName,
        familyName: profile.familyName,
        email: profile.email,
        picture: profile.imageUrl,
      },
    };
  }

  static async authContacts() {
    const [error, response] = await to(GoogleAuth.signIn());
    if (error || isUndefined(response)) return Err.create(error, true);
    const { accessToken, idToken } = response.authentication;
    const profile = response;
    return {
      credentials: {
        accessToken: accessToken || idToken,
      },
      user: {
        id: profile.id,
        givenName: profile.givenName,
        familyName: profile.familyName,
        email: profile.email,
        picture: profile.imageUrl,
      },
    };
  }
}

export default Google;
