import React from 'react';
import Tippy from '@tippy.js/react';
import clsx from 'clsx';
import 'tippy.js/themes/light.css';
import Div from '../natives/Div';
import Column from '../layout/Column';
import Span from '../natives/Span';
import Image from '../natives/Image';
import settingStore from '../stores/setting';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const TooltipChildren = React.forwardRef((props, ref) => {
  const { icon, style, children } = props;
  return (
    <Div setRef={ref} style={`fr aic ${style}`}>
      {children}
      {icon && (
        <InfoOutlinedIcon
          style={{
            fontSize: '16px',
          }}
          className={clsx('ml2', 'violet', icon)}
        />
      )}
    </Div>
  );
});

const TooltipContent = (props) => {
  const {
    title,
    description,
    image,
    inject,
    renderContent,
    contentStyle,
    color,
  } = props;

  if (typeof renderContent === 'function') {
    return renderContent() || <></>;
  }

  const injectTitle = inject?.title;
  const injectDescription = inject?.description;

  return (
    <Column style={contentStyle || 'p5'}>
      {image && <Image source={image} style='cn80 mb4' />}
      {title && (
        <Span id={title} style={`tfb ts18 mb2 ${color}`} inject={injectTitle} />
      )}
      {description && (
        <Span id={description} inject={injectDescription} style={`${color}`} />
      )}
    </Column>
  );
};

const Tooltip = (props) => {
  const { hide, children, config, ...others } = props;
  if (hide) return <Div {...others}>{children}</Div>;
  const tippyConfig = {
    placement: 'top',
    arrow: true,
    inertia: true,
    lazy: true,
    theme: settingStore.darkMode ? 'dark' : 'light',
    maxWidth: 300,
    animation: 'scale',
    distance: 20,
    followCursor: true,
    ...config,
    className: clsx('old-tooltip', config?.className),
  };
  return (
    <Tippy content={<TooltipContent {...props} />} {...tippyConfig}>
      <TooltipChildren {...props} />
    </Tippy>
  );
};

export default Tooltip;
