import to from 'await-to-js';
import Axios from 'axios';
import has from 'lodash/has';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';
import axios from 'axios';
import Format from './format';
import t from '../stores/translate';
import Err, { createRateLimiterError } from './error';

const __COMMENTS__ = process.env.REACT_APP_COMMENTS === 'true';

const throwIfError =
  (endpoint) =>
  ([error, response]) => {
    if (has(error, 'response.data.message'))
      throw Err.create(
        {
          endpoint,
          code: error.response.data.code,
          message: `Server.${error.response.data.message}`,
        },
        error.response.status
      );
    if (error || isUndefined(response))
      throw Err.create({ endpoint, ...error }, error?.response?.status);
    if (has(response, 'data.error'))
      throw Err.create({ endpoint, message: `Server.${response.data.error}` });
    if (response?.status === 429) throw createRateLimiterError(response);
    if (response?.status < 200 && response?.status >= 300)
      throw Err.create({ endpoint, ...response });
    return response;
  };
const returnError = (error) => error;
const returnData = (toCamelCase) => (response) =>
  toCamelCase ? Format.objectCamelCase(response.data) : response.data;
const tap = (fn) => (data) => {
  fn(data);
  return data;
};

const transformRequest = (data) => {
  try {
    const parsed = JSON.parse(data);
    if (typeof parsed === 'string') return parsed;
  } catch (e) {
    return data;
  }
  return data;
};
export class Rest {
  baseURL = '';

  camelCase = true;

  headers = () => ({});

  /** @type AxiosInstance */
  instance = null;

  constructor(baseURL, headers, camelCase = true) {
    this.baseURL = baseURL;
    this.headers = headers;
    this.camelCase = camelCase;
    this.instance = Axios.create({
      baseURL,
      transformRequest: [...axios.defaults.transformRequest, transformRequest],
    });
  }

  getHeaders({ token, headers }) {
    return {
      ...(isString(token) ? { Authorization: `Basic ${token}` } : {}),
      ...(isFunction(this.headers)
        ? this.headers({ token, language: t.l })
        : {}),
      ...(isObject(headers) ? headers : {}),
    };
  }

  async get(endpoint, token, headers) {
    return to(
      this.instance.get(endpoint, {
        headers: this.getHeaders({ token, headers }),
      })
    )
      .then(throwIfError(endpoint))
      .then(tap((response) => __COMMENTS__ && console.log(endpoint, response)))
      .then(returnData(this.camelCase))
      .catch(returnError);
  }

  async post(endpoint, payload, token, headers = undefined) {
    if (__COMMENTS__) console.log(endpoint, payload); // eslint-disable-line

    return to(
      this.instance.post(endpoint, payload, {
        headers: this.getHeaders({ token, headers }),
      })
    )
      .then(throwIfError(endpoint))
      .then(tap((response) => __COMMENTS__ && console.log(endpoint, response)))
      .then(returnData(this.camelCase))
      .catch(returnError);
  }

  async put(endpoint, payload, token, headers) {
    if (__COMMENTS__) console.log(endpoint, payload); // eslint-disable-line
    return to(
      this.instance.put(endpoint, payload, {
        headers: this.getHeaders({ token, headers }),
      })
    )
      .then(throwIfError(endpoint))
      .then(tap((response) => __COMMENTS__ && console.log(endpoint, response)))
      .then(returnData(this.camelCase))
      .catch(returnError);
  }

  // TODO remove payload and usages
  async delete(endpoint, payload, token, headers) {
    if (__COMMENTS__) console.log(endpoint, payload); // eslint-disable-line
    return to(
      this.instance.delete(endpoint, {
        headers: this.getHeaders({ token, headers }),
      })
    )
      .then(throwIfError(endpoint))
      .then(tap((response) => __COMMENTS__ && console.log(endpoint, response)))
      .then(returnData(this.camelCase))
      .catch(returnError);
  }
}

export default new Rest();
