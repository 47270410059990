import { action, observable } from 'mobx';
import { filter, get, isEmpty, isNull } from 'lodash';
import networkStore from './network';
import authStore from './auth';
import Err, { getErrorMessage } from '../utils/error';
import { CreateStatus, Promos } from '../various/enums';
import Post from '../utils/post';
import toastStore from './toast';
import t from './translate';
import Network from '../utils/network';
import Store from '../various/store';
import covers from '../assets/create/covers';
import Price from '../components/Price';
import userStore from './user';
import Financial from '../utils/financial';
import tpasswordStore from './tpassword';

class CreateStore extends Store {
  @observable category;

  @observable channels;

  @observable description;

  @observable f4e;

  @observable image;

  @observable minPeriod;

  @observable name;

  @observable precompiled;

  @observable price;

  @observable relationship;

  @observable plan;

  @observable slots;

  @observable status;

  @observable oldTimeframe;

  @observable timeframe;

  @observable tpasswordType;

  @observable tos;

  @observable visibility;

  @observable lowServicesAvailability;

  @observable selectedService;

  @observable service;

  @observable groupTag;

  factory() {
    this.timeframe = '';
    this.tpasswordType = '';
    this.oldTimeframe = '';
    this.name = '';
    this.description = '';
    this.price = '';
    this.slots = '';
    this.relationship = '';
    this.visibility = false;
    this.category = '';
    this.plan = '';
    this.image = { file: '', base64: '', link: '' };
    this.channels = { private: 1, email: 0, cellphone: 0 };
    this.precompiled = {};
    this.tos = 0;
    this.minPeriod = '';
    this.f4e = false;
    this.status = CreateStatus.PENDING;
    this.service = null;
    this.groupTag = null;
  }

  /**
   * Initializes the store with the given service id
   *
   * @param {number|string} plan
   * @param {string} groupTag
   */
  @action init(plan, groupTag) {
    // If this function gets called
    // with a wrong service parameter then just return false to avoid errors and crashes
    this.groupTag = groupTag;
    if (isEmpty(plan)) {
      this.status = CreateStatus.SUCCESS;
      return false;
    }
    this.plan = parseInt(plan, 10);
    this.set('price', null);
    const {
      id,
      categoryId,
      name,
      description,
      frequency,
      relation,
      visibilityUpdatable,
      freeTitle,
    } = this._plan;
    // This handy function does all the job of handling
    // different kinds of variables and saving them to the store
    const parse = (entry, value, save) => {
      // Save the variable to the store,
      // if the value is null then just save it as an empty string to avoid weird behaviours
      this[entry] = !!value && save ? value : '';
      // Since not all entries are equal,
      // we need to handle some special ones for the precompiled fields
      switch (entry) {
        // In the case of a relationship entry we need to manually calculate
        // which are the ones that the user can not select through the dropdown selector
        case 'relationship':
          this.precompiled[entry] = Post.disabledRelationships(value);
          break;
        // In the case of a timeframe entry we need to get
        // which are the disabled options
        case 'timeframe':
          this.precompiled[entry] = Post.disabledTimeframe(value);
          break;
        // If the precompiled name is Custom then the field
        // must be editable even if it already has a value
        case 'name':
          this.precompiled[entry] = this.isCustom
            ? false
            : !(freeTitle || isNull(value));
          break;
        // If the precompiled description is Service custom then the field
        // must be editable even if it already has a value
        case 'description':
          this.precompiled[entry] =
            this.isCustom || this.isFreeDescription ? false : !isNull(value);
          break;
        case 'visibility':
          this[entry] ||= false;
          this.precompiled[entry] = visibilityUpdatable === false;
          break;
        case 'tpasswordType':
          this.precompiled[entry] = value;
          break;
        // If it is a normal entry then we just have to know
        // if it comes with a precompiled value and just set it in its field
        default:
          this.precompiled[entry] = !!value;
          break;
      }
    };
    // Parse all needed entries
    parse('timeframe', frequency, true);
    parse('name', name, !this.isCustom);
    parse(
      'description',
      description,
      !this.isCustom && !this.isFreeDescription
    );
    parse('relationship', relation, true);
    parse('category', categoryId, true);
    parse('plan', id, true);
    parse('selfAcceptance', 'true', true);
    parse(
      'tpasswordType',
      tpasswordStore._tpasswordTypes.length === 1
        ? tpasswordStore._tpasswordTypes[0].output
        : '',
      true
    );
    parse('visibility', visibilityUpdatable ?? true, true);
    // If the code arrived here then it is a success
    this.status = CreateStatus.SUCCESS;
  }

  /**
   * Uploads a new cover image
   *
   * @param {object} image
   * @returns {Promise<boolean>}
   */
  async uploadCover(image) {
    // We setup the image object with the id that is on the database and the url that will be used
    // to display it on the front-end
    this.set('image', {
      file: '',
      base64: '',
      link: '',
      ...image,
    });

    return true;
  }

  /**
   * Publishes a new post
   *
   * @returns {number}
   */
  async publish() {
    const createAdminAccount = await Financial.post(
      '/createAdminAccount',
      {},
      authStore.token
    );
    if (Err.check(createAdminAccount)) {
      const stripeError = String(
        createAdminAccount['__e__'].code || ''
      ).toLowerCase();
      if (stripeError) {
        return toastStore.error(`Stripe.${stripeError}`);
      }
      return toastStore.error(
        getErrorMessage(createAdminAccount) || 'CreateStore.publish.generic'
      );
    }
    const payload = new window.FormData();
    // this is not clean, but is a workaround to send all the data in one request post+
    // cover using octet stream, in this way the content of wrapper is sent as json
    // with UTF-8 encoding without garbling all text
    payload.append(
      'wrapper',
      new Blob(
        [
          JSON.stringify({
            title: this.name,
            description: this.description,
          }),
        ],
        {
          type: 'application/json',
        }
      )
    );
    payload.append('categoryId', this.category);
    payload.append('totalAvailability', this.slots);
    payload.append('price', parseFloat(this._computePriceIfNeeded));
    payload.append('isPublic', this.visibility);
    // frequency fixed because we don't have anymore annual groups
    // and timeframes is used for computation fo price on the form
    payload.append('frequency', 'monthly');
    payload.append('relation', this.relationship);
    payload.append('subscription', this.timeframe?.toUpperCase() || 'MONTHLY');
    payload.append('serviceId', this.plan);
    if (userStore.isFreeForYouActivable)
      payload.append('lowServicesAvailability', true);
    if (this.f4e || userStore.isFreeForYouActivable)
      payload.append('promoId', Promos.FREE4EVER);
    if (this.image.file) payload.append('coverData', this.image.file);
    if (this.image.link) payload.append('coverLink', this.image.link);
    // Try to publish the group by making a post request to the server
    const response = await Network.post(
      'sharingPost/create',
      payload,
      authStore.token
    );
    if (Err.check(response)) {
      const message = getErrorMessage(response);
      return toastStore.error(
        [
          'Server.VISIBILITY_NOT_UPDATABLE',
          'VISIBILITY_NOT_UPDATABLE',
        ].includes(message)
          ? 'CreateStore.publish.visibilityNotUpdatable'
          : 'CreateStore.publish.generic'
      );
    }
    // If the promo has been enabled for this post then disable it to prevent the creation of more
    // posts with the promo
    if (this.f4e) {
      userStore.set('user.postCreated', userStore.user.postCreated + 1);
      userStore.set('user.f4e', { activable: false });
    }

    return response.details.id;
  }

  get _categories() {
    return Object.values(t.s.categories);
  }

  get _covers() {
    return Array.from(
      new Set([
        ...get(covers, this._plan?.tag, []),
        ...get(covers, this.groupTag, []),
      ])
    );
  }

  get _periods() {
    return Object.values(t.s.periods);
  }

  /**
   * Returns the service price as an array of objects to work with the FormDropdown component
   *
   * @returns {array}
   */
  get _price() {
    // Get the monthly price of the selected service through its id
    const { price, showedPrice } = this._plan;
    if (!price) return false;
    let result;
    // Return it as an object for the dropdown selector, the input value is the one that is
    // displayed on the front-end, the output the one that is used on the database
    if (createStore?.isAnnualWithMonthlyRenewal) {
      result = [
        {
          input: Price.toString(showedPrice),
          output: price.value,
        },
      ]; // TODO:cto set the correct currency
    } else {
      result = [
        {
          input: Price.toString(price),
          output: price.value,
        },
      ];
    }

    return result;
  }

  /**
   * Returns if the timeframe selector should be shown to the user
   *
   * @returns {boolean}
   */
  get _showTimeframe() {
    // user can choose a plan but didn't clicked yet
    if (this._plans.length > 1 && (!this.plan || this.plan === '')) {
      return false;
    }
    // user can't choose a plan and plan is without a price
    if (this._plans.length < 2 && !this._price) {
      return true;
    }
    // all other cases
    return false;
  }

  /**
   * Returns the service price normalized to month if needed
   *
   * @returns {number}
   */
  get _computePriceIfNeeded() {
    if (!createStore?._price && createStore?.isAnnualSelected) {
      this.set('price', this.price / 12);
    }
    return this.price;
  }

  /**
   * Animates the price input when the selector from timeframe is changed
   *
   * @returns {array}
   */
  get _computePriceWhenTimeframeChanges() {
    const oldTimeframeTmp = `${this.oldTimeframe}`;
    this.oldTimeframe = this.timeframe;
    if (!oldTimeframeTmp || oldTimeframeTmp === this.timeframe)
      return this.price;
    if (!this.timeframe || !this.price) return this.price;
    if (this.timeframe === 'monthly') {
      this.set('price', this.price / 12);
    } else {
      this.set('price', this.price * 12);
    }
    return this.price;
  }

  get _currencyCode() {
    const { currencyCode } = this._plan;
    return currencyCode || userStore.info?.marketCurrency || 'EUR';
  }

  get _relationships() {
    return Object.values(t.s.relationships);
  }

  get _selfAcceptanceOptions() {
    return Object.values(t.s.selfAcceptance);
  }

  get _plan() {
    return (
      networkStore.plans[
        networkStore.plans.findIndex((a) => a.id === this.plan)
      ] || {}
    );
  }

  get _plans() {
    return networkStore.plans.map((plan) => ({
      input: plan.name,
      output: plan.id,
      tag: plan.tag,
      duration: plan.duration,
      freeDescription: plan.freeDescription,
    }));
  }

  get _servicesF4E() {
    const services = networkStore.f4eServices.map((service) => ({
      input: service.name,
      output: service.tag,
      tag: service.tag,
    }));

    return services.sort((a, b) =>
      a.output > b.output ? 1 : b.output > a.output ? -1 : 0
    );
  }

  /**
   * Returns the service slots as an array of objects for the FormDropdown component
   *
   * @returns {array}
   */
  get _slots() {
    if (!this?._plan) return [];

    // Get the total availability of the selected service through its id, if not existent just put 100
    const totalSlots = this?._plan.totalSlots || 10;

    // Initialize the slots variable as an empty array to be filled later
    const slots = [];

    // Do a for cycle based on the availability integer, push inside the slots array an object for the dropdown selector which has input and output keys
    for (let i = 0; i < totalSlots; i += 1) {
      if (this.f4e && i === totalSlots - 1) {
        if (t.l === 'it') {
          slots.push({
            input: `${i + 1} (Promo Gratis per te)*`,
            output: `${i + 1}`,
            disabled: this.f4e && i < totalSlots - 1,
          });
        } else {
          slots.push({
            input: `${i + 1} (Promo ${
              t.l === 'es' ? 'Gratis para ti' : 'Free for you'
            })*`,
            output: `${i + 1}`,
            disabled: this.f4e && i < totalSlots - 1,
          });
        }
      } else {
        slots.push({
          input: `${i + 1}`,
          output: `${i + 1}`,
          disabled: this.f4e && i < totalSlots - 1,
        });
      }
    }

    return slots;
  }

  get _subscriptionType() {
    const newVar = this?._plan?.duration || '';
    return newVar.toLowerCase();
  }

  get _timeframes() {
    return Object.values(t.s.timeframes);
  }

  get _currentService() {
    return networkStore.services.find(({ tag }) => tag === this._tag);
  }

  get _visibilities() {
    return filter(
      Object.values(t.s.visibilities),
      (v) => !this.f4e || (this.f4e && v.output === 'public')
    );
  }

  get isCustom() {
    return this?._plan?.tag === 'custom';
  }

  get isDynamic() {
    return ['custom', 'family_group'].includes(this?._plan?.tag);
  }

  get isAnnualWithMonthlyRenewal() {
    return (
      this?._plan?.duration === 'ANNUAL' ||
      (!!this.timeframe && this.timeframe !== 'monthly')
    );
  }

  get isAnnualSelected() {
    return !!this.timeframe && this.timeframe !== 'monthly';
  }

  get isNowTv() {
    return this?._plan?.tag === 'now_tv';
  }

  get isFreeDescription() {
    return this?._plan?.freeDescription === true;
  }

  get _tag() {
    return this?._plan?.tag;
  }

  clear() {
    this.set('service', null);
  }

  @action
  reset() {
    this.factory();
    this.clear();
    this.set('service', null);
  }
}

const createStore = new CreateStore();
export default createStore;
