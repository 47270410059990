import { action, observable } from 'mobx';
import camelCase from 'lodash/camelCase';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import kebabCase from 'lodash/kebabCase';
import merge from 'lodash/merge';
import snakeCase from 'lodash/snakeCase';
import startCase from 'lodash/startCase';
import FlatpickrItalian from 'flatpickr/dist/l10n/it';
import FlatpickrSpanish from 'flatpickr/dist/l10n/es';
import FlatpickrEnglish from 'flatpickr/dist/l10n/default';
import Store from '../various/store';
import momentStore from './moment';
import getLanguage from '../utils/language';
import getTranslations from '../translations/translations';
import i18n from '../../i18n';

const debounceAfterPrevRequestCompleted = (fn) => {
  let last = Promise.resolve();
  return (...args) => {
    last = last.finally(() => fn(...args));
    return last;
  };
};

const cache = new Map();
const hasCache = (key1, key2) => cache.get(key1)?.key === key2;
const getFromCache = (key1, key2) => {
  if (cache.get(key1)?.key === key2) {
    return cache.get(key1).value;
  }
  return undefined;
};
const getOr = (key1, key2, factory) => {
  if (cache.get(key1)?.key === key2) {
    return cache.get(key1).value;
  }
  cache.set(key1, { key: key2, value: factory() });
  return cache.get(key1).value;
};
const setHasLoaded = (key1, key2) => {
  if (hasCache(key1, key2)) {
    getFromCache(key1, key2).loaded = true;
  }
};

// This regex is able to select ££, $$, %%, && from the strings gradually
/**
 * There is a legend for the injectors
 *
 *   ££ = Font Thin
 *   $$ = Font Regular
 *   %% = Font Semi Bold
 *   && = Font Bold
 *   ## = Link
 *
 */
export const symbolRegexp = /(£{2}|%{2}|&{2}|\${2}|#{2}|§{2})/;
// We define this little function that will give us the correct class based on the symbol
export const getFamily = (symbol) => {
  switch (symbol) {
    case '££':
      return 'tft';
    case '$$':
      return 'tfr';
    case '%%':
      return '';
    case '&&':
      return 'tfsb';
    case '##':
      return 'touchable violet';
    case '§§':
      return 'violet tfb';
    default:
      return '';
  }
};
/**
 * Mutates the string casing
 *
 * @param {string} string
 * @param {string} casing
 */
export const casify = (string, casing) => {
  if (!isString(string)) return '';
  switch (casing) {
    case 'start':
      return startCase(string);
    case 'upper':
      return string.toUpperCase();
    case 'camel':
      return camelCase(string);
    case 'snake':
      return snakeCase(string);
    case 'kebab':
      return kebabCase(string);
    case 'lower':
      return string.toLowerCase();
    default:
      return string;
  }
};
class TStore extends Store {
  @observable l;

  @observable s = {};

  constructor() {
    super();
    this.init(getLanguage());
  }

  /**
   * Initializes the store with the given locale
   *
   * @param {string} locale
   */
  @action init = debounceAfterPrevRequestCompleted(async (locale) => {
    // If locale is not a valid string the return false
    if (!isString(locale)) return;
    // Cut the locale string to the first 2 chars
    const locale2Digit = locale.substring(0, 2).toLowerCase();
    if (this.l === locale2Digit) return;
    await i18n.changeLanguage(locale2Digit);
    await Promise.all([
      getTranslations(locale2Digit).then((translations) => translations),
      getTranslations('en').then((translations) => translations),
    ]).then(
      action(([translations, defaultTranslations]) => {
        // Change the moment locale globally
        momentStore.setLocale(locale2Digit);
        // We can change locale with this super easy method
        this.s = merge({}, this.s || {}, defaultTranslations, translations);
        cache.clear();
        // Update the l variable to signal the successful change
        this.l = locale2Digit;
      })
    );
  });

  isLoaded(loadTranslations) {
    return getFromCache(loadTranslations, this.l)?.loaded;
  }

  @action load(loadTranslations) {
    return getOr(loadTranslations, this.l, () =>
      Promise.all([loadTranslations(this.l), loadTranslations('en')]).then(
        action(([translations, defaultTranslations]) => {
          this.s = merge({}, this.s || {}, defaultTranslations, translations);
          setHasLoaded(loadTranslations, this.l);
        })
      )
    );
  }

  /**
   * Gets the translation through the given id
   * Allows for optional variable injections through the inject array
   * Lets you set an optional case pattern to the string through the casing string
   *
   * @param {string} id
   * @param {array=} inject
   * @param {string=} casing
   */
  get(id, inject, casing = '') {
    const value = casify(get(this.s, id, ''), casing);
    if (isEmpty(inject)) {
      return value;
    }
    let i = -1;
    return value
      .split(symbolRegexp)
      .map((part) => {
        if (part.match(symbolRegexp)) {
          i += 1;
          return {
            style: getFamily(part),
            value: part.replace(
              symbolRegexp,
              get(this.s, inject[i], inject[i])
            ),
          };
        }
        return {
          style: '',
          value: part,
        };
      })
      .map(({ style, value: part }) => ({
        style,
        value: casify(part, casing),
      }));
  }

  /**
   * Mutates the string casing
   *
   * @param {string} string
   * @param {string} casing
   */
  // TODO remove when unused
  casify(string, casing) {
    return casify(string, casing);
  }

  getDropdownInput(path, output) {
    return (find(get(this.s, path), (g) => g.output === output) || {}).input;
  }

  get flatpickrLocale() {
    switch (this.l) {
      case 'it':
        return FlatpickrItalian.it;
      case 'ca':
      case 'es':
        return FlatpickrSpanish.es;
      case 'en':
      default:
        return FlatpickrEnglish;
    }
  }

  get links() {
    return {
      tos: {
        es: 'https://www.togetherprice.com/es/terms-of-use',
        it: 'https://www.togetherprice.com/it/terms-of-use',
        pt: 'https://www.togetherprice.com/us/terms-of-use',
        en: 'https://www.togetherprice.com/uk/terms-of-use',
      }[this.l],
      pp: {
        es: 'https://www.togetherprice.com/es/privacy-policy',
        it: 'https://www.togetherprice.com/it/privacy-policy',
        pt: 'https://www.togetherprice.com/us/privacy-policy',
        en: 'https://www.togetherprice.com/uk/privacy-policy',
      }[this.l],
      rp: {
        es: 'https://www.togetherprice.com/es/referral-program',
        it: 'https://www.togetherprice.com/it/referral-program',
        pt: 'https://www.togetherprice.com/us/referral-program',
        en: 'https://www.togetherprice.com/uk/referral-program',
      }[this.l],
      f4e: {
        es: 'https://www.togetherprice.com/es/terminos-y-condiciones-del-programa-gratis-por-3-meses/',
        it: 'https://www.togetherprice.com/it/termini-e-condizioni-del-programma-gratis-per-3-mesi',
        pt: 'https://www.togetherprice.com/us/terms-and-conditions-of-the-3-months-free-program/',
        en: 'https://www.togetherprice.com/uk/terms-and-conditions-of-the-3-months-free-program/',
      }[this.l]
    };
  }

  get latamCountries() {
    return ['MX', 'CO', 'PE', 'AR', 'EC', 'CL', 'UY'];
  }

  get spanish() {
    return this.l === 'es';
  }

  get italian() {
    return this.l === 'it';
  }
}
const t = new TStore();
window.changeAppLocale = (locale) => t.init(locale);
export default t;
