import React, { createContext, useContext, useState } from 'react';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import { AlertProps } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';

type Feedback = {
  message: React.ReactNode,
} & Pick<AlertProps, 'severity'>;
type AppFeedbackContextValue = {
  showFeedback: (feedback: Feedback) => void,
};
export const AppFeedbackContext = createContext<AppFeedbackContextValue>({
  showFeedback: null,
});

export const useShowFeedback = () => useContext(AppFeedbackContext)?.showFeedback;

const useStyles = makeStyles({
  root: (props: any) => {
    switch (props?.severity) {
      case 'error':
      case 'warning':
        return {
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
          padding: 0,
          color: 'white',
          backgroundColor: '#fb4c68',
          textAlign: 'center',
          width: '100vw',
        };
      case 'info':
      case 'success':
        return {
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
          padding: 0,
          color: 'white',
          backgroundColor: '#825af8',
          textAlign: 'center',
          width: '100vw',
        };
      default:
        return {
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
          padding: 0,
          color: 'white',
          backgroundColor: '#825af8',
          textAlign: 'center',
          width: '100vw',
        };
    }
  },
  message: {
    padding: 0,
  },
});

function getSnackbarIcon(props: any) {
  switch (props?.severity) {
    case 'error':
      return <ErrorOutlineOutlinedIcon />;
    case 'warning':
      return <ReportProblemOutlinedIcon />;
    case 'success':
      return <CheckCircleOutlinedIcon />;
    case 'info':
      return <InfoOutlinedIcon />;
    default:
      return <InfoOutlinedIcon />;
  }
}

const AppFeedback: React.FC = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [feedback, setFeedback] = useState<Feedback>();
  const handleClose = () => setOpen(false);
  const showFeedback = (value: Feedback) => {
    setOpen(true);
    setFeedback(value);
  };
  const classes = useStyles({ severity: feedback?.severity });
  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        style={{ top: 'env(safe-area-inset-top)' }}
      >
        <SnackbarContent
          classes={classes}
          message={(
            <Grid style={{ margin: 0, width: '100vw' }} container justify="center" alignItems="center" spacing={2}>
              <Grid item>
                {getSnackbarIcon({ severity: feedback?.severity })}
              </Grid>
              <Grid item>
                {feedback?.message}
              </Grid>
            </Grid>
              )}
        />
      </Snackbar>
      <AppFeedbackContext.Provider value={{ showFeedback }}>
        {children}
      </AppFeedbackContext.Provider>
    </>
  );
};

export default AppFeedback;
