import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { isEmpty, isFunction, isString } from 'lodash';
import modalStore from '../stores/modal';
import Column from '../layout/Column';
import Title from '../natives/Title';
import { ModalStatus } from '../various/enums';
import Image from '../natives/Image';
import TouchableIcon from '../touchables/TouchableIcon';
import SpinnerModal from '../modals/SpinnerModal';
import EmptyModal from './EmptyModal';

@observer
class BaseModal extends Component {
  mounted = false;

  // eslint-disable-next-line react/state-in-constructor
  state = {
    spinner: false,
  };

  componentDidUpdate() {
    const { target } = this.props;
    if (!isString(target) || isEmpty(target)) return;
    if (modalStore.status[target] === ModalStatus[target.toUpperCase()].PENDING)
      this.init();
    if (modalStore.status[target] !== ModalStatus[target.toUpperCase()].PENDING)
      this.onUnmount();
  }

  init = async () => {
    if (!this.mounted) {
      this.mounted = true;
      this.setState({ spinner: true });
      try {
        if (isFunction(this.props.init)) await this.props.init();
      } catch (e) {
        this.setState({ spinner: false });
      }
      this.setState({ spinner: false });
    }
  };

  onUnmount = async () => {
    if (this.mounted) {
      this.mounted = false;
      this.forceUpdate();
    }
  };

  onRequestClose = () => {
    const { closable, onClose, target } = this.props;
    // If the modal is not closable then do not exec the content of this function
    if (!closable) return;
    // If the onClose method passed down via props then execute it
    if (isFunction(onClose)) {
      onClose();
    }
    // Set the current modal to IDLE through this handy method
    modalStore.idle(target);
  };

  render() {
    const {
      props: {
        target,
        title,
        children,
        style,
        image,
        imageStyle,
        inject,
        width,
        closable,
        zIndex,
        onCloseCustom,
      },
      state: { spinner },
      mounted,
    } = this;

    if (
      !target ||
      modalStore.status[target] !== ModalStatus[target.toUpperCase()].PENDING
    ) {
      return false;
    }
    if (spinner) return <SpinnerModal />;
    if (!mounted) return false;
    return (
      <>
        <EmptyModal
          zIndex={zIndex}
          onRequestClose={this.onRequestClose}
          visible
        >
          <Column
            style={`bg-white br2 wp90 mxhp90 ${width || 'lg-wp30'} zi1 rp`}
          >
            {closable && (
              <TouchableIcon
                name='md-close'
                color='violet'
                size='22'
                style='ap at20 ar10'
                onPress={onCloseCustom || this.onRequestClose}
              />
            )}
            <Column style={`wp100 ph4 pt4 brt2 ${image ? 'aic' : 'aifs'}`}>
              {image && (
                <Image
                  source={image}
                  style={`cn100 mb6 ${imageStyle}`}
                  contain
                />
              )}
              <Title
                id={title}
                style={`violet ${image ? 'tac' : 'tal'} ts22`}
                casing='capitalize'
                inject={inject}
                outerStyle={!image && 'fpr32'}
              />
            </Column>
            <Column style='wp100 ff ph4 pb4 pt2 ofya'>
              <Column style={`wp100 ${style}`}>{children}</Column>
            </Column>
          </Column>
        </EmptyModal>
      </>
    );
  }
}

export default BaseModal;
