import i18next from 'i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { initReactI18next } from 'react-i18next';
import getTranslations from '../shared/translations/translations';

const instance = i18next
  .createInstance()
  .use(initReactI18next)
  .use(intervalPlural);

instance.init({
  resources: {},
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  defaultNS: 'translation',
  react: {
    bindI18n: 'languageChanged loaded',
    bindI18nStore: 'added',
  },
});

/**
 * Add translation after initiation
 * resourcesToBackend have problems
 */
['en', 'it', 'es', 'pt'].map((lang) => {
  getTranslations(lang).then((translation) => {
    instance.addResourceBundle(lang, 'translation', translation.default);
  });
});

export default instance;
