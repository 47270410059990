import { observable } from 'mobx';
import debounce from 'lodash/debounce';
import Store from '../various/store';
import Feedback from '../utils/feedback';
import authStore from './auth';
import postStore from './post';
import Err from '../utils/error';

const debounceDataCollectors = (fn, wait) => {
  let args = [];
  const debounced = debounce(() => {
    const respone = fn(Array.from(new Set(args)));
    args = [];
    return respone;
  }, wait);
  return (p) => {
    args = args.concat(Array.from(new Set(p)));
    return debounced();
  };
};

class RatingStore extends Store {
  @observable hasUserRatedGroup;

  @observable hasClickedBanner;

  @observable isOpen;

  @observable starsCount;

  @observable userRating;

  factory() {
    this.hasUserRatedGroup = false;
    this.hasClickedBanner = false;
    this.isOpen = false;
    this.starsCount = {};
    this.userRating = {};
  }

  async sendGroupRating(payload) {
    const response = await Feedback.post(
      '/rating/rateUser',
      payload,
      authStore.token
    );
    if (!Err.check(response)) return this.set('hasUserRatedGroup', true);
    return null;
  }

  async hasUserRated(sharingPostId) {
    const response = await Feedback.get(
      `/rating/hasUserRated?sharingPostId=${sharingPostId}`,
      authStore.token
    );
    this.set('hasUserRatedGroup', response);
  }

  async getHasUserRatedApp() {
    const response = await Feedback.get(
      '/rating/hasUserRatedEntity?entity=mobile_app',
      authStore.token
    );
    if (Err.check(response)) return false;
    return response;
  }

  async rateApp(stars) {
    const response = await Feedback.post(
      '/rating/rateEntity',
      {
        stars,
        reviewedEntity: 'mobile_app',
      },
      authStore.token,
      undefined
    );
    if (Err.check(response)) return false;
    return true;
  }

  async getRatingStars(adminIds) {
    const response = await Feedback.post(
      '/rating/findByAdminIds',
      adminIds,
      authStore.token
    );
    if (Err.check(response)) return false;
    return this.set('starsCount', { ...this.starsCount, ...response });
  }
  async getUserRating(sharingPostId) {
    const response = await Feedback.get(
      `rating/findUserRating?sharingPostId=${sharingPostId}`,
      authStore.token
    );
    if (Err.check(response)) return false;
    return this.set('userRating', { ...this.userRating, ...response });
  }

  get canUserOpenRatingPopup() {
    return (
      !this.hasUserRatedGroup &&
      postStore?.isUserJoinedOrSuspended &&
      this.isOpen
    );
  }

  get canUserEditRatingPopup() {
    return (
      this.hasUserRatedGroup &&
      postStore?.isUserJoinedOrSuspended &&
      this.isOpen
    );
  }

  getRatingStarsDebounced = debounceDataCollectors(
    (adminIds) => this.getRatingStars(adminIds),
    200
  );

  get canUserOpenRatingBanner() {
    return !this.hasUserRatedGroup && postStore?.isUserJoinedOrSuspended;
  }

  get canUserEditRatingBanner() {
    return this.hasUserRatedGroup && postStore?.isUserJoinedOrSuspended;
  }

  clearStore() {
    this.set('hasClosedTPassword', false);
    this.set('hasClickedBanner', false);
    this.set('isOpen', false);
  }
}

const ratingStore = new RatingStore();
export default ratingStore;
