import { observable } from 'mobx';
import { isEmpty, isString } from 'lodash';
import Chance from 'chance';
import Store from '../various/store';
import { CardStatus } from '../various/enums';
import authStore from './auth';
import { getErrorMessage, hasError } from '../utils/error';
import stripeStore from './stripe';
import Financial from '../utils/financial';
import userStore from './user';
import { dispatchPixel } from '../utils/pixel';
import captchaStore from './captcha';
import loaderStore from './loader';
import toastStore from './toast';

class CardStore extends Store {

  /**
   * Since previously only one card was allowed, the following data was intended to rappresent the saved card
   * Now multiple payment methods are allowed, so the following data is used to rappresent the selected card
   */

  @observable paymentMethodId

  @observable brand

  @observable captcha

  @observable cvc

  @observable detachable

  @observable expDate

  @observable expMonth

  @observable expYear

  @observable idempotency

  @observable last4

  @observable number

  @observable owner

  @observable status

  @observable recaptchaLoaded = false

  @observable recaptcha;

  /**
   * The payment methods of the logged user
   */
  @observable paymentMethods = []

  factory() {
    this.brand = '';
    this.cvc = '';
    this.expDate = '';
    this.expMonth = '';
    this.expYear = '';
    this.idempotency = '';
    this.last4 = '';
    this.number = '';
    this.owner = '';
    this.status = CardStatus.PENDING;
  }

  get paymentMethods() {
    return this.paymentMethods;
  }

  /**
   * Gets the payment methods of the logged user
   *
   * @returns {boolean}
   */
  async get(
    /**
     * @deprecated
     */
    poll = false
  ) {
    const savedResponse = await Financial.get('paymentMethod/saved', authStore.token);

    const paymentMethods = Object.values(savedResponse);

    this.set("paymentMethods", paymentMethods);

    if (paymentMethods?.length == 0) return this.set('status', CardStatus.SUCCESS_EMPTY);

    const defaultPaymentMethod = paymentMethods.find(paymentMethod => paymentMethod.isDefault) 
    
    return this.setPaymentMethod(defaultPaymentMethod?.paymentMethodId || paymentMethods[0].paymentMethodId);
  }

  async clearSelectedMethod() {
    this.set('paymentMethodId', null);
    this.set('brand', null);
    this.set('cvc', null);
    this.set('expDate', null);
    this.set('expMonth', null);
    this.set('expYear', null);
    this.set('last4', null);
    this.set('number', null);
    this.set('owner', null);
    this.set('status', CardStatus.SUCCESS_EMPTY);
  }

  async setPaymentMethod(id) {

    const card = this.paymentMethods.find(card => card.paymentMethodId === id);

    // Create a key with the full expiry date for the form
    card.expDate = `${card.expMonth} / ${card.expYear}`;
    // Create a key for the cvc
    card.cvc = 'XXX';

    // Append the X to the number
    card.number = `•••• •••• •••• ${card.last4}`;

    Object.entries(card).forEach(([key, value]) => this.set(key, value));

    // Set the status to SUCCESS_EMPTY to inform the rest of the app that this is just a placeholder
    this.set('status', CardStatus.SUCCESS);

    return true;
  }

  /**
   * Updates the logged user registered credit card
   *
   * @returns {boolean}
   */
  async save() {
    const recaptcha = await captchaStore.getRecaptcha(this.isRecaptchaEnabled);
    if (recaptcha.status === 'EXPIRED') return false;

    loaderStore.show('Loader.saveCard');
    const secret = await Financial.get('/getClientSecretForCardSetup', authStore.token);
    if (hasError(secret)) { loaderStore.hide(); return false; }

    const stripe = await stripeStore.setup(secret);
    if (hasError(stripe)) { loaderStore.hide(); return false; }

    this.generateIdempotency();

    const payload = {
      idempotencyId: this.idempotency,
      paymentMethodId: stripe.paymentMethod.id
    };

    const response = await Financial.post('/paymentMethod/attach', payload, authStore.token, {
      'Captcha-Token': recaptcha.token,
    });

    const defaultPaymentMethod = await Financial.post('/paymentMethod/default/' + stripe.paymentMethod.id, {}, authStore.token, {
      'Captcha-Token': recaptcha.token,
    });

    if (hasError(response)) {
      loaderStore.hide();
      toastStore.error(`AttachPaymentMethod.${getErrorMessage(response)}`);
      return false; 
    }

    if (hasError(defaultPaymentMethod)) {
      loaderStore.hide();
      toastStore.error(`AttachPaymentMethod.${getErrorMessage(defaultPaymentMethod)}`);
      return false; 
    }

    dispatchPixel('save-card');
    // Set the status to pending to hard refresh the page because of a stripe bug
    this.set('status', CardStatus.PENDING);
    userStore.updateFunnel('credit_card_saved');
    this.set("paymentMethodId", stripe.paymentMethod.id);
    return true;
  }

  get isRecaptchaEnabled() {
    return Number(userStore.configurations?.['frontEnd.public.cardForm.recaptcha.enabled']) > 0
        && !navigator.userAgent.includes('GabriPi');
  }

  /**
   * Removes the credit-card that the logged user has previously registed
   *
   * @returns {boolean}
   */
  async remove(id) {
    const response = await Financial.post('paymentMethod/detach/' + id, null, authStore.token);
    if (hasError(response)) {
      toastStore.error(`DetachPaymentMethod.${getErrorMessage(response)}`);
      return false;
    }
    const emptyCard = {
      number: '',
      brand: '',
      expDate: '',
      expMonth: '',
      expYear: '',
      owner: '',
      cvc: '',
    };
    Object.entries(emptyCard).forEach(([key, value]) => this.set(key, value));

    this.set('status', CardStatus.PENDING);

    await this.get();

    return true;
  }

  generateIdempotency() {
    this.set('idempotency', new Chance().guid());
  }

  get isChangeable() {
    return this.status === CardStatus.SUCCESS_CHANGE;
  }

  get isDetachable() {
    return this.detachable === true;
  }

  /**
   * Check if the credit card has been saved or not
   *
   * @returns {boolean}
   */
  get isSaved() {
    const { number, brand, expDate } = this;
    return isString(number)
      && !isEmpty(number)
      && isString(brand)
      && !isEmpty(brand)
      && isString(expDate)
      && !isEmpty(expDate);
  }

  get isSuccess() {
    return this.status === CardStatus.SUCCESS;
  }
}

const cardStore = new CardStore();
window.cardStore = cardStore;
export default cardStore;
