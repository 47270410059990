import { action, observable } from 'mobx'
import { HeaderTypes, Routes } from '../various/enums'
import Store from '../various/store'
import { isEmpty, isString } from 'lodash'
import Err from '../utils/error'
import withdrawStore from './withdraw'

class HeaderStore extends Store {
  @observable history
  @observable shadow
  @observable translateY
  @observable type
  @observable visible

  factory() {
    this.history = []
    this.shadow = false
    this.translateY = 0
    this.type = HeaderTypes.CLASSIC
    this.visible = false
  }

  /**
   * Changes the Header type
   *
   * @param {number} type
   */
  @action changeType(type) {
    if (typeof type === 'string') return false

    this.type = HeaderTypes[type.toString()]

    this.push(type)
  }

  /**
   * Hides the Header
   */
  @action hide() {
    this.translateY = -80

    // Use the visible variable to inform other components that the bottom tab is hidden
    this.visible = false
  }

  /**
   * Shows the Header
   */
  @action show() {
    this.translateY = 0

    // Use the visible variable to inform other components that the bottom tab is visible
    this.visible = true
  }

  /**
   * Calcualates when the Header should have a shadow
   *
   * @param {string} route
   */
  @action calculateShadow(route) {
    if (!isString(route) || isEmpty(route)) return Err.type('HeaderStore', 'calculateShadow')

    // In some cases there is an extra Top Tab inside the Bottom Tab stacks, we handle it with a switch to hide the header shadow for aesthetic
    switch (true) {
      case route.includes(Routes.HOME):
      case route.includes(Routes.SHARINGS):
      case route.includes(Routes.ACCOUNT):
      case route.includes('/feed'):
      case route.includes('/refunds'):
      case route.includes('/chat'):
        this.shadow = false
        break
      default:
        this.shadow = true
        break
    }
  }

  /**
   * Calculates when the Header should have a specific type
   *
   * @param {string} route
   */
  @action calculateType(route) {
    // In some cases there is a different header layout displayed, we handle it here
    switch (true) {
      case /\/post\/\d+\/chat/.test(route):
        this.type = HeaderTypes.CHAT
        break
      case /\/post\/\d+\/checkout\/success/.test(route):
        this.type = HeaderTypes.NONE
        break
      case /\/post\/\d+\/checkout/.test(route):
      case /\/post\/\d+\/payment/.test(route):
        this.type = HeaderTypes.CHECKOUT
        break
      case /\/post\/\d+/.test(route):
        this.type = HeaderTypes.POST
        break
      case /\/welcome\/\w+/:
        this.type = HeaderTypes.NONE
        break
      case /\/withdraw\/latam/.test(route):
        this.type = HeaderTypes.CLASSIC
        break
      case /\/withdraw/.test(route) && !withdrawStore.isIbanVerified:
      case /\/withdraw\/document/.test(route):
        this.type = HeaderTypes.WITHDRAW
        break
      default:
        this.type = HeaderTypes.CLASSIC
        break
    }
  }

  get _visible() {
    return this.visible && this.type !== HeaderTypes.NONE
  }
}

const headerStore = new HeaderStore()
export default headerStore
