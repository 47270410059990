import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { StatusBarTheme } from '.';
import { EXTRACTED } from '../shared/overlays/NewHeader';

const DynamicStatusBar = () => (
  <Switch>
    <Route path={EXTRACTED}>
      <StatusBarTheme style='light' color='#FFFFFF' />
    </Route>
    <Route path='/'>
      <StatusBarTheme style='light' color='#EAE8FE' />
    </Route>
  </Switch>
);

export default DynamicStatusBar;
