import authStore from '../../shared/stores/auth';

export class HTTPException extends Error {
  constructor(public status: number, public statusText: string, public data: any) {
    super(`${status} ${statusText}`);
  }
}
const parseResponse = <D, >(response: Response): Promise<D> => {
  if (response.headers.get('content-type')?.includes('application/json')) {
    return response.json().then((data) => {
      if (response.ok) {
        return data;
      }
      throw new HTTPException(response.status, response.statusText, data);
    });
  }
  if (response.ok) {
    return null;
  }
  throw new HTTPException(response.status, response.statusText, null);
};

export const getErrorMessage = (error: any) => {
  if (error instanceof HTTPException && 'message' in error.data) {
    return error.data.message.split(':')[1]?.trim();
  }
  return null;
};

export const getAuthorizationToken = () => authStore.token;

export type Fetcher<D> = (url: string, init?: RequestInit) => Promise<D>;
export const createFetch = <D>(baseUrl: string): Fetcher<D> => (url, init) => {
  const headers = new Headers(init?.headers ?? {});
  headers.append('Authorization', getAuthorizationToken());
  return fetch(
    `${baseUrl}${url}`,
    {
      ...init,
      headers,
    },
  )
    .then((response) => parseResponse<D>(response)) as Promise<D>;
};

export const fetchUms = <D, >(url: string, init?: RequestInit) => fetch(`${process.env.REACT_APP_UMS}${url}`, init)
  .then(parseResponse) as Promise<D>;
export const fetchFinancial = <D, >(url: string, init?: RequestInit) => fetch(`${process.env.REACT_APP_FINANCIAL}${url}`, init)
  .then(parseResponse) as Promise<D>;
export const fetchAb = <D, >(url: string, init?: RequestInit) => fetch(`${process.env.REACT_APP_AB}${url}`, init)
  .then(parseResponse) as Promise<D>;

export const fetchNetwork = createFetch(process.env.REACT_APP_NETWORK.replace(/\/$/, ''));
