import { action, observable } from 'mobx';
import {
  filter, find, get, isEmpty,
} from 'lodash';
import authStore from './auth';
import Store from '../various/store';
import Network from '../utils/network';
import Paging from '../various/paging';
import ratingStore from "./rating";
import networkStore from "./network";
import Error from "../utils/error";

class SharingStore extends Store {
  @observable created

  @observable filter

  @observable joined

  @observable paging

  @observable pending

  factory() {
    this.created = new Paging(() => this.getOwned());
    this.joined = new Paging(() => this.getJoined());
    this.pending = new Paging(() => this.getPending());
    this.filter = 'allPosts';
  }

  async getJoined() {
    const response = await Network.get(`joined?page=${this.joined.current}`, authStore.token);
    if (Error.check(response)) return response;
    ratingStore.getRatingStarsDebounced([...Object.values(response).map((x) => x?.admin?.id)]);
    return networkStore.getPostsRating(Object.values(response));
  }

  /**
   * Gets the posts that the logged user has joined
   *
   * @returns {boolean}
   */
  async getPending() {
    const response = await Network.get(`pending?page=${this.pending.current}`, authStore.token);
    if (Error.check(response)) return response;
    ratingStore.getRatingStarsDebounced([...Object.values(response).map((x) => x?.admin?.id)]);
    return networkStore.getPostsRating(Object.values(response));
  }

  /**
   * Gets the posts that the logged user has created
   *
   * @returns {boolean}
   */
  async getOwned() {
    const response = await Network.get(`mines?page=${this.created.current}`, authStore.token);
    if (Error.check(response)) return response;
    ratingStore.getRatingStarsDebounced([...Object.values(response).map((x) => x?.admin?.id)]);
    return networkStore.getPostsRating(Object.values(response));
  }

  /**
   * Inserts a new post inside the target section
   *
   * @param {string} target
   * @param {object} post
   */
  @action push(target, post) {
    get(this, target).unshift(post);
  }

  hasPendingRelation(id) {
    return find(this.pending.data, (p) => p.sharingPost?.adminId === parseInt(id));
  }

  get _pending() {
    return this.pending.data.slice().sort((_a, _b) => {
      const a = _a.userStatus === 'pending' ? 0 : _a.userStatus === 'accepted' && 1;
      const b = _b.userStatus === 'pending' ? 0 : _b.userStatus === 'accepted' && 1;

      return b - a;
    });
  }

  get accepted() {
    return filter(this.pending.data, (p) => p.userStatus === 'accepted');
  }

  get isCreatedEmpty() {
    return isEmpty(this.created.allPosts) && isEmpty(this.created.full) && isEmpty(this.created.pending);
  }
}

const sharingStore = new SharingStore();
export default sharingStore;
