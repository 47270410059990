import { CircularProgress } from '@material-ui/core';
import React, { Component } from 'react';
import EmptyModal from '../components/EmptyModal';

class SpinnerModal extends Component {
  render() {
    return (
      <EmptyModal visible>
        <CircularProgress color='primary' size={32} />
      </EmptyModal>
    );
  }
}

export default SpinnerModal;
