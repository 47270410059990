import { useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import { App as CapacitorApp } from '@capacitor/app';

const BackHandler = () => {
  const history = useHistory();
  useEffect(() => {
    const listener = CapacitorApp.addListener('backButton', () => {
      if (['/', '/v2/'].includes(history.location.pathname)) {
        CapacitorApp.exitApp();
        return;
      }
      history.goBack();
    });
    return () => listener.remove();
  }, [history]);
  return <></>;
};

export default BackHandler;
