import React from 'react';
import Row from '../layout/Row';
import TouchableRow from '../touchables/TouchableRow';
import Icon from '../natives/Icon';
import Span from '../natives/Span';

export type HelpSectionProps = {
  rowStyle?: string;
  helpWidth?: string;
  onPressHelp?: () => void;
  iconName?: string;
  iconColor?: string;
  text?: string;
  textStyle?: string;
};

const HelpSection: React.FC<HelpSectionProps> = ({
  rowStyle,
  helpWidth,
  onPressHelp,
  iconName,
  iconColor,
  text,
  textStyle,
}) => (
  <Row style={rowStyle}>
    <TouchableRow width={helpWidth} onPress={onPressHelp}>
      <Span
        id={text}
        style={textStyle}
      />
      <Icon name={iconName} color={iconColor} />
    </TouchableRow>
  </Row>
);

export default HelpSection;
