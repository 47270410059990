export const StatusModel = {
  STANDARD: {
    PENDING: 0,
    ERROR: 1,
    SUCCESS: 2,
  },
  IDLEABLE: {
    IDLE: 0,
    PENDING: 1,
    ERROR: 2,
    SUCCESS: 3,
  },
  EMPTIABLE: {
    PENDING: 0,
    ERROR: 1,
    SUCCESS: 2,
    SUCCESS_EMPTY: 3,
  },
};

export const AuthStatus = StatusModel.STANDARD;

export const UserStatus = {
  USER: {
    PENDING: 0,
    ERROR: 1,
    EXPIRED: 2,
    SUCCESS_OB: 3,
    SUCCESS: 4,
  },
  RENEWS: StatusModel.STANDARD,
  REFERRAL: StatusModel.STANDARD,
  SAVINGS: StatusModel.STANDARD,
};

export const NetworkStatus = {
  NETWORK: StatusModel.STANDARD,
  FEED: StatusModel.STANDARD,
  SERVICE: StatusModel.STANDARD,
  SERVICES: StatusModel.STANDARD,
  USERPOSTS: StatusModel.STANDARD,
};

export const SharingStatus = {
  PENDING: StatusModel.STANDARD,
  JOINED: StatusModel.STANDARD,
  CREATED: StatusModel.STANDARD,
};

export const PostStatus = StatusModel.STANDARD;

export const ContactStatus = {
  PREVIEW: {
    PENDING: 0,
    LOADING: 1,
    ERROR: 2,
    SUCCESS: 3,
  },
  INCOMING: StatusModel.IDLEABLE,
  CONTACTS: StatusModel.IDLEABLE,
  SUGGESTIONS: StatusModel.IDLEABLE,
  INVITED: StatusModel.IDLEABLE,
};

export const Routes = {
  HOME: '/home',
  SHARINGS: '/sharings',
  CREATE: '/create',
  CONTACTS: '/social',
  ACCOUNT: '/account',
};

export const TrustStatus = StatusModel.STANDARD;

export const WalletStatus = {
  BALANCE: StatusModel.STANDARD,
  HISTORY: StatusModel.STANDARD,
};

export const SettingStatus = StatusModel.STANDARD;

export const CreateStatus = StatusModel.STANDARD;

export const ModalStatus = {
  // SPECIAL
  STANDARD: StatusModel.IDLEABLE,
  DIALOG: StatusModel.IDLEABLE,
  FORM: StatusModel.IDLEABLE,
  // NORMAL
  CHANNEL: StatusModel.IDLEABLE,
  RELATIONSHIP: StatusModel.IDLEABLE,
  PAYMENT: StatusModel.IDLEABLE,
  AVATAR: StatusModel.IDLEABLE,
  CUSTOMPOST: StatusModel.IDLEABLE,
  ADMINCONTACTS: StatusModel.IDLEABLE,
  HOWWALLET: StatusModel.IDLEABLE,
  HOWPOST: StatusModel.IDLEABLE,
  SHARINGRULES: StatusModel.IDLEABLE,
  CHATPOST: StatusModel.IDLEABLE,
  SHAREPOST: StatusModel.IDLEABLE,
  SHAREPOSTS: StatusModel.IDLEABLE,
  EDITPOST: StatusModel.IDLEABLE,
  RENEWS: StatusModel.IDLEABLE,
  WITHDRAW: StatusModel.IDLEABLE,
  VERIFYPHONE: StatusModel.IDLEABLE,
  VERIFYIBAN: StatusModel.IDLEABLE,
  PASSWORD: StatusModel.IDLEABLE,
  REFUND: StatusModel.IDLEABLE,
  PROFILE: StatusModel.IDLEABLE,
  CREATEINFO: StatusModel.IDLEABLE,
  CONTACT: StatusModel.IDLEABLE,
  POSTMORE: StatusModel.IDLEABLE,
  UPLOADCOVER: StatusModel.IDLEABLE,
  VERIFYEMAIL: StatusModel.IDLEABLE,
  POSTREQUESTS: StatusModel.IDLEABLE,
  UPLOADDOCUMENT: StatusModel.IDLEABLE,
  VERSION: StatusModel.IDLEABLE,
  CREDITCARD: StatusModel.IDLEABLE,
  LEMONWAY: StatusModel.IDLEABLE,
  TPASSWORD: StatusModel.IDLEABLE,
  PUSHTUTORIAL: StatusModel.IDLEABLE,
  CHRISTMASINVITE: StatusModel.IDLEABLE,
  NEWYEARINVITE: StatusModel.IDLEABLE,
  FEEDBACKMODAL: StatusModel.IDLEABLE,
  POSTSIGNUPMODAL: StatusModel.IDLEABLE,
  FORGOTPASSWORDMODAL: StatusModel.IDLEABLE,
  REFERRALJOINERWELCOME: StatusModel.IDLEABLE,
  REFERRALSHAREMODAL: StatusModel.IDLEABLE,
  DELETEACCOUNTVALIDATIONMODAL: StatusModel.IDLEABLE,
};

export const SupportStatus = StatusModel.STANDARD;

export const NotificationStatus = {
  NOTIFICATIONS: StatusModel.STANDARD,
  REQUESTS: StatusModel.STANDARD,
};

export const HeaderTypes = {
  NONE: 0,
  CLASSIC: 1,
  POST: 2,
  CHAT: 3,
  CHECKOUT: 4,
  WITHDRAW: 5,
};

export const ChatStatus = {
  PENDING: 0,
  CLOSE: 1,
  OPEN: 2,
  AUTHORIZED: 3,
  NOT_AUTHORIZED: 4,
  ERROR: 5,
  PRIVATE: StatusModel.STANDARD,
  PUBLIC: StatusModel.STANDARD,
};

export const ProfileStatus = {
  PROFILE: StatusModel.STANDARD,
  POSTS: StatusModel.STANDARD,
  CONNECTIONS: StatusModel.STANDARD,
};

export const RefundStatus = StatusModel.STANDARD;

export const SearchStatus = {
  USERS: StatusModel.IDLEABLE,
  POSTS: StatusModel.IDLEABLE,
};

export const PostStep = {
  CHECKOUT: 1,
  SAFETY: 2,
  PAYMENT: 3,
  SUCCESS: 4,
};

export const WithdrawStatus = {
  ...StatusModel.EMPTIABLE,
  SUCCESS_CHANGE: 4,
};

export const CardStatus = {
  ...StatusModel.EMPTIABLE,
  SUCCESS_CHANGE: 4,
};

export const DocumentStep = {
  FRONT: 1,
  BACK: 2,
  ADDITIONAL_FRONT: 3,
  ADDITIONAL_BACK: 4,
  BOTH: 5,
  BALANCE: 6,
};

export const WelcomeStep = {
  LOGIN_OR_SIGNUP: 1,
  COMPLETE_REGISTRATION: 2,
};

export const Promos = {
  FREE4EVER: 1,
};

export const DocumentType = {
  IDENTITY_CARD: 'IDENTITY_CARD',
  DIGITAL: 'DIGITAL_IDENTITY_CARD',
  DRIVING_LICENSE: 'DRIVING_LICENSE',
  PASSPORT: 'PASSPORT',
  BANK_STATEMENT: 'BANK_STATEMENT',
  BOAT_LICENSE: 'BOAT_LICENSE',
  WEAPON_LICENSE: 'WEAPON_LICENSE',
  PENSION_BOOK: 'PENSION_BOOK',
  STATE_ISSUED_CARD: 'STATE_ISSUED_CARD',
  RESIDENT_PERMIT_US: 'RESIDENT_PERMIT_US',
  BORDER_CROSSING_CARD: 'BORDER_CROSSING_CARD',
  CHILD_CARD: 'CHILD_CARD',
  NYC_CARD: 'NYC_CARD',
  US_VISA_CARD: 'US_VISA_CARD',
  BIRTH_CERTIFICATE: 'BIRTH_CERTIFICATE',
  RESIDENT_PERMIT: 'RESIDENT_PERMIT',
  CITIZEN_CARD: 'CITIZEN_CARD',
  ELECTORAL_ID: 'ELECTORAL_ID',
  VALIDATE_UK: 'VALIDATE_UK',
  NID: 'NATIONAL_IDENTITY_DOCUMENT',
};

export const WalletParams = {
  TYPE: {
    ALL: 'ALL',
    PAYMENT: 'PAYMENT',
    PROMO: 'PROMO',
    WITHDRAW: 'WITHDRAW',
    REFUND: 'REFUND',
    PAYOUT: 'PAYOUT',
  },
  SUBTYPE: {
    NONE: null,
    RECEIVED: 'RECEIVED',
    SENT: 'SENT',
    AUTO_PAYOUT_FEATURE: 'AUTO_PAYOUT_FEATURE',
    AUTOMATIC: 'AUTOMATIC',
  },
  STATUS: {
    NONE: null,
    REFUND_PENDING: 'REFUND_PENDING',
    REFUND_DECLINED: 'REFUND_DECLINED',
    REFUNDED: 'REFUNDED',
    SETTLED: 'SETTLED',
    BLOCKED: 'BLOCKED',
    FAILED: 'FAILED',
    ACTIVE: 'ACTIVE',
    USED: 'USED',
  },
};

export const TPasswordRating = {
  VALID: 'VALID',
  WRONG: 'WRONG',
  TRIAL: 'TRIAL',
  UNKNOWN: 'UNKNOWN',
  MISSING: 'MISSING',
};

export const CryptoStatus = StatusModel.STANDARD;

export const TPasswordStatus = {
  JOINERS: StatusModel.STANDARD,
  RATING: StatusModel.STANDARD,
};

export const TPasswordJoinerStatus = {
  NOT_AUTHORIZED: 'NOT_AUTHORIZED',
  AUTHORIZED: 'AUTHORIZED',
  NOT_DEFINED: 'NOT_DEFINED',
  NOT_ASKED: 'NOT_ASKED',
};

export const Breakpoint = {
  MOBILE: 568,
  TABLET: 768,
  DESKTOP: 1024,
};

export const UserPromotionStatus = {
  FREE4EVER: {
    PAUSED: 'paused',
    PROGRESS: 'progress',
    REDEEM: 'redeem',
  },
  REFERRAL: {
    PENDING: 'pending',
    ACTIVE: 'active',
    USED: 'used',
  },
};

export const Relation = {
  ROOMMATE: 'ROOMMATE',
  FAMILY: 'FAMILY',
  FRIEND: 'FRIEND',
  TEAMMATE: 'TEAMMATE',
};

export const SearchBarStatus = {
  IDLE: 0,
  TYPING: 1,
  TYPED: 2,
  SEARCHED: 3,
};

export const PromoTag = {
  FREE_4_EVER: 'free_4_ever',
  AUTO_PROMO: 'auto_promo',
  REFUND_TOKEN: 'refund_token',
  VIRAL_LOOP: 'viral_loop',
  CROSS_SELLING: 'cross_selling_promo_joiner',
  BLACK_FRIDAY: 'promo_black_friday',
};

export const MediaMobGoal = {
  SIGNUP: 2,
  LANDING: 3,
  REQUEST_SENT: 4,
  PAYMENT: null,
};

export const OnBoardingChoice = {
  JOINER: 0,
  ADMIN: 1,
};

export const SiteContext = {
  NO_CONTEXT: 0,
  LANDING_PAGE: 1,
  SEARCH_BAR: 2,
  NETWORK: 3,
};
