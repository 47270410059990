import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Route, Switch } from 'react-router-dom';
import { TextZoom } from '@capacitor/text-zoom';
import { Capacitor } from '@capacitor/core';
import toastStore from 'src/shared/stores/toast';
import NordigenFallback from 'src/shared/views/NordigenFallback';
import AppLinearProgress from '@bit/together-price.core.components.app-linear-progress';
import recorder from '../shared/utils/recorder';
import Google from '../shared/utils/google';
import Facebook from '../shared/utils/facebook';
import appStore from '../shared/stores/app';
import modalStore from '../shared/stores/modal';
import authStore from '../shared/stores/auth';
import { AuthStatus, UserStatus } from '../shared/various/enums';
import AppReCaptcha from '../shared/components/AppReCaptcha';
import loaderStore from '../shared/stores/loader';
import userStore from '../shared/stores/user';

const BootStack = React.lazy(() => import('./BootStack'));
const AuthStack = React.lazy(() => import('./AuthStack'));
const OnBoardingStack = React.lazy(() => import('./OnBoardingStack'));

const { REACT_APP_VERSION: appVersion } = process.env;

const UserRoutes = observer(() => {
  switch (userStore.status.user) {
    case UserStatus.USER.PENDING:
      return <AppLinearProgress />;
    case UserStatus.USER.EXPIRED:
      toastStore.error('Auth.expiredToken');
      appStore.clearStorage();
      return <AppLinearProgress />;
    case UserStatus.USER.SUCCESS_OB:
      return <OnBoardingStack />;
    case UserStatus.USER.SUCCESS:
      return <AuthStack />;
    default:
      return <AppLinearProgress />;
  }
});

@observer
class Routes extends Component {
  componentDidMount() {
    recorder.init();
    Google.init();
    Facebook.init();
    window.addEventListener('resize', () =>
      appStore.set('screen.width', window.innerWidth)
    );
    this.init()
    appStore.getVersion().then(() => {
      if (appStore.version > appVersion) modalStore.pending('version');
    });
    if (Capacitor.getPlatform() !== 'web') {
      TextZoom.set({ value: 1.0 });
    }
  }

  init = async () => {
    await appStore.init();
    await authStore.init();
    await userStore.init();
  }

  renderRoutes = () => {
    switch (authStore.status) {
      case AuthStatus.PENDING:
        return <AppLinearProgress />;
      case AuthStatus.ERROR:
        return (
          <>
            <AppReCaptcha
              onErrored={() => loaderStore.hide()}
              onExpired={() => loaderStore.hide()}
            />
            <BootStack />
          </>
        );
      case AuthStatus.SUCCESS:
        return <UserRoutes />;
      default:
        return <AppLinearProgress />;
    }
  };

  render() {
    return (
      <>
        <Switch>
          <Route exact path='/nordigen-fallback' component={NordigenFallback} />
          <Route path='/'>{this.renderRoutes()}</Route>
        </Switch>
      </>
    );
  }
}

export default Routes;
