export const LoginModel = {
  signUp: null,
  token: null,
  user: {
    idUser: null,
    id: null,
    imageId: null,
    walletId: null,
    name: null,
    surname: null,
    email: null,
    trust: null,
    rating: null,
    payWith: null,
    cellphone: null,
    sex: null,
    city: null,
    countryResidence: null,
    countryBirth: null,
    birthday: null,
    facebookId: null,
    googleId: null,
    messengerId: null,
    ipAddress: null,
    verified: null,
    privacyCheck: null,
    firstTime: null,
    status: null,
    lastLogin: null,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    postalCode: null,
    address: null,
    mission: {
      id: null,
      userId: null,
      emailVerified: null,
      facebookConnected: null,
      googleConnected: null,
      phoneVerified: null,
      avatarUploaded: null,
      hypeConnected: null,
      n26Connected: null,
      citySexBirthday: null,
      interestsSet: null,
      firstSharingRequest: null,
      firstPayment: null,
      threePayments: null,
      sixPayments: null,
      firstPostCard: null,
      firstPaymentReceived: null,
      twelvePaymentsReceived: null,
      twentyfourPaymentsReceived: null,
      threeFriendsInvited: null,
      sixFriendsInvited: null,
      twelveFriendsInvited: null,
      fbShareAfterPayment: null,
      fbShareAfterNewPostCard: null,
      createdAt: null,
      updatedAt: null,
      deletedAt: null,
    },
  },
};

export const UserModel = {
  id: null,
  name: null,
  surname: null,
  email: null,
  trust: null,
  cellphone: null,
  verified: null,
  firstTime: null,
  lastLogin: null,
  lastSession: null,
  createdAt: null,
  image: null,
  country: null,
  lang: null,
  postCreated: null,
  postJoined: null,
  marketCountry: null,
  facebookId: null,
  googleId: null,
  promoToUse: null,
  promoToUseForRenew: null,
  promoToUseForFirstPayment: null,
  onboarding: {},
};

export const UserRiskManagementModel = {
  forcedBankAccount: false,
  forcedPayout: false,
  showGroupsInNetwork: true,
  createNewGroup: true,
  updateGroup: true,
  receivePayment: true,
  doPayment: true,
  doPayout: true,
  activitySettle: true,
  newJoiner: true,
  writeInChat: true,
  motivation: 'NOT_SELECTED',
};

export const CardModel = {
  details: {
    id: null,
    image: null,
    freeSlots: null,
    totalSlots: null,
    price: {
      currency: null,
      amount: null,
    },
    frequency: null,
    createdAt: null,
    title: null,
    public: null,
  },
  me: {
    status: null,
  },
  admin: {
    name: null,
    surname: null,
    id: null,
    trust: null,
    image: null,
  },
};

export const CategoryModel = [
  {
    category: null,
    description: null,
    id: null,
  },
];

export const ServiceModel = [
  {
    category: null,
    coverImage: null,
    id: null,
    price: null,
    name: null,
    relation: null,
    frequency: null,
    description: null,
    totalAvailability: null,
    type: null,
    tag: null,
  },
];

export const AchievementModel = {
  data: {
    description: null,
    image: null,
    info: null,
  },
  done: null,
};

export const MissionModel = {
  data: {
    description: null,
    image: null,
    info: null,
  },
  done: null,
};

export const InterestModel = {
  data: {
    description: null,
    image: null,
    info: null,
  },
  done: null,
};

export const InterestsModel = {
  courses: InterestModel,
  coworking: InterestModel,
  design: InterestModel,
  filmSeries: InterestModel,
  gaming: InterestModel,
  healtFitness: InterestModel,
  management: InterestModel,
  marketing: InterestModel,
  music: InterestModel,
  photography: InterestModel,
  sport: InterestModel,
  tech: InterestModel,
};

export const SettingNotificationModel = {
  emailChannel: null,
  facebookChannel: null,
  whatsappChannel: null,
  newJoiners: null,
  onMyPostCards: null,
  onPayments: null,
  promotions: null,
};

export const PrivacyModel = {
  profile: null,
  contacts: null,
};

export const UserSettingModel = {
  birthday: null,
  cellphone: null,
  city: null,
  countryBirth: null,
  countryResidence: null,
  email: null,
  facebookId: null,
  googleId: null,
  id: null,
  name: null,
  profileImage: null,
  sex: null,
  surname: null,
};

export const F4EModel = {
  amount: null,
  bannerStatus: null,
  status: null,
  promoTag: null,
  days: null,
};

export const PostModel = {
  details: {
    id: null,
    image: null,
    freeSlots: null,
    totalSlots: null,
    priceWithFee: null,
    priceNoFee: null,
    fee: null,
    frequency: null,
    subscription: null,
    createdAt: null,
    title: null,
    description: null,
    tag: null,
    relation: null,
  },
  admin: {
    name: null,
    surname: null,
    id: null,
    trust: null,
    image: null,
    postCreated: null,
    postJoined: null,
    lastSession: null,
    adminIsOnline: false,
    memberSince: null,
    facebookVerified: null,
    googleVerified: null,
    phoneVerified: null,
  },
  me: {
    status: null,
    relationWithAdmin: null,
  },
  members: [
    {
      id: null,
      name: null,
      surname: null,
      image: null,
      joinerSince: null,
      expiredAt: null,
      expired: null,
      status: null,
      trust: null,
    },
  ],
  promo: {
    free4Ever: F4EModel,
  },
};

export const ContactItemModel = {
  id: null,
  name: null,
  relation: {
    status: null,
    relation: null,
    sentBy: null,
  },
  social: {
    facebook: null,
    google: null,
  },
  surname: null,
  trust: null,
  userImage: null,
  userLinked: null,
};

export const ContactModel = {
  count: null,
  items: [ContactItemModel],
};

export const ProviderContactModel = {
  invitableUsers: 0,
  addableUsers: 0,
  canShowInviteAll: false,
  canShowAddAll: false,
};

export const ContactPreviewModel = {
  requests: ContactModel,
  contacts: ContactModel,
  suggestions: ContactModel,
};

export const NotificationModel = [
  {
    data: {
      image: null,
      sharingPostId: null,
      text: null,
      userId: null,
    },
    id: null,
    type: null,
  },
];

export const RequestModel = NotificationModel;

export const ServicePostModel = [PostModel];

export const ChatModel = [
  {
    createdAt: null,
    id: null,
    message: null,
    userId: null,
    userImage: null,
    userName: null,
  },
];

export const RefundModel = {
  count: null,
  items: [
    {
      adminName: null,
      adminSurname: null,
      monthlyPrice: null,
      paymentDate: null,
      postTitle: null,
      sharingPostId: null,
    },
  ],
};

export const RenewsModel = {
  count: null,
  sharingPosts: [
    {
      adminName: null,
      adminSurname: null,
      amount: null,
      autoRenew: null,
      postTitle: null,
      renewDate: null,
      sharingPostId: null,
    },
  ],
};

export const ReferralModel = {
  maxReferralsAvailable: null,
  promoBalance: null,
  promoBalanceExpended: null,
  promoBalanceForRenew: null,
  promoBalanceExpendedForRenew: null,
  referralLink: null,
  usersActivated: null,
  activated: {
    count: null,
    promoBalance: null,
    promoBalanceForRenew: null,
    users: [
      {
        activationDate: null,
        avatar: null,
        email: null,
        id: null,
        name: null,
        surname: null,
      },
    ],
  },
};

export const SavingsModel = {
  services: [
    {
      name: null,
      saving: null,
      withTp: null,
      withoutTp: null,
    },
  ],
  totalSaving: null,
};
