export const RequestNotifications = [
  'App\\Notifications\\Admin\\FastCheckout\\FastCheckoutSharingRequest',
  'App\\Notifications\\Admin\\FastCheckout\\FastCheckoutSharingRequestWithButtons',
  'App\\Notifications\\Joiner\\NewSharingInvite',
  'App\\Notifications\\Admin\\FakeSharingRequest',
  'App\\Notifications\\NewContactRequest'
] as const;


export type RequestType = typeof RequestNotifications[number];
