import React, { Component } from 'react'
import EmptyModal from './EmptyModal'
import Div from '../natives/Div'
import List from '../natives/List'
import appStore from '../stores/app'
import { observer } from 'mobx-react'
import TouchableRow from '../touchables/TouchableRow'
import Span from '../natives/Span'
import Icon from '../natives/Icon'
import _, { isNil } from 'lodash'
import Row from '../layout/Row'

@observer
class DropdownModal extends Component {
  renderItem = ({ item }) => {
    let onPress, index, active, disabled

    const { data, store, entry, color, onPressItem } = appStore.dropdown

    // The onPress function has as parameter the item output
    onPress = () => onPressItem(item.output, item.callback)

    // Get the value using the lodash get function
    const value = _.get(store, entry)

    // The index variable finds the active item index based on the current item output and the store entry value
    index = data.findIndex(object => (isNil(object.output) ? undefined : object.output === value))

    // So it can understand the active state of it
    active = index < 0 ? null : data[index].input

    // The disabled variable can be an array of disabled items that will be excluded from user selection based on a simple indexOf operation, if not then all values are selectable
    disabled = _.isArray(appStore.dropdown.disabled)
      ? appStore.dropdown.disabled.indexOf(item.output) > -1
      : item.disabled

    return (
      <FormDropdownRow key={item.input} {...item} color={color} active={active} onPress={onPress} disabled={disabled} />
    )
  }

  onRequestClose = () => {
    appStore.set('dropdown.visible', false)
  }

  render() {
    return (
      <EmptyModal visible={appStore.dropdown.visible} onRequestClose={this.onRequestClose} zIndex='zi40'>
        <Div style='bg-white wp90 mxhp90 lg-wp30 s10 br1 zi1 pv1 ofya'>
          <List data={appStore.dropdown.data} renderItem={this.renderItem} />
        </Div>
      </EmptyModal>
    )
  }
}

class FormDropdownRow extends Component {
  render() {
    const { input, active, disabled, icon, onPress } = this.props

    return (
      <TouchableRow disabled={disabled} onPress={onPress} style='p4 jcsb'>
        <Row style='wa ff'>
          {icon && <Icon name={icon} style='mr2' />}
          <Span id={input} value={input} style={`${disabled && 'black o30'}`} />
        </Row>
        <Icon name={`md-radio-button-${input === active ? 'on' : 'off'} `} style={disabled && 'o30'} />
      </TouchableRow>
    )
  }
}

export default DropdownModal
