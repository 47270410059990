import React, { createContext, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Skeleton } from '@material-ui/lab';
import t from '../stores/translate';

export const getCurrentLanguage = () => t.l;
export const i18n = (key: string) => t.get(key);
export type SupportedLocales = 'en' | 'es' | 'it' | 'ca' | 'pt';
export type LoadTranslationsFn = (locale: SupportedLocales) => Promise<any>;
export const useTranslations = (
  init: LoadTranslationsFn,
  locale: string
): boolean => {
  const [loading, setLoading] = useState(!t.isLoaded(init));
  useEffect(() => {
    if (t.isLoaded(init)) return;
    setLoading(true);
    t.load(init).then(() => setLoading(false));
  }, [locale]);
  return loading;
};
type TranslationsContextValue = {
  loading?: boolean;
  overrides: Record<string, string>;
};
const TranslationsContext = createContext<TranslationsContextValue>({
  loading: false,
  overrides: {},
});
const TranslationsProvider: React.FC<TranslationsProviderProps> = ({
  init,
  locale,
  overrides = {},
  children,
}) => {
  const { loading, overrides: previusOverrides } =
    useContext(TranslationsContext);
  const currentLoading = useTranslations(init, locale);
  return (
    <TranslationsContext.Provider
      value={{
        loading: currentLoading || loading,
        overrides: {
          ...overrides,
          ...previusOverrides,
        },
      }}
    >
      {children}
    </TranslationsContext.Provider>
  );
};
type TranslationsProviderProps = {
  init: LoadTranslationsFn;
  overrides?: Record<string, string>;
  locale: string;
};
export type I18NProviderProps = Pick<
  TranslationsProviderProps,
  'init' | 'overrides'
>;
export const I18NProvider: React.FC<I18NProviderProps> = observer((props) => (
  <TranslationsProvider {...props} locale={t.l} />
));
export const preloadTranslations = (init: LoadTranslationsFn) => {
  t.load(init);
};

export const createTranslationInit = (
  translations: Record<SupportedLocales, () => Promise<any>>,
  preload: boolean = true
): LoadTranslationsFn => {
  const init: LoadTranslationsFn = (locale) =>
    (translations[locale] || translations.en)();
  if (preload) {
    preloadTranslations(init);
  }
  return init;
};
export type I18NProps = {
  name: string;
  value: string;
  values?: Record<string, React.ReactNode>;
  component?: React.FC;
};
export const getTranslationValue = (key) => t.get(key);
const I18N: React.FC<I18NProps> = ({
  name,
  value,
  values = {},
  component: Component = React.Fragment,
}) => {
  const { loading } = useContext(TranslationsContext);
  // @ts-ignore
  const texts = value?.split(/{{|}}/gi)?.flatMap((v) => {
    if (v.trim() in values) {
      return values[v.trim()];
    }
    return v;
  });
  if (!loading) {
    return (
      <Component>{(texts?.length === 1 ? texts[0] : texts) || name}</Component>
    );
  }
  return (
    <Skeleton
      variant='text'
      component='span'
      width='90%'
      style={{ minWidth: '60px' }}
    />
  );
};
const DefaultI18N = observer(({ name, values, component }) => (
  <TranslationsContext.Consumer>
    {({ overrides }) => {
      const realName = overrides[name] || name;
      return (
        <I18N
          name={name}
          value={t.get(realName) || realName}
          values={values}
          component={component}
        />
      );
    }}
  </TranslationsContext.Consumer>
)) as React.FC<Pick<I18NProps, 'name' | 'values' | 'component'>>;

export const createI18nText = <P extends keyof any>(key: string) =>
  (({ children, ...props }) => (
    <DefaultI18N name={key} values={props} />
  )) as React.ComponentType<Record<P, React.ReactNode>>;

export default DefaultI18N;
