import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import loaderStore from '../stores/loader';
import t from '../stores/translate';

@observer
class Loader extends Component {
  render() {
    if (!loaderStore.visible) return false;
    return (
      <Grid
        className='fp at0 al0 bg-white zi40 hd100'
        container
        direction='column'
        alignItems='center'
        justifyContent='center'
      >
        <CircularProgress />
        <Typography
          className='mt4'
          variant='body1'
          color='textPrimary'
          align='center'
        >
          {t.get(loaderStore.message)}
        </Typography>
      </Grid>
    );
  }
}

export default Loader;
