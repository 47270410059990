import React, {Component, CSSProperties } from 'react';
import { observer } from 'mobx-react';
import {
  get, isString, isEmpty, isArray,
} from 'lodash';
import translate from '../../stores/translate';
import Regex from '../../various/regex';

export type SpanProperties = {
    id?: string;
    inject?: Array<any>;
    casing?: string;
    outerStyle?: string;
    numberOfLines?: number;
    animated?: CSSProperties;
    link?: string;
    value?: string;
    style?: string;
}

@observer
class Span extends Component<SpanProperties> {
    onPress = (pressable) => {
      if (!pressable) return false;

      const { link } = this.props;
      if (isString(link) && !isEmpty(link)) {
        window.open(link.match(Regex.link) ? link : get(translate.links, link));
      }
    }

    render() {
      const {
        id, inject, casing, outerStyle, numberOfLines, animated,
      } = this.props;

      let style; let maskStyle; let onPress;

      // Style is equal to the style given in the props
      style = this.props.style ? `${this.props.style} ${numberOfLines && 'toe'}` : '';

      // Get the translated value through the provided id, if not found show the raw value
      const value = translate.get(id, inject, casing) || translate.casify(this.props.value, casing);

      // MP Style stands for margin and padding style,
      // this is done to avoid using outerStyle when not necessary
      const mpStyle = isString(style) ? (style.match(Regex.textFind) || []).join(' ') : '';
      if (mpStyle) style = style.replace(Regex.textReplace, '');

      return (
        <span id={id} className={`${mpStyle} ${outerStyle}`} style={animated}>
          {isArray(value) ? (
            value.map((v, k) => {
              maskStyle = v.style ? `${style.replace(/(tft|tfr|tfsb|tfb)/gm, '')} ${v.style}` : style;
              onPress = () => this.onPress(Boolean(v.style));
              return (
                <span onClick={onPress} key={k} className={maskStyle}>
                  {v.value}
                </span>
              );
            })
          ) : (
            <span className={style}>{value}</span>
          )}
        </span>
      );
    }
}

export default Span;
