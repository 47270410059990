import { observable } from 'mobx';
import { has, includes } from 'lodash';
import Server from '../utils/server';
import authStore from './auth';
import Err from '../utils/error';
import { NotificationStatus } from '../various/enums';
import toastStore from './toast';
import Store from '../various/store';
import Notification from '../utils/notification';
import Network from '../utils/network';
import Paging from '../various/paging';
import Ums from '../utils/ums';

const PostRequestTypes = [
  'App\\Notifications\\Admin\\NewSharingRequest',
  'App\\Notifications\\Admin\\NewSharingAndUserRequest',
  'App\\Notifications\\Admin\\FastCheckout\\FastCheckoutSharingRequest',
];

class NotificationStore extends Store {
  @observable notifications;

  @observable requests;

  @observable status;

  @observable summary;

  factory() {
    this.summary = {
      notRead: 0,
    };
    this.notifications = new Paging(() => this.getNotifications());
    this.requests = new Paging(() => this.getRequests());
  }

  async get() {
    let notifications;
    let requests;

    notifications = await this.notifications.getFirstPage();
    requests = await this.requests.getFirstPage();

    return notifications && requests;
  }

  /**
   * Gets both notifications and requests
   *
   * @return {boolean}
   */
  async getNotifications() {
    let notifications;

    // Fetch both notifications and requests with this single server get request
    notifications = await Notification.get(
      `findNotifications?page=${this.notifications.current}`,
      authStore.token
    );
    if (Err.check(notifications))
      return this.set(
        'status.notifications',
        NotificationStatus.NOTIFICATIONS.ERROR
      );

    return notifications;
  }

  /**
   * Filters the notifications array to only get the requests
   *
   * @param {object} notifications
   */
  async getRequests() {
    let requests;

    requests = await Notification.get(
      `findNotificationRequests?page=${this.requests.current}`,
      authStore.token
    );
    if (Err.check(requests))
      return this.set('status.requests', NotificationStatus.REQUESTS.ERROR);

    return requests;
  }

  async isAdminOnline(adminId) {
    const isOnline = await Notification.get(
      `chatInfo/isAdminOnline?adminId=${adminId}`,
      authStore.token
    );
    return Err.check(isOnline) ? false : isOnline;
  }

  async getNotificationsSummary() {
    const summary = await Notification.get(
      'notificationsSummary',
      authStore.token
    );
    if (Err.check(summary)) return null;
    this.set('summary', summary);
  }

  resetNotiticationPage = async () =>
    Promise.all([
      this.notifications.getFirstPage(),
      this.requests.getFirstPage(),
      this.getNotificationsSummary(),
    ]);

  /**
   * Marks a notification as read
   *
   * @param {number} id
   * @returns {Promise<boolean>}
   */
  async markAsRead(id) {
    // Try to mark the notification or request as read through this server post request
    const newResponse = await Notification.post(
      `/readNotification/${id}`,
      {},
      authStore.token
    );
    if (Err.check(newResponse)) return false;

    // Remove it from the local array of this store to give the user realtime feedback
    await this.resetNotiticationPage();

    return true;
  }

  readAllNotification = async () => {
    const newResponse = await Notification.post(
      '/readUserNotifications',
      {},
      authStore.token
    );
    if (Err.check(newResponse)) return false;

    await this.resetNotiticationPage();

    return true;
  };

  /**
   * Sets a request either as accepted or declined
   *
   * @param {number} id
   * @param {string} type
   * @param {string} action
   * @param {number} sharingRequestId
   * @param {number} userId
   * @param {number} sharingInviteId
   * @returns {boolean}
   */
  async setRequest(
    id,
    type,
    action,
    sharingRequestId,
    sharingInviteId,
    userId
  ) {
    let endpoint;
    let method;
    let payload;
    let headers;
    let api = Server;

    switch (type) {
      case 'App\\Notifications\\Admin\\NewSharingRequest':
      case 'App\\Notifications\\Admin\\NewSharingAndUserRequest':
      case 'App\\Notifications\\Admin\\FastCheckout\\FastCheckoutSharingRequest':
      case 'App\\Notifications\\Admin\\FastCheckout\\FastCheckoutSharingRequestWithButtons':
        endpoint = `sharingRequests/${action}SharingRequest`;
        payload = { notificationId: id, sharingRequestId };
        method = 'post';
        headers = { 'content-type': 'application/json' };
        api = Network;
        break;
      case 'App\\Notifications\\Joiner\\NewSharingInvite':
        endpoint = `sharingPost/${action}SharingInvite`;
        payload = { notificationId: id, sharingRequestId };
        method = 'post';
        headers = { 'content-type': 'application/json' };
        api = Network;
        break;
      case 'App\\Notifications\\NewContactRequest':
        endpoint = '/social/acceptRelationRequest';
        method = 'post';
        payload = { notificationId: id, accepted: action === 'accept' };
        headers = { 'content-type': 'application/json' };
        api = Ums;
        break;
      case 'App\\Notifications\\NewUserRelationUpdate':
        endpoint = `user/${userId}/${action}_update_relation`;
        method = 'post';
        break;
      case 'App\\Notifications\\Admin\\FakeSharingRequest':
        endpoint = `sharingPost/validateSharingPost?notificationId=${id}&declined=${
          action === 'decline'
        }`;
        headers = { 'content-type': 'application/json' };
        method = 'post';
        api = Network;
        break;
      default:
        return false;
    }
    // Try to accept or decline the request through the server
    const response = await api[method](
      endpoint,
      payload,
      authStore.token,
      headers
    );
    if (Err.check(response)) return toastStore.error(`Request.${action}`);

    // Remove it from the local array of this store to give the user realtime feedback
    await Promise.all([
      this.notifications.getFirstPage(),
      this.requests.getFirstPage(),
      this.getNotificationsSummary(),
    ]);
    return true;
  }

  get requestsByPost() {
    let requests;

    requests = {};
    for (const request of this.requests.data) {
      if (!includes(PostRequestTypes, request.type)) continue;

      if (!has(requests, request.data.sharingPostId))
        requests[request.data.sharingPostId] = [request];
      else requests[request.data.sharingPostId].push(request);
    }

    return requests;
  }
}

const notificationStore = new NotificationStore();
export default notificationStore;
