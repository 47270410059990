import { observable } from 'mobx';
import moment from 'moment';
import 'moment/locale/it';
import 'moment/locale/es';
import { isEmpty } from 'lodash';
import Err from '../utils/error';
import Store from '../various/store';

class MomentStore extends Store {
  @observable e

  factory() {
    this.e = moment;
  }

  /**
   * Changes moment language
   *
   * @param {string} language
   */
  setLocale(language) {
    if (isEmpty(this.e)) return Err.type('MomentStore', 'setLocale');
    this.e.locale(language);
  }
}

const momentStore = new MomentStore();
export default momentStore;
