import { action, observable } from 'mobx'
import { Routes } from '../various/enums'
import Store from '../various/store'

class BottomStore extends Store {
  @observable opacity
  @observable translateY
  @observable visible

  factory() {
    this.opacity = 0
    this.translateY = 80
    this.visible = false
  }

  /**
   * Hides the BottomTab
   */
  @action hide() {
    this.translateY = 80
    this.opacity = 0

    // Use the visible variable to inform other components that the bottom tab is hidden
    this.visible = false
  }

  /**
   * Shows the BottomTab
   */
  @action show() {
    this.translateY = 0
    this.opacity = 1

    // Use the visible variable to inform other components that the bottom tab is visible
    this.visible = true
  }

  /**
   * Calculates if the BottomTab should be visible based on the given route
   *
   * @param {string} route
   */
  @action calculateVisibility(route) {
    switch (true) {
      case route.includes(Routes.ACCOUNT):
      case route.includes(Routes.CONTACTS):
      case route.includes(Routes.HOME):
      case route.includes(Routes.SHARINGS):
        this.show()
        break
      case route.includes(Routes.CREATE):
      default:
        this.hide()
        break
    }
  }
}

const bottomStore = new BottomStore()
export default bottomStore
