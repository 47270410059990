/* eslint-disable max-len */
/* eslint-disable import/no-webpack-loader-syntax */

import React, { FunctionComponent, useEffect, useState } from 'react';
import { Network } from '@capacitor/network';
import isFastNet from 'isfastnet';
import { Preferences } from '@capacitor/preferences';
import moment from 'moment';
import toastStore from 'src/shared/stores/toast';
import { Trans } from 'react-i18next';
import NoConnection from '-!react-svg-loader!./assets/noConnection.svg';
import FullPageError from '../FullPageError';

const detectSlowConnection = () =>
  new Promise((resolve) => {
    isFastNet(
      (value) => {
        resolve(!value);
      },
      {
        timesToTest: 5, // optional, number of times to load the image, default is 5
        threshold: 800, // optional, threshold in ms after which internet speed is considered slow
        image: 'https://www.google.com/images/phd/px.gif', //  optional, url of the tiny image to load
        allowEarlyExit: true, // optional, if the first request takes greater than threshold*3 ms then the function exits with false
      }
    );
  });
const NetworkConnection: FunctionComponent = ({ children }) => {
  const [isConnected, setIsConnected] = useState(true);
  const [isSlowConnection, setIsSlowConnection] = useState(false);
  const checkInterval = 5_000;

  const getTimeout = async () => {
    const { value } = await Preferences.get({
      // Get timeout value
      key: 'Network.timeout',
    });
    const diff = value ? +value - moment().valueOf() : checkInterval; // check diff
    if (diff <= checkInterval) {
      // if timeout is expired then update it
      Preferences.set({
        key: 'Network.timeout',
        value: `${moment().add(5, 'minutes').valueOf()}`,
      });
    }
    return Math.max(diff, checkInterval);
  };
  const checkConnection = () => {
    // if there is no connection do not check the connection
    if (isConnected)
      detectSlowConnection().then((res: boolean) => {
        setIsSlowConnection(res);
        if (res) {
          toastStore.error('SlowConnection.description');
        }
      });
  };

  const reconnect = () => {
    Network.getStatus().then(({ connected }) => {
      setIsConnected(connected);
    });
  };

  // Check after app is loaded
  useEffect(() => {
    Network.addListener('networkStatusChange', ({ connected }) => {
      setIsConnected(connected);
      setTimeout(checkConnection, checkInterval); // check after connection is changed
    });
    let timer;
    getTimeout().then((remainingTime) => {
      timer = setTimeout(checkConnection, remainingTime);
    });
    return () => {
      clearTimeout(timer);
      Network.removeAllListeners();
    };
  }, []);

  // check in each 5 minutes
  useEffect(() => {
    const timer = setInterval(checkConnection, 300_000);

    return () => {
      clearInterval(timer);
    };
  }, [isSlowConnection]);

  return (
    <>
      {/* <link rel='preload' as='image' href='/assets/noConnection.svg' /> */}
      <FullPageError
        open={!isConnected}
        icon={<NoConnection />}
        description={<Trans i18nKey='NoConnection.body' />}
        onClose={reconnect}
      />
      {isConnected ? children : null}
    </>
  );
};

export default NetworkConnection;
