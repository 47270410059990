import { useEffect } from 'react';

class Script {
  constructor(public src: string, public callback?: () => any) {
    this.load();
  }

  load() {
    const script = document.createElement('script');
    script.src = this.src;
    script.onload = this.callback;
    document.body.appendChild(script);
  }
}

export default Script;

type InjectScriptProps = {
  src?: string,
  body?: string,
  onLoad?: () => void,
};
export const injectScript = ({ src, body, onLoad }: InjectScriptProps) => {
  const loaded = document.querySelector(`[src="${src}"]`);
  if (loaded) {
    console.warn('script already loaded', src);
    return loaded;
  }
  const script = document.createElement('script');
  if (src) script.src = src;
  if (body) script.textContent = body;
  if (onLoad) script.onload = onLoad;
  document.body.appendChild(script);
  return script;
};
export const useInjectScript = ({ src, body, onLoad }: InjectScriptProps) => {
  useEffect(() => {
    const script = injectScript({ src, body, onLoad });
    return () => script.remove();
  }, [src, body, onLoad]);
};
