import { RequestType } from 'src/shared/constants/feed';
import sendRequest from '../sendRequest';

export type Notification = {
  notificationId: string;
  notificationType: string;
  sharingRequestId: number;
  text: string;
};

export const ACCEPTED_NOTIFICATION_TYPES: Array<RequestType> = ['App\\Notifications\\Admin\\FastCheckout\\FastCheckoutSharingRequestWithButtons'];

export type RequestAction = 'accept' | 'decline';

export const handleRequest = (
  action: RequestAction,
  notification: Notification
) => {
  const { notificationId, sharingRequestId, notificationType } = notification;

  return sendRequest({
    id: notificationId,
    type: notificationType,
    action,
    sharingRequestId,
    sharingInviteId: null,
    sharingPostId: null,
    userId: null,
    callback: null,
  });
};

export const notificationOpenedHandler = (action: RequestAction, notification?: Notification) => {
  if(notification && notification.notificationId && ACCEPTED_NOTIFICATION_TYPES.includes(notification.notificationType)) return handleRequest(action, notification);
}