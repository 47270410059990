import React, { Component } from 'react';
import Span from '../natives/Span';
import BigButton from './BigButton';
import modalStore from '../stores/modal';
import { observer } from 'mobx-react';
import BaseModal from './BaseModal';
import { has } from 'lodash';
import Form from '../forms/Form';

@observer
class FormModal extends Component {
  render() {
    const {
      radix,
      entries,
      forms,
      validators,
      inject,
      store,
      image,
      closable,
      onPress,
      children,
    } = modalStore.form;

    let injectTitle, injectDescription;

    injectTitle = has(inject, 'title') && inject.title;
    injectDescription = has(inject, 'description') && inject.description;

    return (
      <BaseModal
        target='form'
        title={`${radix}.title`}
        inject={injectTitle}
        style='aifs'
        image={image}
        closable={closable}
      >
        <Span
          id={`${radix}.description`}
          inject={injectDescription}
          style={`gray ts12 mb4 ${image && 'asc tac'}`}
        />
        <Form
          entries={forms}
          validators={validators}
          store={store}
          onSubmit={onPress}
        />
        <BigButton
          title={`${radix}.button`}
          onPress={onPress}
          entries={entries}
          validators={validators}
          store={store}
          style='mt4'
        />
        {children}
      </BaseModal>
    );
  }
}

export default FormModal;
