import React, { useEffect } from 'react';
import { AppBar, Button, Container, Grid, Toolbar, Typography, } from '@material-ui/core';
import SystemUpdateIcon from '@material-ui/icons/SystemUpdate';
import Divider from '@material-ui/core/Divider';
import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update';
import tools from '../shared/assets/app/tools.svg';
import I18N from '../shared/translations/I18N';
import Logo from '../modules/onboarding-v2/assets/Logo.svg';

/**
 * Supported platform(s): Android, iOS
 * Opens the app store entry of the app in the Play Store (Android) or App Store (iOS).
 */
const openAppStore = async () => {
  await AppUpdate.openAppStore();
};

/**
 * Supported platform(s): Android
 * Performs an immediate in-app update.
 */
const performImmediateUpdate = async () => {
  const info = await AppUpdate.getAppUpdateInfo();
  if (info.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
    return;
  }
  if (!info.immediateUpdateAllowed) {
    return;
  }
  await AppUpdate.performImmediateUpdate();
};

const VersionNotSupported = () => {
  useEffect(() => {
    performImmediateUpdate();
  }, []);
  return (
    <>
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <img
            alt="logo"
            src={Logo}
            style={{
              paddingLeft: 5,
              paddingTop: 5
            }}
          />
        </Toolbar>
      </AppBar>
      <Container
        maxWidth="sm"
        style={{
          padding: 25,
          paddingTop: 50
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container justify="space-around">
              <Grid item>
                <img src={tools} style={{ width: 96 }} alt="tools" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h1"
              style={{ fontSize: '24px' }}
              align="left"
            >
              <I18N name="VersionNotSupported.title" />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" align="left" style={{ whiteSpace: 'pre-line' }}>
              <I18N name="VersionNotSupported.description" />
            </Typography>
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              size="large"
              color="primary"
              endIcon={<SystemUpdateIcon />}
              onClick={openAppStore}
            >
              <I18N name="VersionNotSupported.button" />
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default VersionNotSupported;
