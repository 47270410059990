import React, { Component, CSSProperties } from 'react';
import Geosuggest, { Suggest } from 'react-geosuggest';
import FormItem from '../FormItem';
import cryptoStore from '../../stores/crypto';
import t from '../../stores/translate';

export type GeoSuggestFormProps = {
    entry?: any,
    style?: CSSProperties,
    title?: string;
    disabled?: boolean,
    store?: any,
}

class GeoSuggestForm extends Component<GeoSuggestFormProps> {
  onSuggestSelect = (place : Suggest) => {
    if (place) cryptoStore.set(this.props.entry, place.gmaps.address_components.map((x) => x));
    else cryptoStore.set(this.props.entry, undefined);
  }

  render() {
    const {
      style, title, disabled, entry,
    } = this.props;

    return (
      <>
        <FormItem style={style} title={title} border={false} margin={false} />
        <Geosuggest placeholder={t.get('GeoSuggestForm.inputPlaceholder')} onSuggestSelect={this.onSuggestSelect} disabled={disabled} />
      </>
    );
  }
}

export default GeoSuggestForm;
