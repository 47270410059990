import React, { Component } from 'react';
import Div from './Div';

class Icon extends Component {
  render() {
    const {
      name,
      style,
      onRef,
      color = 'black',
      size = 16,
    } = this.props;

    return (
      <Div setRef={onRef} style={`cn${size} jcc aic ${style}`}>
        <i className={`icon ion-${name || 'ios-logo-android'} ts${size} ${color}`} />
      </Div>
    );
  }
}

export default Icon;
