import { action, observable } from 'mobx';
import { cloneDeep, has, isEmpty, isString, uniq, } from 'lodash';
import SecureStorage from '../utils/storage';
import Err from '../utils/error';
import Store from '../various/store';
import Format from '../utils/format';
import Network from '../utils/network';
import authStore from './auth';
import networkStore from './network';
import searchStore from './search';
import Retention from '../utils/retention';

class TrackingStore extends Store {
  @observable referral;

  @observable utm;

  @observable affiliate;

  @observable promo;

  @observable ab;

  @observable skipOnBoarding;

  @observable from;

  @observable experiment;

  factory() {
    this.referral = {};
    this.utm = {};
    this.affiliate = {};
    this.ab = {};
    this.experiment = null;
    this.from = null;
    this.promo = null;
    this.skipOnBoarding = null;
  }

  async send(source, posts) {
    let payload;
    let response;

    payload = {
      sourceInfo: source,
      postsCard: posts,
      serviceIds: this.getServiceIdsFromSource(source),
      searchInfo: searchStore.query,
    };

    response = Network.post('userTracker/trackUserMovements', payload, authStore.token);
    if (Err.check(response)) return false;

    return true;
  }

  getServiceIdsFromSource(source) {
    switch (source) {
      case 'SERVICE_CARD':
        return [networkStore.serviceByTag(networkStore.serviceTag)?.id];
      case 'SEARCH_BAR':
        return uniq(searchStore.posts.data.reduce((r, v) => [...r, v.details.serviceId], []));
      case 'ON_BOARDING':
      default:
        return networkStore.services.reduce((r, v) => [...r, v.id], []);
    }
  }

  /*
  * Track user promotion
  *
  * */
  async trackUserPromo(payload) {
    const response = await Retention.post('/setUserUtmPromoSource', payload, authStore.token, null);
    if (Err.check(response)) return false;
    return true;
  }

  /**
   * Fetches all the keys saved by this store inside the storage and sets them to the current store
   *
   * @returns {Promise<boolean>}
   */
  async fromStorage() {
    // Fetch all data saved inside the storage at the AuthStore position
    try {
      const data = await SecureStorage.get('TrackingStore');
      Object.entries(data).forEach(([key, value]) => this.set(key, value));
    } catch (e) {
      console.error(e);
      return false;
    }
    return true;
  }

  @action setPromo(promo) {
    this.promo = promo;
  }

  /**
   * Saves the given data to the storage
   *
   * @param {string} last
   * @returns {boolean}
   */
  async toStorage(last) {
    // Save only the auth token to the storage
    const data = {
      promo: this.promo,
      referral: {
        ...this.referral,
        createdAt: last === 'referral' ? Date.now().valueOf() : this.referral.createdAt || 0,
      },
      utm: {
        ...this.utm,
        createdAt: last === 'utm' ? Date.now().valueOf() : this.utm.createdAt || 0,
      },
      affiliate: {
        ...this.affiliate,
        createdAt: last === 'affiliate' ? Date.now().valueOf() : this.affiliate.createdAt || 0,
      },
      ab: {
        ...this.ab,
        createdAt: last === 'ab' ? Date.now().valueOf() : this.ab.createdAt || 0,
      },
      experiment: this.experiment,
      from: this.from,
      skipOnBoarding: this.skipOnBoarding,
    };
    try {
      await SecureStorage.set('TrackingStore', data);
    } catch (e) {
      console.error(e);
      return false;
    }
    return true;
  }

  /**
   * Clear the storage
   */
  async clearStorage() {
    const data = {
      referral: {
        createdAt: 0,
      },
      utm: {
        createdAt: 0,
      },
      affiliate: {
        createdAt: 0,
      },
      ab: {
        createdAt: 0,
      },
      experiment: null,
      from: null,
      skipOnBoarding: null,
    };
    try {
      await SecureStorage.set('TrackingStore', data);
    } catch (e) {
      console.error(e);
      return false;
    }
    return true;
  }

  get _referral() {
    let referral;

    referral = cloneDeep(this.referral);
    delete referral.createdAt;

    return referral;
  }

  get _utm() {
    let utm;

    utm = cloneDeep(this.utm);
    delete utm.createdAt;

    return utm;
  }

  get _affiliate() {
    let affiliate;

    affiliate = cloneDeep(this.affiliate);
    delete affiliate.createdAt;

    return affiliate;
  }

  get hasReferral() {
    return (
      isString(this.referral.referrer_id)
      && !isEmpty(this.referral.referrer_id)
      && isString(this.referral.tag)
      && !isEmpty(this.referral.tag)
      && isString(this.referral.channel)
      && !isEmpty(this.referral.channel)
      && this.referral.createdAt > this.utm.createdAt
    );
  }

  get isFromFacebook() {
    return (
      (has(this.utm, 'utm_source') && this.utm.utm_source.includes('cripto'))
      || (has(this.utm, 'utm_medium') && this.utm.utm_medium.includes('cripto'))
    );
  }

  get tracking() {
    let referral;
    let utm;
    let affiliate;

    referral = this._referral;
    utm = this._utm;
    affiliate = this._affiliate;
    const ab = this.ab?.ab ? this.ab : null;

    if (this.referral.createdAt > this.utm.createdAt) return { ...Format.objectCamelCase(referral), ...Format.objectCamelCase(this.affiliate), ab };
    if (this.utm.createdAt > this.referral.createdAt) return { ...Format.objectCamelCase(utm), ...Format.objectCamelCase(this.affiliate), ab };
    if (this.affiliate.createdAt > 0) return { ...Format.objectCamelCase(this.affiliate), ab };
    return {
      ab,
    };
  }

  get v2TrackingReferral() {
    const referral = this?._referral;
    if (this.referral.createdAt > this.utm.createdAt) return { ...Format.objectCamelCase(referral) };
    return {};
  }

  get v2TrackingUtm() {
    const utm = this?._utm;
    if (this.utm.createdAt > this.referral.createdAt) return { ...Format.objectCamelCase(utm) };
    return {};
  }

  get v2TrackingAb() {
    const ab = this.ab?.ab ? this.ab : null;
    return ab;
  }

  get v2TrackingExperiment() {
    return this.experiment;
  }

  get v2TrackingAffiliate() {
    if (this.affiliate.createdAt > 0) return { ...Format.objectCamelCase(this.affiliate) };
    return null;
  }
}

const trackingStore = new TrackingStore();
export default trackingStore;
