import React, { useEffect } from 'react';
import AppLinearProgress from '@bit/together-price.core.components.app-linear-progress';
import useSWR from 'swr';

const urlAppConfigJSON =
  'https://tp-app-config.s3.eu-west-1.amazonaws.com/app.json';
const url500Page = 'https://app.togetherprice.com/500.html';
const production = process.env.REACT_APP_ENV;

const redirectTo500 = () => {
  window.location.href = url500Page;
};

type Info = {
  status: string;
};

const errorInfo = {
  status: 'ERROR',
};
const loadingInfo = {
  status: 'LOADING',
};

const fetchInfo = async (url: string): Promise<Info> => {
  if (!production) return { status: 'OK' };
  return fetch(url)
    .then((r) => r.json())
    .catch((e) => {
      console.error('Error loading app.json', e);
      return errorInfo;
    });
};

const MaintenancePage: React.FC = ({ children }) => {
  const { data } = useSWR<Info>(urlAppConfigJSON, fetchInfo, {
    fallbackData: loadingInfo,
    revalidateOnMount: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    suspense: false,
  });
  const { status } = data;
  useEffect(() => {
    if (status === 'DOWN') {
      redirectTo500();
    }
  }, [status]);
  if (status === 'LOADING') {
    return <AppLinearProgress />;
  }
  return <>{children}</>;
};

export default MaintenancePage;
