import { action, observable } from 'mobx';
import { isString } from 'lodash';
import Err from '../utils/error';
import Store from '../various/store';

class LoaderStore extends Store {
  @observable message;

  @observable visible;

  factory() {
    this.visible = false;
    this.message = '';
  }

  /**
   * Shows the global spinner with an optional message
   *
   * @param {string} message
   */
  @action show(message) {
    if (!isString(message)) return Err.type('LoaderStore', 'show');

    this.visible = true;
    this.message = message;
  }

  /**
   * Hides the global spinner
   */
  @action hide() {
    this.visible = false;
  }
}

const loaderStore = new LoaderStore();
export default loaderStore;
