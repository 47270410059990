import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { isFunction } from 'lodash';
import Column from '../layout/Column';
import Touchable from '../natives/Touchable';

@observer
class EmptyModal extends Component {
  onRequestClose = () => isFunction(this.props.onRequestClose) && this.props.onRequestClose()

  render() {
    if (!this.props.visible) return false;

    const { zIndex } = this.props;

    return (
      <Column style={`cnd100 jcc fp at0 al0 ${zIndex || 'zi30'}`}>
        <Touchable
          style="cnp100 ap o50 zin1"
          animated={{ backgroundColor: 'black', transform: 'scale(2)' }}
          onPress={this.onRequestClose}
        />
        {this.props.children}
      </Column>
    );
  }
}

export default EmptyModal;
