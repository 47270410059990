import React from 'react';
import ReactDOM from 'react-dom';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import './styles/nullifier.scss';
import './styles/index.scss';
import './tracking/facebook.ts';
import App from './App';

const setRealVh = () => {
  document.documentElement.style.setProperty(
    '--vh',
    `${window.innerHeight * 0.01}px`
  );
  document.documentElement.style.setProperty(
    '--app-height',
    `${window.innerHeight}px`
  );
};
window.addEventListener('resize', setRealVh);
document.documentElement.style.setProperty('--intensity', `${768 * 0.005}px`);
ReactDOM.render(<App />, document.getElementById('root'));

// Load ionic pwa elements https://capacitorjs.com/docs/web/pwa-elements
defineCustomElements(window);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register({
//   onUpdate: (registration) => registration.waiting.postMessage({ type: 'SKIP_WAITING' }),
// });
// serviceWorkerRegistration.unregister();
