import React, { PureComponent } from 'react';
import { TextInputMask } from 'tp-react-web-masked-text';
import { get, isBoolean, isEmpty, isFunction, isString } from 'lodash';
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  IbanElement,
} from 'react-stripe-elements';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/dark.css';
import Geosuggest from 'react-geosuggest';
import { observer } from 'mobx-react';
import momentStore from '../stores/moment';
import userStore from '../stores/user';
import t from '../stores/translate';
import Mask from '../various/masks';

const __TEST__ = process.env.REACT_APP_ENV === 'TEST';

@observer
class TextInput extends PureComponent {
  state = { advanced: '' };

  componentDidMount = () => {
    if (isString(this.props.stripe) && isString(this.props.id) && !__TEST__) {
      document.querySelector(`#${this.props.id} iframe`).style.width = '100%';
      document.querySelector(`#${this.props.id} iframe`).style.height = '40px';
    }
  };

  onChangeRef = (ref) => {
    this.props.onChangeRef(ref);
  };

  onChange = (e, raw) => {
    if (isBoolean(this.props.textarea) && this.props.textarea) {
      e.target.style.height = 'auto';
      e.target.style.height =
        e.target.scrollHeight > 80 ? `${e.target.scrollHeight}px` : '44px';
    }
    if (isFunction(this.props.onChange)) return this.props.onChange(e, raw);
  };

  render() {
    return this.element;
  }

  get cityProps() {
    if (this.props.type !== 'city') return {};

    return {
      ...this.coreProps,
      className: 'wp100',
      inputClassName: this.props.style,
      placeholder: this.placeholder,
      initialValue: this.props.value,
      onSuggestSelect: this.onChange,
    };
  }

  get coreProps() {
    return {
      className: this.props.style,
      placeholder: this.placeholder,
      onFocus: this.props.onFocus,
      onBlur: this.props.onBlur,
      disabled: this.props.disabled,
      style: this.props.inline,
    };
  }

  get dateProps() {
    if (this.props.type !== 'date') return {};

    return {
      ...this.coreProps,
      placeholder: this.placeholder,
      value: momentStore.e(this.props.value).valueOf(),
      onChange: this.onChange,
      options: {
        minDate: momentStore.e.isMoment(this.props.min)
          ? this.props.min.valueOf()
          : this.props.min,
        maxDate: momentStore.e.isMoment(this.props.max)
          ? this.props.max.valueOf()
          : this.props.max,
        dateFormat: 'j F Y',
        locale: t.flatpickrLocale,
        disableMobile: true,
        ...this.props.dateOptions,
      },
    };
  }

  get element() {
    switch (true) {
      case this.props.stripe === 'number' && !__TEST__:
        return <CardNumberElement {...this.stripeProps} />;
      case this.props.stripe === 'expiry' && !__TEST__:
        return <CardExpiryElement {...this.stripeProps} />;
      case this.props.stripe === 'cvc' && !__TEST__:
        return <CardCVCElement {...this.stripeProps} />;
      case this.props.stripe === 'iban' && !__TEST__:
        return <IbanElement {...this.stripeProps} />;
      case this.props.type === 'date':
        return <Flatpickr {...this.dateProps} />;
      case this.props.type === 'city':
        return <Geosuggest {...this.cityProps} />;
      case isString(this.props.mask):
        return <TextInputMask {...this.maskedProps} />;
      case isBoolean(this.props.textarea) && this.props.textarea:
        return (
          <textarea {...this.normalProps} autoFocus={this.props.autoFocus} />
        );
      default:
        return <input {...this.normalProps} />;
    }
  }

  get maskedProps() {
    if (!this.props.mask) return {};

    return {
      ...this.normalProps,
      kind: get(Mask, `${this.props.mask}.type`),
      options: get(Mask, `${this.props.mask}.options`),
      ref: this.onChangeRef,
    };
  }

  get normalProps() {
    return {
      ...this.coreProps,
      ...this.refProps,
      autoFocus: this.props.autoFocus,
      type: this.props.type,
      onChange: this.onChange,
      autoComplete: this.props.autoComplete,
      readOnly: this.props.readOnly,
      onClick: this.props.onClick,
      onSubmit: this.props.onSubmit,
      value: this.props.value,
      size: this.props.size,
      min: this.props.min,
      max: this.props.max,
      onKeyDown: this.props.onKeyDown,
      onKeyUp: this.props.onKeyUp,
      tabIndex: this.props.tabIndex,
      maxLength: this.props.maxLength,
    };
  }

  get placeholder() {
    return t.get(this.props.placeholder) || this.props.placeholder;
  }

  get refProps() {
    return isFunction(this.props.onChangeRef) ? { ref: this.onChangeRef } : {};
  }

  get stripeProps() {
    if (!this.props.stripe) return {};

    return {
      ...this.coreProps,
      placeholder: !isEmpty(this.props.value) ? this.props.value : undefined,
      style: {
        base: {
          lineHeight: '40px',
          fontFamily: '"Poppins"',
          fontWeight: '600',
          fontSize: '14px',
          color: '#323233',
          '::placeholder': {
            color: '#3232334d',
          },
          ...get(this.props.stripeOptions, 'style.base', {}),
        },
        invalid: {
          color: '#fb4c68',
          ...get(this.props.stripeOptions, 'style.invalid', {}),
        },
      },
      supportedCountries: ['SEPA'],
      placeholderCountry: userStore.user.marketCountry,
    };
  }

  get type() {
    switch (true) {
      case isString(this.props.type):
        return this.props.type;
      default:
        return 'text';
    }
  }
}

export default TextInput;
