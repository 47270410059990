import { action, observable } from 'mobx';
import { isBoolean, isEmpty, isFunction, isObject, isString } from 'lodash';
import { ModalStatus } from '../various/enums';
import userStore from './user';
import Store from '../various/store';
import recorder from '../utils/recorder';

class ModalStore extends Store {
  @observable callback;

  @observable dialog;

  @observable form;

  @observable params;

  @observable standard;

  @observable status;

  factory() {
    this.status = {
      // Special
      standard: ModalStatus.STANDARD.IDLE,
      dialog: ModalStatus.DIALOG.IDLE,
      form: ModalStatus.FORM.IDLE,
      // Normal
      channel: ModalStatus.CHANNEL.IDLE,
      relationship: ModalStatus.RELATIONSHIP.IDLE,
      payment: ModalStatus.PAYMENT.IDLE,
      avatar: ModalStatus.AVATAR.IDLE,
      customPost: ModalStatus.CUSTOMPOST.IDLE,
      adminContacts: ModalStatus.ADMINCONTACTS.IDLE,
      howWallet: ModalStatus.HOWWALLET.IDLE,
      howPost: ModalStatus.HOWPOST.IDLE,
      sharingRules: ModalStatus.SHARINGRULES.IDLE,
      chatPost: ModalStatus.CHATPOST.IDLE,
      sharePost: ModalStatus.SHAREPOST.IDLE,
      sharePosts: ModalStatus.SHAREPOSTS.IDLE,
      editPost: ModalStatus.EDITPOST.IDLE,
      renews: ModalStatus.RENEWS.IDLE,
      withdraw: ModalStatus.WITHDRAW.IDLE,
      verifyPhone: ModalStatus.VERIFYPHONE.IDLE,
      verifyIban: ModalStatus.VERIFYIBAN.IDLE,
      password: ModalStatus.PASSWORD.IDLE,
      refund: ModalStatus.REFUND.IDLE,
      profile: ModalStatus.PROFILE.IDLE,
      createInfo: ModalStatus.CREATEINFO.IDLE,
      contact: ModalStatus.CONTACT.IDLE,
      postMore: ModalStatus.POSTMORE.IDLE,
      uploadCover: ModalStatus.UPLOADCOVER.IDLE,
      verifyEmail: ModalStatus.VERIFYEMAIL.IDLE,
      postRequests: ModalStatus.POSTREQUESTS.IDLE,
      uploadDocument: ModalStatus.UPLOADDOCUMENT.IDLE,
      version: ModalStatus.VERSION.IDLE,
      creditCard: ModalStatus.CREDITCARD.IDLE,
      lemonway: ModalStatus.LEMONWAY.IDLE,
      tpassword: ModalStatus.TPASSWORD.IDLE,
      pushTutorial: ModalStatus.PUSHTUTORIAL.IDLE,
      christmasInvite: ModalStatus.CHRISTMASINVITE.IDLE,
      newYearInvite: ModalStatus.NEWYEARINVITE.IDLE,
      feedbackModal: ModalStatus.FEEDBACKMODAL.IDLE,
      postSignUpModal: ModalStatus.POSTSIGNUPMODAL.IDLE,
      forgotPasswordModal: ModalStatus.FORGOTPASSWORDMODAL.IDLE,
      referralJoinerWelcome: ModalStatus.REFERRALJOINERWELCOME.IDLE,
      referralShareModal: ModalStatus.FORGOTPASSWORDMODAL.IDLE,
      deleteAccountValidationModal:
        ModalStatus.DELETEACCOUNTVALIDATIONMODAL.IDLE,
    };
    this.standard = {
      title: '',
      description: '',
      image: '',
      button: '',
      inject: { title: false, description: false },
      align: '',
      link: '',
      onPress: () => {},
      children: null,
      isButtonDisabled: false,
      width: null,
      zIndex: null,
    };
    this.dialog = {
      radix: '',
      image: '',
      onPress: () => {},
      inject: { title: false, description: false },
    };
    this.form = {
      radix: '',
      entries: [],
      forms: [],
      validators: [],
      inject: { title: false, description: false },
      closable: true,
      image: '',
      onPress: () => {},
      children: null,
    };
    this.params = {};
    this.callback = () => {};
  }

  @action setCallback = (callback) => {
    this.callback = callback;
  };

  /**
   * Sets the status and callback of the target modal
   *
   * @param {string} target
   * @param {string} status
   * @param {function} callback
   * @param {string} funnel
   * @param {object} params
   */
  @action set(target, status, callback, funnel = '', params = {}) {
    // Set the target modal status as specified in the parameter
    this.status[target] =
      ModalStatus[target.toUpperCase()][status.toUpperCase()];
    // If the callback is a valid function the set it inside the store
    this.callback = isFunction(callback) ? callback : () => {};
    // Update the funnel status
    if (isString(funnel) && !isEmpty(funnel)) userStore.updateFunnel(funnel);
    // If params is defined then set it
    this.params = isObject(params) ? params : {};
  }

  /**
   * Hides the target modal
   *
   * @param {string} target
   * @param {string=} funnel
   */
  @action idle(target, funnel) {
    // Set the target modal status as idle
    this.status[target] = ModalStatus[target.toUpperCase()].IDLE;

    // Update the funnel status
    if (isString(funnel) && !isEmpty(funnel)) userStore.updateFunnel(funnel);

    // Clear callback variable
    this.callback = () => {};
  }

  /**
   * Shows the target modal
   *
   * @param {string} target
   * @param {string=} funnel
   * @param {object=} params
   */
  @action pending(target, funnel, params) {
    // Set the target modal status as pending
    this.status[target] = ModalStatus[target.toUpperCase()].PENDING;

    // Update the funnel status
    if (isString(funnel) && !isEmpty(funnel)) userStore.updateFunnel(funnel);

    // Notify hotjar that this modal has been opened
    recorder.modal(target);

    // Clear callback variable
    this.callback = () => {};

    // Set the params if defined
    this.params = isObject(params) ? params : {};
  }

  /**
   * Sets the target modal status to ERROR
   *
   * @param {string} target
   * @param {string} funnel
   */
  @action error(target, funnel) {
    // Set the target modal status as error
    this.status[target] = ModalStatus[target.toUpperCase()].ERROR;

    // Update the funnel status
    if (isString(funnel) && !isEmpty(funnel)) userStore.updateFunnel(funnel);

    // Clear callback variable
    this.callback = () => {};

    return false;
  }

  /**
   * Sets the target modal status to SUCCESS
   *
   * @param {string} target
   * @param {string=} funnel
   */
  @action success(target, funnel) {
    // Set the target modal status as success
    this.status[target] = ModalStatus[target.toUpperCase()].SUCCESS;

    // Execute the modal callback only if it is defined
    if (isFunction(this.callback)) this.callback();

    // Update the funnel status
    if (isString(funnel) && !isEmpty(funnel)) userStore.updateFunnel(funnel);

    // Clear callback variable
    this.callback = () => {};

    return true;
  }

  /**
   * Initializes and shows a StandardModal
   *
   * @param {string} radix
   * @param {string} title
   * @param {string} description
   * @param {object} image
   * @param {string} button
   * @param {function} onPress
   * @param {function=} callback
   * @param {array=} inject
   * @param {string=} align
   * @param {string=} link
   * @param {import('react').ReactNode=} children
   * @param {boolean=} isButtonDisabled
   * @param {(number | string)=} width
   * @param {string=} buttonColor
   * @param {boolean=} closable
   * @param {number=} zIndex
   */
  @action setStandard(
    radix,
    title,
    description,
    image,
    button,
    onPress,
    callback,
    inject,
    align,
    link,
    children,
    isButtonDisabled,
    width,
    buttonColor,
    closable,
    zIndex
  ) {
    this.standard = {
      title: `${radix}.${title || 'title'}`,
      description: `${radix}.${description || 'description'}`,
      image,
      button: `${radix}.${button || 'button'}`,
      inject: { title: false, description: false, ...inject },
      align,
      onPress: isFunction(onPress) ? onPress : () => this.idle('standard'),
      link,
      children,
      isButtonDisabled: isBoolean(isButtonDisabled) ? isButtonDisabled : false,
      width,
      buttonColor,
      closable,
      zIndex,
    };
    this.callback = isFunction(callback) ? callback : () => {};
    this.status.standard = ModalStatus.STANDARD.PENDING;
  }

  /**
   * Initializes and shows a DialogModal
   *
   * @param {string} radix
   * @param {object} image
   * @param {function} onPress
   * @param {function} callback
   * @param {object} inject
   * @param {boolean} isClosable
   */
  @action setDialog(
    radix,
    image,
    onPress,
    callback = console.log,
    inject = {},
    closable = true
  ) {
    this.dialog = {
      radix,
      image,
      onPress,
      inject: { title: false, description: false, ...inject },
      closable,
    };
    this.callback = isFunction(callback) ? callback : () => {};
    this.status.dialog = ModalStatus.DIALOG.PENDING;
  }

  @action setForm(
    radix,
    entries,
    forms,
    validators,
    store,
    inject,
    onPress,
    callback,
    image,
    children,
    closable
  ) {
    this.form = {
      radix,
      entries,
      forms,
      validators,
      store,
      inject: { title: false, description: false, ...inject },
      onPress,
      closable: isBoolean(closable) ? closable : true,
      image,
      children,
    };
    this.callback = isFunction(callback) ? callback : () => {};
    this.status.form = ModalStatus.FORM.PENDING;
  }

  @action idleAll() {
    Object.keys(this.status).forEach((key) => {
      this.status[key] = 0;
    });
  }

  isIdle(target) {
    return this.status[target] === ModalStatus[target.toUpperCase()].IDLE;
  }

  isPending(target) {
    return this.status[target] === ModalStatus[target.toUpperCase()].PENDING;
  }

  get isEveryIdle() {
    return Object.keys(this.status).every((target) => !this.isPending(target));
  }
}

const modalStore = new ModalStore();
export default modalStore;
