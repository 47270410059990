import { action, observable } from 'mobx';
import chatStoreWS from './chat-ws';
import Store from '../various/store';
import postStore from './post';
import { ChatStatus } from '../various/enums';

class PlaceholderChat extends Store {
  @observable message = ''

  @observable messengers = []

  @observable paging = {
    current: 0,
    max: 1,
  }

  @observable pinned = {}

  @observable private = {}

  @observable ref = {}

  @observable resolvers = {
    getUnreadMessages: () => {},
    sendMessage: () => {},
    pinMessage: () => {},
    unpinMessage: () => {},
  }

  @observable scrollable = true

  @observable socket = {}

  @observable status = ChatStatus.CLOSE

  @observable writing = {
    id: -1,
    name: '',
  }

  async init() {}

  onOpen() {}

  onError() {}

  async onMessage() {}

  onClose() {}

  async join() {}

  send() {}

  async sendMessage() {}

  async getUnreadMessages() {}

  getMessengers() {}

  addMessage() {}

  addMessages() {}

  setMessengers() {}

  setPinnedMessage() {}

  unpinAllMessages() {}

  updateLastSeenMessage() {}

  setUserDisconnected() {}

  setMessengerIsWriting() {}

  async close() {}

  async pinMessage() {}

  async unpinMessage() {}

  setLastSeenMessageId() {}

  onWriting() {}

  getPinnedMessage() {}

  getProfile() {}

  isUserOnline() {}

  messageSeenBy() {}

  getUserLastLogin() {}

  reset() {}

  get _messengers() {
    return this.messengers;
  }

  get _private() {
    return this.private;
  }

  get _privateKeys() {
    return Object.keys(this.private).sort((a, b) => parseInt(a) - parseInt(b));
  }

  get height() {
    return `calc(100vh - 134px)`;
  }

  get isAuthorized() {
    return postStore.isUserOwned || postStore.isUserJoinedOrSuspended;
  }
}

class Chat extends Store {
  @observable last

  @observable message

  @observable store

  factory() {
    this.message = '';
    this.store = new PlaceholderChat();
  }

  @action setStore() {
    this.store = chatStoreWS;
  }

  @action reset() {
    this.factory();
    chatStoreWS.reset();
  }
}

const chat = new Chat();
export default chat;
