import has from 'lodash/has';
import camelCase from 'lodash/camelCase';
import startCase from 'lodash/startCase';

const __COMMENTS__ = process.env.REACT_APP_COMMENTS === 'true';

const hasError = (data) => {
  const error = has(data, '__e__');
  if (__COMMENTS__ && error) console.log('Error:', data['__e__']); // eslint-disable-line
  return error;
};

const hasErrorCode = (data, code) => hasError(data) && data.code === code;

const createError = (data, code = null) => ({ __e__: data, code });

const RATE_LIMITER_ERROR = 429;
export const createRateLimiterError = (data) =>
  createError(data, RATE_LIMITER_ERROR);
export const hasRateLimiterError = (data) =>
  hasErrorCode(data, RATE_LIMITER_ERROR);

const createTypeError = (className, method) => {
  const error = createError(
    `${startCase(className)} -> ${camelCase(method)} -> Type Error`
  );
  if (__COMMENTS__ && error) console.log('Error:', error['__e__']); // eslint-disable-line
  return error;
};

const javaError = /java.lang.*: (\w*)/;
const illegalStateException =
  /Server.java.lang.IllegalStateException: Can't deserialize exception : /;
const requestId = /; request-id: .*/;
const errorField = '__e__';
const getErrorMessage = (error) => {
  if (errorField in error) return getErrorMessage(error[errorField]);
  if ('message' in error) {
    const { message } = error;
    if (illegalStateException.test(message)) {
      return getErrorMessage(
        JSON.parse(message.replace(illegalStateException, ''))
      );
    }
    if (javaError.test(message)) {
      return String(message).match(javaError)[1];
    }

    return message.replace(requestId, '').trim();
  }
  return null;
};

const getErrorMessageWithoutServerPrefix = (error) => {
  if (errorField in error) {
    return getErrorMessageWithoutServerPrefix(error[errorField]);
  }
  if ('message' in error) {
    const { message } = error;
    if (illegalStateException.test(message)) {
      return getErrorMessageWithoutServerPrefix(
        JSON.parse(message.replace(illegalStateException, ''))
      );
    }
    const result = message.replace(requestId, '').trim();
    return result.replace('Server.', '');
  }
  return null;
};

export const getStripeErrorCode = (error) => {
  if (!hasError(error)) return null;
  const message = getErrorMessage(error);
  const [match] = [...message.matchAll(/code: (?<code>[a-z_]*)$/gi)];
  return match?.groups?.code;
};

const Errors = {
  check: hasError,
  create: createError,
  type: createTypeError,
};

export default Errors;
export {
  hasError,
  createError,
  createTypeError,
  getErrorMessage,
  getErrorMessageWithoutServerPrefix,
};
