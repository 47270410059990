import { action, observable } from 'mobx';
import { isEmpty, isString } from 'lodash';
import Chance from 'chance';
import Store from '../various/store';
import { WithdrawStatus } from '../various/enums';
import authStore from './auth';
import Err from '../utils/error';
import userStore from './user';
import Financial from '../utils/financial';
import walletStore from './wallet';

class WithdrawStore extends Store {
  @observable data

  @observable isChangingIban = false;

  @observable method

  @observable status

  @observable uuid

  @observable financialStatus

  @observable feeDetails

  @observable availableDocuments

  @observable identityDocuments

  factory() {
    this.data = {
      bankName: '',
      bankAddress: '',
      country: '',
      currency: '',
      last4: '',
      iban: '',
      status: '',
      stripesBankId: '',
      ownerName: '',
      ownerSurname: '',
      accountNumber: '',
      routingNumber: '',
      address: '',
      city: '',
      postalCode: '',
      ssn: '',
      state: '',
    };
    this.method = 'iban';
    this.status = WithdrawStatus.PENDING;
    this.uuid = {
      payout: new Chance().guid(),
      upload: new Chance().guid(),
      bankAccount: new Chance().guid(),
    };
    this.financialStatus = {
      providers: [],
      migrationsStatus: 'COMPLETED',
      isAdditionalDocumentUpdated: false,
      isAddressUpdated: false,
      isDocumentUpdated: false,
      status: 'unverified',
      details: '',
      detailsCode: '',
      detailsDocument: '',
      detailsCodeDocument: '',
      detailsAdditional: '',
      detailsCodeAdditional: '',
    };
    this.isChangingIban = false;
    this.feeDetails = {
      feeThreshold: {},
      feeAmount: {},
      feeEnabled: false,
    };
    this.availableDocuments = {
      IT: [
        'PASSPORT',
        'DRIVING_LICENSE',
        'IDENTITY_CARD',
        'DIGITAL_IDENTITY_CARD',
        'WEAPON_LICENSE',
        'BOAT_LICENSE',
        'PENSION_BOOK',
      ],
      US: [
        'PASSPORT',
        'DRIVING_LICENSE',
        'IDENTITY_CARD',
        'STATE_ISSUED_CARD',
        'RESIDENT_PERMIT_US',
        'BORDER_CROSSING_CARD',
        'CHILD_CARD',
        'NYC_CARD',
        'US_VISA_CARD',
        'BIRTH_CERTIFICATE',
      ],
      ES: [
        'PASSPORT',
        'DRIVING_LICENSE',
        'IDENTITY_CARD',
        'RESIDENT_PERMIT',
        'CITIZEN_CARD',
        'ELECTORAL_ID',
        'VALIDATE_UK',
      ],
      GB: [
        'PASSPORT',
        'DRIVING_LICENSE',
        'IDENTITY_CARD',
        'RESIDENT_PERMIT',
      ],
    };

    this.identityDocuments = ['IDENTITY_CARD', 'DIGITAL_IDENTITY_CARD'];
  }

  /**
   * Gets the saved withdrawal informations of the logged user
   *
   * @returns {boolean}
   */
  async get() {
    this.isChangingIban = false;
    this.set('status', WithdrawStatus.PENDING);
    const [withdraw, financialStatus] = await Promise.all([
      Financial.get('getBankAccount', authStore.token),
      Financial.get('financialStatus', authStore.token),
    ]);
    if (Err.check(withdraw) || Err.check(financialStatus)) return this.set('status', WithdrawStatus.ERROR);

    this.set('data', { ...this.data, ...withdraw, changeable: !!withdraw?.last4 });
    this.set('data.iban', withdraw.last4 ? `XXXXXXXXXXXXXXXXXXXXXXX${withdraw.last4}` : '');
    this.set('data.ownerName', withdraw.ownerName || userStore.user.name);
    this.set('data.ownerSurname', withdraw.ownerSurname || userStore.user.surname);

    this.set('financialStatus', {
      ...financialStatus,
      isIbanUpdated: !!withdraw.last4,
    });

    // remove last dot for translations
    this.set('financialStatus.details', this.financialStatus?.details?.replace(/[,.]/g, ''));
    this.set('financialStatus.detailsDocument', this.financialStatus?.detailsDocument?.replace(/[,.]/g, ''));
    this.set('financialStatus.detailsAdditional', this.financialStatus?.detailsAdditional?.replace(/[,.]/g, ''));

    // Update the status
    this.set('status', this.isIbanVerified ? WithdrawStatus.SUCCESS : WithdrawStatus.SUCCESS_EMPTY);

    this.set('data.address', userStore.user.address);
    this.set('data.postalCode', userStore.user.postalCode);
    this.set('data.city', userStore.user.city);
    this.set('data.state', userStore.user.state);
    this.generateUUID('payout');

    // Get user fee details for withdraw
    const feeDetails = await Financial.get('/getPayoutFeeDetails', authStore.token);
    this.set('feeDetails', feeDetails);

    return true;
  }

  async requestCountryClosedWithdraw() {
    return !Err.check(await Financial.post('/latam/startPayout', null, authStore.token, {
      'Content-Type': 'application/json',
    }));
  }

  @action generateUUID(target) {
    const chance = new Chance();
    const uuid = chance.guid();
    this.set(`uuid.${target}`, uuid);
  }

  get isDocumentRejected() {
    return Boolean(walletStore?.balance?.available?.value
    && walletStore?.balance?.available?.value > 0
    && this.financialStatus.status === 'unverified'
    && (this.financialStatus.isDocumentUpdated || this.financialStatus.isAdditionalDocumentUpdated)
    && ((this.financialStatus?.details && this.financialStatus?.details !== '')
        || (this.financialStatus?.detailsDocument && this.financialStatus?.detailsDocument !== '')
        || (this.financialStatus?.detailsAdditional && this.financialStatus?.detailsAdditional !== '')));
  }

  get isIbanVerified() {
    return isString(this.data.last4) && !isEmpty(this.data.last4);
  }
}

const withdrawStore = new WithdrawStore();
export default withdrawStore;
