import { action, observable } from 'mobx';
import t from './translate';
import Err from '../utils/error';
import toastStore from './toast';
import Store from '../various/store';
import Financial from '../utils/financial';
import authStore from './auth';
import { injectScript } from '../utils/script';
import { loadStripe } from '@stripe/stripe-js';

class StripeStore extends Store {
  @observable elements;

  @observable stripe;

  @observable stripePromise;

  @observable entity;

  @observable info;

  @observable type;

  factory() {
    this.entity = null;
    this.type = 'credit_card';
  }

  init() {
    injectScript({ src: 'https://js.stripe.com/v3/' });
  }

  @action
  async setup(secret) {
    let result;
    
    const {error: submitError} = await this.elements.submit();
    if (submitError) {
      const message =
        t.get(`Error.Stripe.${submitError.code}`) || submitError.message;

      toastStore.error(message, null, null, null);
      return Err.create(submitError);
    }

    result = await this.stripe.createPaymentMethod({
      elements: this.elements
    });

    if (result.error) {
      const message =
        t.get(`Error.Stripe.${result.error.code}`) || result.error.message;

      toastStore.error(message, null, null, null);
      return Err.create(result.error);
    }

    return result;
  }

  /**
   * Handle card payment via secret
   *
   * @param secret
   * @returns {Promise<*>}
   */
  async handleAction(secret) {
    const intent = await this.stripe.handleNextAction({clientSecret: secret});
    if (intent.error) {
      toastStore.error(`Stripe.${intent.error.code}`);
      return Err.create(intent.error);
    }

    return intent.paymentIntent;
  }

  async getStripeInfo() {
    const info = await Financial.get('/getStripeInfo', authStore.token);
    if (Err.check(info)) return false;
    this.set("stripePromise", loadStripe(info.apiKey));
    this.set('info', info);
    return true;
  }

  get isBacs() {
    return ['GBP', 'USD'].some(
      (currency) => currency === this.info.marketCurrency
    );
  }
}

const stripeStore = new StripeStore();
export default stripeStore;
