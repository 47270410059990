import { action, configure, observable } from 'mobx';
import { get, isEmpty } from 'lodash';
import { Device } from '@capacitor/device';
import { Preferences } from '@capacitor/preferences';
import { Capacitor } from '@capacitor/core';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import SecureStorage from '../utils/storage';
import Err from '../utils/error';
import authStore from './auth';
import userStore from './user';
import networkStore from './network';
import sharingStore from './sharing';
import contactStore from './contact';
import postStore from './post';
import navigatorStore from './navigator';
import loaderStore from './loader';
import bottomStore from './bottom';
import chat from './chat';
import createStore from './create';
import headerStore from './header';
import hypeStore from './hype';
import modalStore from './modal';
import momentStore from './moment';
import notificationStore from './notification';
import profileStore from './profile';
import refundStore from './refund';
import searchStore from './search';
import settingStore from './setting';
import stripeStore from './stripe';
import supportStore from './support';
import toastStore from './toast';
import topTabStore from './toptab';
import t from './translate';
import trustStore from './trust';
import walletStore from './wallet';
import trackingStore from './tracking';
import Store from '../various/store';
import gift from '../assets/network-promo-modal/gift.png';
import withdrawStore from './withdraw';
import cardStore from './card';
import cryptoStore from './crypto';
import tpasswordStore from './tpassword';
import Rest from '../utils/rest';
import { AuthStatus, Breakpoint } from '../various/enums';
import OneSignal from '../utils/onesignal';
import authClient from '../utils/auth-client';
import Price from '../components/Price';
import Ums from '../utils/ums';

// Important settings
configure({
  computedRequiresReaction: true,
  enforceActions: 'observed',
});

class AppStore extends Store {
  @observable deviceInfo = {};

  @observable deviceId = '';

  @observable dropdown = {};

  @observable params = {};

  @observable screen = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  @observable version = '';

  @observable onesignalNotification = null;

  async init() {
    await Device.getId().then((deviceId) =>
      this.set('deviceId', deviceId.uuid)
    );
    await Device.getInfo().then((info) => this.set('deviceInfo', info));
  }

  async isDeviceRegistered() {
    const response = Ums.get(
      `/device/isDeviceRegistered?uuid=${this.deviceId}`,
      authStore.token
    );
    if (Err.check(response)) return false;
    return response;
  }

  // check if user can register device
  async checkPinAuth() {
    const isApp = Capacitor.isNativePlatform();
    if (!isApp) return false;
    const isRegistered = await this.isDeviceRegistered();
    await Preferences.set({
      key: 'isDeviceRegistered',
      value: isRegistered ? 'true' : 'false',
    });
    if (!isRegistered) {
      const { value } = await Preferences.get({
        key: 'UserPinAuths',
      });
      const pinAuths = JSON.parse(value) || {};
      pinAuths[userStore.user.id] = (pinAuths?.[userStore.user.id] || 0) + 1;
      if (pinAuths[userStore.user.id] <= 3) {
        Preferences.set({
          key: 'UserPinAuths',
          value: JSON.stringify(pinAuths),
        });
        navigatorStore.stack('/pin-auth');
      }
    }
  }

  /**
   * Deletes everything, both temporary and saved into the storage
   *
   * @returns {boolean}
   */
  async clearStorage() {
    // Remove User and Auth data from storage
    await SecureStorage.remove('UserStore');
    await SecureStoragePlugin.remove({
      key: 'AuthStore'
    });

    // Reset all stores variables to default
    authClient.signOut();
    authStore.reset();
    bottomStore.reset();
    cardStore.reset();
    chat.reset();
    contactStore.reset();
    createStore.reset();
    cryptoStore.reset();
    headerStore.reset();
    hypeStore.reset();
    loaderStore.reset();
    modalStore.reset();
    momentStore.reset();
    networkStore.reset();
    navigatorStore.reset();
    notificationStore.reset();
    postStore.reset();
    profileStore.reset();
    refundStore.reset();
    searchStore.reset();
    settingStore.reset();
    sharingStore.reset();
    stripeStore.reset();
    supportStore.reset();
    toastStore.reset();
    topTabStore.reset();
    t.reset();
    tpasswordStore.reset();
    trackingStore.reset();
    trustStore.reset();
    userStore.reset();
    walletStore.reset();
    withdrawStore.reset();
    this.reset();

    // swrCache.clear();

    // Execute the init methods for various checks and to avoid stupid loops
    await authStore.init();
    await userStore.init();

    await Device.getInfo().then((info) => this.set('deviceInfo', info));

    // Set the stack as root to handle various conditions
    navigatorStore.root('boot');
  }

  clearLinkParams() {
    this.set('params.post', undefined);
  }

  /**
   * This function is invoked after a successful authentication
   *
   * @param {object} data
   * @param {boolean} isSignup
   */
  async onAuthSuccess(data, isSignup = false) {
    authStore.set('status', AuthStatus.PENDING)
    userStore.setPending();

    // Save the access token inside the auth store
    authStore.set('token', data.token);

    await SecureStorage.set('__LoggedInAtLeastOnce', true);

    // Save the auth token inside the secure storage
    await authStore.toStorage();

    // Use the auth store init function to execute various tasks
    await authStore.init();

    // Save the user store inside the secure storage
    await userStore.toStorage();

    // Use the user store init function to execute various tasks
    await userStore.init();

    // Check if the user has the card saved on lemonway (active joiner but no card saved on stripe)
    await userStore.checkIsJoinerAndHasNotCard();
    await Promise.all([
      userStore.getPromoTokenToUse(),
      userStore.getFree4Ever(),
    ]);

    // check if has tracking promo
    if (trackingStore.promo) {
      await trackingStore.trackUserPromo(trackingStore.promo);
      await userStore.getFree4Ever();
    }

    // Clear the tracking store
    if (!isSignup) await trackingStore.clearStorage();

    // Trigger the navigator root to update the stack
    navigatorStore.root('auth');

    // Handle query params
    this.handleParams();

    // Disable the spinner
    loaderStore.hide();
  }

  async openPromoModal() {
    for (let i = 0; i < 10; i++) {
      await userStore.getPromoTokenToUse();
      if (userStore.user) break;
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
    const { promoToUse } = userStore.user;
    if (promoToUse.promoTag === 'viral_loop') {
      modalStore.setStandard(
        'PromoModal',
        'title',
        'description',
        gift,
        'button',
        null,
        null,
        {
          description: [Price.toString(promoToUse.amount)],
        }
      );
    }
  }

  /**
   * Fetches the number version from the server
   *
   * @returns {Promise<number>}
   */
  @action
  async getVersion() {
    const response = await Rest.get(
      `${window.location.origin}/version.json?t=${Date.now()}`
    );
    if (Err.check(response)) return false;
    this.version = parseFloat(response.version);
    return Number(response.version);
  }

  /**
   * Handle the query params obtained from the Spy component
   */
  handleParams() {
    if (!isEmpty(this.params?.sharingRequestId))
      navigatorStore.stack('/feed/requests', this.params);
    if (get(this.params, 'utm.utm_term') === 'freeforever')
      navigatorStore.stack('/free4ever');
  }

  async showNotificationPrompt() {
    if (await OneSignal.isConsentTriggerable()) {
      modalStore.pending('pushTutorial');
      await OneSignal.showNativePrompt();
    }
    return null;
  }

  /**
   * Reset the store
   */
  @action reset() {
    this.clear = false;
    this.params = {};
    this.dropdown = {};
  }

  get desktop() {
    return this.screen.width >= Breakpoint.DESKTOP;
  }

  get isDev() {
    return process.env.REACT_APP_ENV === 'DEVELOPMENT';
  }

  get isProd() {
    return process.env.REACT_APP_ENV === 'PRODUCTION';
  }

  get mobile() {
    return this.screen.width >= 0 && this.screen.width < Breakpoint.DESKTOP;
  }

  get tablet() {
    return (
      this.screen.width >= Breakpoint.TABLET &&
      this.screen.width < Breakpoint.DESKTOP
    );
  }
}

const appStore = new AppStore();
export default appStore;
