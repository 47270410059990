import React from 'react';
import { App, AppInfo } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Device, DeviceInfo } from '@capacitor/device';
import useSWR from 'swr';
import { fetchUms } from '../modules/commons-v2/fetch';

const useAppInfo = (): AppInfo => useSWR('capacitor://App.getInfo', async () => {
  if (Capacitor.getPlatform() === 'web') {
    return {
      id: '',
      name: '',
      version: '',
      build: '99999999',
    };
  }
  return App.getInfo();
}, { suspense: true }).data;

const useDeviceInfo = (): DeviceInfo => useSWR('capacitor://Device.getInfo', Device.getInfo, { suspense: true }).data;

const useSupportedVersion = (): number => {
  const {
    platform,
  } = useDeviceInfo();
  const { data } = useSWR('getPublicConfigurations', fetchUms, {
    suspense: true,
  });
  return Number(data[`frontEnd.public.supportedVersion.${platform}`] || 0);
};

type OnlyIfSupportedVersionProps = React.PropsWithChildren<{
  fallback?: React.ReactNode,
}>;
const OnlyIfSupportedVersion: React.FC<OnlyIfSupportedVersionProps> = ({
  fallback = <></>,
  children,
}) => {
  const {
    build,
  } = useAppInfo();
  const supportedVersion = useSupportedVersion();
  const isSupported = supportedVersion <= Number(build);
  if (!isSupported) return <>{fallback}</>;
  return <>{children}</>;
};

export default OnlyIfSupportedVersion;
