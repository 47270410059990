import React from 'react';

class Div extends React.Component {
  render() {
    const {
      id,
      style,
      animated,
      onPress,
      children,
      setRef,
      innerStyle,
      ...others
    } = this.props;
    return (
      <div ref={setRef} id={id} className={`${style} tp-old dario-div`} style={animated} onClick={onPress} {...others}>
        {children}
      </div>
    );
  }
}

export default Div;
