import Store from '../various/store'
import modalStore from './modal'
import hype from '../assets/hype/hype.png'

class HypeStore extends Store {
  code
  link

  factory() {
    this.link = 'https://www.hype.it/landing-a-new?utm_source=network&utm_medium=togetherprice'
    this.code = 'TOGETHERPRICE'
  }

  /**
   * Opens the hype link in a new tab
   */
  open = () => {
    modalStore.setStandard(
      'HypeModal',
      'title',
      'description',
      hype,
      'button',
      () => {
        window.open(this.link)
        modalStore.success('standard')
      },
      () => {},
      { description: [this.code] }
    )
  }
}

const hypeStore = new HypeStore()
export default hypeStore
