import React, { PureComponent } from 'react'

class Column extends PureComponent {
  render() {
    const { id, animated, style, children } = this.props
    return (
      <article id={id} style={animated} className={style}>
        {children}
      </article>
    )
  }
}

export default Column
