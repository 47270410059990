import React from 'react';
import _ from 'lodash';
import Span from '../natives/Span';
import Row from '../layout/Row';
import Image from '../natives/Image';
import Switch from '../components/Switch';
import { observer } from 'mobx-react';
import FormItem from './FormItem';

const FormSwitch = observer(({ color, ...props }) => {
  const {
    store,
    title,
    entry,
    useNative,
    disabled,
    style,
    inject,
    link,
    singular,
    callback,
    placeholder,
    image,
  } = props;
  const onPress = () => {
    let value;
    // Get the current value of the store entry we are targeting
    value = _.get(store, entry);
    // Since we have to work with 0 and 1 and javascript does not understand them very well,
    // we will just reverse them manually
    if (_.isNumber(value)) value = value === 0 ? 1 : value === 1 ? 0 : value;
    else if (_.isBoolean(value)) value = !value;
    // The singular prop means that there can only be one active checkbox at a time,
    // it will cycle through the store entry object and set all the entries to 0 (false)
    if (_.isString(singular)) {
      Object.keys(_.get(store, singular)).forEach((key) => {
        store.set(`${singular}.${key}`, _.isNumber(value) ? 0 : false);
      });
    }

    // Set the new value to the store entry target
    store.set(entry, value);

    if (_.isFunction(callback)) callback(value);
  };

  // If the image prop is defined then look for it inside the specified position
  const imageURI = image?.startsWith('http')
    ? image
    : image
    ? _.get(store, image)
    : false;
  // Get the value with the lodash get function
  const value = _.get(store, entry);
  return (
    <FormItem {...props} border={false}>
      <Row
        disabled={disabled}
        style={`pv3 jcsb ${style}`}
        useNative={useNative}
      >
        <Row style='wa ff pr3'>
          {image ? <Image uri={imageURI} style='brp100 mr3 cn40' /> : null}
          <Span
            id={placeholder || title}
            inject={inject}
            value={entry}
            link={link}
            style={`ts14 ${disabled ? 'normal-gray' : color || 'black'}`}
          />
        </Row>
        <Switch disabled={disabled} value={value} onPress={onPress} />
      </Row>
    </FormItem>
  );
});

export default FormSwitch;
