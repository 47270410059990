import { action, observable } from "mobx";
import { camelCase, get, has, isEmpty, isNil, isString, merge } from "lodash";
import { Money } from "@bit/together-price.core.monetary";
import { mutate } from "swr";
import SecureStorage from "../utils/storage";
import Err from "../utils/error";
import { PromoTag, UserStatus } from "../various/enums";
import Server from "../utils/server";
import authStore from "./auth";
import {
  ReferralModel,
  RenewsModel,
  SavingsModel,
  UserModel,
  UserRiskManagementModel,
} from "../various/models";
import t from "./translate";
import Store from "../various/store";
import Retention from "../utils/retention";
import Financial from "../utils/financial";
import modalStore from "./modal";
import card from "../assets/credit-card-modal/card.svg";
import Ums from "../utils/ums";
import notificationStore from "./notification";
import AutoPromoModal from "../modals/AutoPromoModal";
import postStore from "./post";
import cardStore from "./card";
import momentStore from "./moment";
import OneSignal from "../utils/onesignal";
import navigatorStore from "./navigator";
import stripeStore from "./stripe";
import Network from "../utils/network";
import trackingStore from "./tracking";
import recorder from "../utils/recorder";
import { getAppUrl } from "../utils/app-url";
import getTokenExpirationTime from "../functions/getTokenExpirationTime";
import Notification from "../utils/notification";
import toastStore from "./toast";

class UserStore extends Store {
  @observable abtests;

  @observable canActivateF4E;

  @observable fromOnboarding;

  @observable referral;

  @observable renews;

  @observable savings;

  @observable status;

  @observable user;

  @observable restoreIdFreshdesk;

  @observable userRisk;

  @observable configurations;

  @observable supportChatEnabled;

  @observable publicConfigurations;

  @observable canActivateFreeForYou;

  @observable f4uRedeemCount;

  @observable f4uAmount;

  @observable f4eRedeemCount;

  @observable closedCountryInfo;

  @observable adminUltimatumAlertOpened = false;

  @observable netflixMigrateId;

  @observable netflixMigrateOpen;

  factory() {
    this.abtests = {};
    this.fromOnboarding = false;
    this.referral = ReferralModel;
    this.renews = RenewsModel;
    this.savings = SavingsModel;
    this.status = {
      user: UserStatus.USER.PENDING,
      renews: UserStatus.RENEWS.PENDING,
      referral: UserStatus.REFERRAL.PENDING,
      savings: UserStatus.SAVINGS.PENDING,
    };
    this.user = UserModel;
    this.userRisk = UserRiskManagementModel;
    this.configurations = undefined;
    this.publicConfigurations = null;
    this.canActivateFreeForYou = false;
    this.f4uRedeemCount = null;
    this.f4uAmount = null;
    this.f4eRedeemCount = null;
    this.closedCountryInfo = null;
    this.restoreIdFreshdesk = null;
    this.supportChatEnabled = false;
    this.netflixMigrateId = undefined;
    this.netflixMigrateOpen = false;
  }

  /**
   * Initializes the store by trying to login if the storage exists
   *
   * @returns {boolean}
   */
  async init() {
    this.setPending();

    const storage = await this.fromStorage();
    if (!storage) return this.set("status.user", UserStatus.USER.ERROR);
    const expirationTime = getTokenExpirationTime(authStore.token);
    if (expirationTime <= 0) {
      return this.set("status.user", UserStatus.USER.EXPIRED);
    }

    const user = await Ums.get("getUserInfo", authStore.token);
    if (Err.check(user)) {
      return this.set("status.user", UserStatus.USER.EXPIRED);
    }

    const userRisk = await Ums.get("getRiskManagementStatus", authStore.token);

    if (trackingStore.promo) trackingStore.trackUserPromo(trackingStore.promo);
    await this.getConfigurations();
    await this.getSupportChatConfig();
    const hotJarIdentityEnabled =
      parseInt(userStore.configurations["frontEnd.hotJarIdentityEnabled"]) > 0;

    t.init(user.lang);
    recorder.user(user.id);
    if (hotJarIdentityEnabled) {
      let userNetworkInfo = await Network.get(
        "getUserNetworkInfo",
        authStore.token
      );
      if (Err.check(userNetworkInfo)) userNetworkInfo = {};
      recorder.identify(user, userNetworkInfo);
    }

    OneSignal.init(() => {
      this.getPromoTokenToUse();
      this.getPromoTokenToUseForFirstPaymentV2();
      this.getPromoTokenToUseForRenewV2();
      this.getCrossSelling();
      mutate("/notificationsSummary");
    })
      .then(() => OneSignal.setUserId(user.id))
      .catch((err) => console.error(err));

    this.set("user", user);
    this.set("userRisk", userRisk);
    const fdIdR = await this.getNotificationUserInfo();
    this.set("restoreIdFreshdesk", fdIdR.freshdeskId);

    await Promise.all([
      this.getOnboarding(),
      authStore.getSignUpStatus().then((response) => this.initData(response)),
    ]).then(() => this.toStorage());

    this.checkNetflixMigration();

    this.setSuccess();
    return true;
  }

  async checkNetflixMigration() {
    const hasNetflixToMigrate = await this.fetchNetflixMigration();

    const userId = this.user.id;
    let migration;

    try {
      // { 124: 3, 125: 1, 126: 2}
      const migrationData = await SecureStorage.get("netflix_migration");

      migration = JSON.parse(migrationData);
      if (typeof migration[userId] !== "number") migration[userId] = 0;
    } catch (e) {
      migration = { [userId]: 0 };
    }

    if (hasNetflixToMigrate && migration[userId] < 3) {
      this.set("netflixMigrateOpen", true);

      migration[userId] += 1;

      await SecureStorage.set("netflix_migration", JSON.stringify(migration));
    }
  }

  async fetchNetflixMigration() {
    const { hasNetflixToMigrate } = await Network.get(
      "/utilities/migrate/hasNetflixToMigrate",
      authStore.token
    );

    this.set("netflixMigrateId", hasNetflixToMigrate);

    return hasNetflixToMigrate;
  }

  setPending() {
    this.set("status.user", UserStatus.USER.PENDING);
  }

  setSuccess() {
    this.set(
      "status.user",
      this.user.firstTime ? UserStatus.USER.SUCCESS : UserStatus.USER.SUCCESS_OB
    );
  }

  setAdminUltimatumAlertOpened() {
    this.adminUltimatumAlertOpened = true;
  }

  getAdminUltimatumAlertOpened() {
    return this.adminUltimatumAlertOpened;
  }

  async loadUserLanguage() {
    const user = await Ums.get("getUserInfo", authStore.token);
    if (Err.check(user)) {
      return this.set("status.user", UserStatus.USER.EXPIRED);
    }

    t.init(user.lang);
  }

  async initData(isRegistrationComplete) {
    if (!isRegistrationComplete) {
      navigatorStore.stack("/complete-sign-up");
      return navigatorStore.block();
    }
    await Promise.all([
      stripeStore.getStripeInfo(),
      notificationStore.getNotificationsSummary(),
      this.getClosedCountryInfo(),
      this.getPromos(),
    ]);
    const { landedDisneyPlus } = this.user?.onboarding;
    const { country } = this.user;
    const landingEnabled =
      parseInt(this.configurations["frontEnd.showDisneyLandingPage"]) > 0;

    if (landingEnabled && !landedDisneyPlus && country === "IT") {
      return navigatorStore.stack("/disney-plus-landing");
    }
  }

  getNotificationUserInfo = async () => {
    const response = await Notification.get(
      "/getNotificationUserInfo",
      authStore.token
    );
    if (Err.check(response)) {
      toastStore.error("Faqs.sendQuestion1");
    }
    return response;
  };

  saveFreshdeskId = async (freshdeskId) => {
    const response = await Notification.post(
      "/setFreshdeskId",
      { freshdeskId },
      authStore.token
    );
    if (Err.check(response)) {
    } else {
      this.set("restoreIdFreshdesk", freshdeskId);
    }
  };

  async getPromos() {
    return Promise.all([
      // this.getAutoPromo(true),
      this.getFree4Ever(),
      this.getPromoTokenToUse(),
      this.getCrossSelling(),
      this.getPromoTokenToUseForFirstPaymentV2(),
      this.getPromoTokenToUseForRenewV2(),
    ]);
  }

  async getCrossSelling() {
    let crossSelling;

    crossSelling = await Retention.get(
      "cross_selling_promo/hasCrossSellingPromoInPending",
      authStore.token
    );
    if (Err.check(crossSelling)) return this.set("user.crossSelling", {});

    this.set("user.crossSelling", crossSelling);

    return true;
  }

  async getFree4Ever() {
    let f4e;

    f4e = await Retention.get(
      "promo/get_user_promo?promo_tag=free_4_ever",
      authStore.token
    );
    if (Err.check(f4e)) return this.set("user.f4e", {});

    // Disable Free4Ever
    this.set("user.f4e", f4e);

    return true;
  }

  async getOnboarding() {
    let response;

    response = await Ums.get("getUserOnboarding", authStore.token);
    if (Err.check(response)) return this.hideTutorial();
    this.set("user.onboarding", response);
    if (this.user.id < 200000) return this.hideTutorial();

    return true;
  }

  async getPromoTokenToUse() {
    const promoTokenToUse = await Retention.get(
      "/hasPromoTokenToUse",
      authStore.token
    );
    if (Err.check(promoTokenToUse) || !promoTokenToUse.active) {
      this.set("user.promoToUse", { amount: {} });
      return false;
    }

    this.set("user.promoToUse", promoTokenToUse);
    return true;
  }

  async getPromoTokenToUseForFirstPaymentV2() {
    const promoTokenToUseForFirstPayment = await Retention.get(
      "/tokens/getPromoTokensForFirstPayment",
      authStore.token
    );

    if (Err.check(promoTokenToUseForFirstPayment)) {
      this.set("user.promoToUseForFirstPayment", { amount: {} });
      return false;
    }
    this.set("user.promoToUseForFirstPayment", promoTokenToUseForFirstPayment);
    return true;
  }

  async getPromoTokenToUseForRenewV2() {
    const promoTokenToUseForRenew = await Retention.get(
      "/tokens/getPromoTokensForRenew",
      authStore.token
    );
    if (Err.check(promoTokenToUseForRenew)) {
      this.set("user.promoToUseForRenew", { amount: {} });
      return false;
    }
    this.set("user.promoToUseForRenew", promoTokenToUseForRenew);
    return true;
  }

  async getClosedCountryInfo() {
    if (this.isInAClosedMarketCountry) {
      const response = await Financial.get("/latam/info", authStore.token);
      if (!Err.check(response)) {
        this.set("closedCountryInfo", response);
      }
    }
  }

  async getConfigurations() {
    const response = await Ums.get("getConfigurations", authStore.token);
    if (Err.check(response)) {
      this.set("configurations", {});
      return false;
    }
    this.set("configurations", response);
    return true;
  }

  async getSupportChatConfig() {
    const response = await Notification.get(
      "isSupportChatAvailable",
      authStore.token
    );
    if (Err.check(response)) {
      this.set("supportChatEnabled", false);
      return false;
    }
    this.set("supportChatEnabled", response);
    return true;
  }

  isFreshdeskEnabled() {
    return this.supportChatEnabled;
  }

  async getPublicConfigurations() {
    if (this.publicConfigurations) return true;
    const response = await Ums.get("getPublicConfigurations", authStore.token);
    if (Err.check(response)) {
      this.set("publicConfigurations", null);
      return false;
    }
    this.set("publicConfigurations", response);
    return true;
  }

  /**
   * At the end of the onboarding sets the first_time variable to false
   *
   * @returns {boolean}
   */
  async setFirstTime() {
    if (isString(this.user.firstTime) && !isEmpty(this.user.firstTime)) {
      return false;
    }

    // Set that the user is not a first timer through the server
    const newResponse = await Ums.post("/firstTime", {}, authStore.token);

    if (Err.check(newResponse)) return false;

    // And also save it inside the store
    this.set("user.firstTime", true);

    // If the code arrived here then it means that the user can go to the dashboard
    this.set("status.user", UserStatus.USER.SUCCESS);

    return true;
  }

  /**
   * Updates the logged user preferred payment method
   *
   * @returns {boolean}
   */
  async setPaymentMethod() {
    let response;
    let payload;

    payload = {
      pay_with: this.user.payWith,
    };

    response = await Server.put(
      "users/update_payment_method",
      payload,
      authStore.token
    );
    if (Err.check(response)) return false;

    return true;
  }

  /**
   * Gets the posts that the logged user has to renew
   *
   * @returns {boolean}
   */
  async getRenews() {
    let renews;

    this.set("status.renews", UserStatus.RENEWS.PENDING);

    renews = await Server.get("users/renews", authStore.token);
    if (Err.check(renews)) {
      return this.set("status.renews", UserStatus.RENEWS.ERROR);
    }

    renews.data[0].sharingPosts = Object.values(renews.data[0].sharingPosts);

    this.set("renews", renews.data[0]);

    this.set("status.renews", UserStatus.RENEWS.SUCCESS);

    return true;
  }

  /*
   *
   * Can or not activate the new F4U
   *
   * */
  async getCanActivateFreeForYou(serviceId, numberOfPosts) {
    const response = await Retention.get(
      `promo/isFree4everActivable?serviceId=${serviceId}&numberOfPosts=${numberOfPosts}`,
      authStore.token,
      null
    );
    if (Err.check(response)) return this.set("canActivateFreeForYou", null);
    this.set("canActivateFreeForYou", response.isActivable);
    this.set("f4uRedeemCount", response.redeemCount);
    this.set("f4uAmount", response.amount);
    return true;
  }

  /**
   * Send user tracked data for free4You to server
   *
   * @param {long} serviceId
   * @param {string} serviceGroupTag
   * @param {object} keysToSet
   * @returns {boolean}
   */
  async sendUserTrackedDataF4U(serviceId, serviceGroupTag, keysToSet) {
    const payload = {
      userId: this.user?.id,
      serviceId,
      serviceGroupTag,
      promoBannerShowed: false,
      clickedOnPromoBanner: false,
      clickedOnActivatePromo: false,
      verifyEmailShowed: false,
      clickedOnVerifyEmail: false,
      verifyCellphoneShowed: false,
      cellphoneVerified: false,
      firstMessageShowed: false,
      firstMessageWritten: false,
      tPasswordShowed: false,
      tPasswordFilled: false,
      groupCreated: false,
    };

    Object.assign(payload, keysToSet);

    const response = await Retention.post(
      "promo/saveTracking",
      payload,
      authStore.token
    );

    return true;
  }

  /**
   * Gets all the informations needed by the Referral Program
   *
   * @returns {boolean}
   */
  async getReferral() {
    this.set("status.referral", UserStatus.REFERRAL.PENDING);

    const referral = await Retention.get(
      "referral_promo/referralPage",
      authStore.token
    );
    if (Err.check(referral)) {
      return this.set("status.referral", UserStatus.REFERRAL.ERROR);
    }

    referral.referralLink = getAppUrl(
      `/sign-up?tag=viral_loop&channel=link&promo_code=${referral.code}`
    );

    this.set("referral", referral);

    this.set("status.referral", UserStatus.REFERRAL.SUCCESS);

    return true;
  }

  async getSavings(services) {
    const savings = {
      totalSaving: 0,
      serviceSavings: [],
    };
    // return true;

    this.set("status.savings", UserStatus.SAVINGS.PENDING);

    const savingResponse = await Network.get(
      `sharingServices/savings?${services
        ?.map((id) => ["servicesGroupsIds", id]?.join("="))
        ?.join("&")}`,
      authStore.token
    );
    if (Err.check(savings)) return false;

    savings.serviceSavings = Object.values(savingResponse.serviceSavings);
    savings.totalSaving = savingResponse.totalSaving;

    this.set("savings", savings);

    this.set("status.savings", UserStatus.SAVINGS.SUCCESS);

    return true;
  }

  async saveInterests(services) {
    const response = await Ums.post(
      "/setServicesLikes",
      services,
      authStore.token
    );
    return !Err.check(response);
  }

  async getAbTest(test) {
    let response;

    if (!isString(test)) return Err.type("UserStore", "getAbTest -> test");
    if (has(this.abtests, test)) return true;

    response = await Server.get(
      `users/abtest/get?test=${test}`,
      authStore.token
    );
    if (Err.check(response)) return false;

    if (!has(this.abtests, test)) {
      this.set(`abtests.${test}`, response[0].variant);
    }

    return true;
  }

  async checkIsJoinerAndHasNotCard() {
    if (!modalStore.isEveryIdle) return false;

    let response;
    let params;

    response = await Financial.get(
      "checkIsJoinerAndHasNotCard",
      authStore.token
    );
    if (Err.check(response)) return false;

    params = {
      title: "CreditCardModal.title.save",
      description: "CreditCardModal.description.save",
      image: card,
    };

    if (response) modalStore.set("creditCard", "pending", null, "", params);

    return true;
  }

  async updateAbTest(payload) {
    let response;

    response = await Server.post(
      "users/abtest/update",
      payload,
      authStore.token
    );
    if (Err.check(response)) return false;

    // Update the this store with the payload data
    this.set(`abtests.${payload.test}`, payload.variant);

    return true;
  }

  /**
   * Updates the logged user funnel
   *
   * @param {string} step
   */
  async updateFunnel(step) {
    const column = camelCase(step);
    const newResponse = Ums.post("/updateFunnel", { column }, authStore.token);
    if (Err.check(newResponse)) return false;

    recorder.funnel(step);
    return true;
  }

  /**
   * Updates the logged user onboarding
   *
   * @param {string} step
   * @returns {boolean}
   */
  async updateOnBoarding(step, extra) {
    const column = camelCase(step);
    // Load the current value of this onboarding step inside the current variable
    const current = this.user.onboarding[column];
    // Check if current is a string and is not an empty value (should be a date string)
    if (isString(current) && !isEmpty(current)) return false;

    const payload = {
      ...extra,
      column,
    };

    const newResponse = Ums.post("/updateOnBoarding", payload, authStore.token);
    if (Err.check(newResponse)) return false;

    // Update the local variable too
    this.set(`user.onboarding.${column}`, Date.now().valueOf().toString());

    return true;
  }

  /**
   * Updates the user object with a new one
   *
   * @param {object} user
   */
  @action update(user) {
    this.user = merge(this.user, user);
    this.user.image = user.profileImage;
  }

  /**
   * Forces the tutorial parts to be shown
   *
   * @param {boolean} checked
   * @returns {boolean}
   */
  forceTutorial(checked) {
    if (checked) this.setTutorial(null);
  }

  /**
   * Hides the tutorial
   */
  hideTutorial() {
    this.setTutorial(Date.now().valueOf().toString());
  }

  /**
   * Sets the tutorial object based on a custom state
   *
   * @param {any} state
   */
  @action setTutorial(state) {
    this.user.onboarding = {
      ...(this.user.onboarding || {}),
      serviceLike: state,
      wowEffect: state,
      firstChoice: state,
      network: state,
      postJoinInfo: state,
      sharingServiceInfo: state,
      payGroup: state,
      sharingServiceCustomInfo: state,
      adminCreation: state,
      creationSuccess: state,
      requestSentInfo: state,
      postEditInfo: state,
      tpasswordPostRow: state,
      tpasswordManagerForm: state,
      autoPromo: state,
      latamPayout: state,
    };
  }

  async fromStorage() {
    try {
      const data = await SecureStorage.get("UserStore");
      if (data && !isEmpty(data)) {
        Object.entries(data ?? {}).forEach(([key, value]) => {
          this.set(key, value);
        });
      }
    } catch (e) {
      console.log("failed", e);
      return false;
    }
    return true;
  }

  async toStorage() {
    await SecureStorage.set("lastUserInfo", this.user);
    return SecureStorage.set("UserStore", {
      user: this.user,
    });
  }

  /**
   * Fetches the auto promo status and details for the current user
   *
   * @returns {boolean}
   */
  async getAutoPromo(showModal) {
    let promo;
    let params;
    let isFirstJoin;
    let isPromoActive;

    promo = await Retention.get(
      "promo/get_user_promo?promo_tag=auto_promo",
      authStore.token
    );
    if (Err.check(promo) || isEmpty(promo) || !promo) return false;

    isFirstJoin = !this.user.onboarding.autoPromo;
    params = promo.details;

    postStore.set("autoPromo", params);

    if (!showModal || !promo.active || !params?.postId) return;
    await postStore.get(params.postId);

    isPromoActive = promo.active && postStore.hasNeverRenewed;

    if (isPromoActive && isFirstJoin) {
      modalStore.idleAll();
      return new AutoPromoModal({ mode: "home" });
    }
    if (isPromoActive && postStore.isAutoPromoExpired) {
      modalStore.idleAll();
      return new AutoPromoModal({ mode: "info" });
    }
    await cardStore.get();
    if (isPromoActive && !cardStore.isSaved) {
      modalStore.idleAll();
      return new AutoPromoModal({ mode: "card" });
    }
    return null;
  }

  @action reset() {
    this.factory();
    SecureStorage.remove("UserStore");
  }

  get activatedUsers() {
    return this.referral.activatedUsers;
  }

  get hasBlackFridayPromo() {
    return (
      this.user.promoToUse?.promoTag === PromoTag.BLACK_FRIDAY &&
      !this.isCrossSellingPending
    );
  }

  get canUseHotSpotPromo() {
    return this.hasBlackFridayPromo;
  }

  get hotSpotPromoPrice() {
    return {
      // TODO get currency from the right source
      currency: "EUR",
      value: 0.99,
    };
  }

  get hasCellphone() {
    return isString(this.user.cellphone) && !isEmpty(this.user.cellphone);
  }

  get hasEssentials() {
    if (this.status.user !== UserStatus.USER.SUCCESS) return false;

    const { name, surname, birthday } = this.user;

    return (
      isString(name) &&
      !isEmpty(name) &&
      isString(surname) &&
      !isEmpty(surname) &&
      isString(birthday) &&
      !isEmpty(birthday)
    );
  }

  get hasPriceBasedPromo() {
    return (
      !isNil(this.user.promoToUse.promoTag) &&
      this.user.promoToUse.promoTag !== PromoTag.REFUND_TOKEN &&
      !this.isCrossSellingPending
    );
  }

  get hasPromoToken() {
    return (
      JSON.stringify(this.user.promoToUse) !== JSON.stringify({ amount: {} })
    );
  }

  get getPromoToUseV2() {
    return [
      ...(this.user.promoToUseForFirstPayment || []),
      ...(this.user.promoToUseForRenew || []),
    ];
  }

  get hasReferralPromo() {
    return this.user.promoToUse?.promoTag === PromoTag.VIRAL_LOOP;
  }

  get hasRefundedFeePromo() {
    return this.user.promoToUse.promoTag === PromoTag.REFUND_TOKEN;
  }

  get hasSentLatamRequest() {
    return (
      this.user.onboarding.latamPayout &&
      momentStore
        .e(Date.now())
        .diff(this.user.onboarding.latamPayout, "hours") < 72
    );
  }

  get hasSentRequestWithPromoToken() {
    return this.hasPromoToken && !isEmpty(this.user.promoToUse.sharingRequest);
  }

  get canInviteForChristmas() {
    return (
      !this.hasBlackFridayPromo &&
      !this.isCrossSellingPending &&
      this.user.marketCountry !== "GB"
    );
  }

  get isCrossSellingActive() {
    return (
      this.user.promoToUse &&
      this.user.promoToUse.promoTag === PromoTag.CROSS_SELLING
    );
  }

  get isCrossSellingPending() {
    return (
      this.user.crossSelling &&
      this.user.crossSelling.promoTag === PromoTag.CROSS_SELLING &&
      this.user.crossSelling.status === "PENDING"
    );
  }

  get isCrossSellingVisible() {
    return (
      (this.isCrossSellingActive && !this.hasSentRequestWithPromoToken) ||
      this.isCrossSellingPending
    );
  }

  get isFree4EverActivable() {
    // return this.user.f4e.activable === true
    //  && trackingStore.referral.promotions?.includes('free_4_ever');
    return this.user?.f4e?.activable;
  }

  get isFreeForYouActivable() {
    return this.canActivateFreeForYou;
  }

  get _f4uRedeemCount() {
    return this.f4uRedeemCount;
  }

  get _f4uAmount() {
    return this.f4uAmount;
  }

  get isLatam() {
    return t.latamCountries.indexOf(this.user.marketCountry) > -1;
  }

  get isInAClosedMarketCountry() {
    const bool = JSON.parse(
      this.configurations["frontEnd.closed.countries"] || "[]"
    ).includes(this.user.marketCountry);
    return bool;
  }

  get isCustomerCareChatAvailableMarketCountry() {
    const bool = JSON.parse(
      this.configurations["frontEnd.support.chat.countries"] || "[]"
    ).includes(this.user.marketCountry);
    return bool;
  }

  get isReferralEnabled() {
    const frontEndReferralConfiguration =
      this.configurations["frontEnd.referral.enabled"];

    return !!JSON.parse(frontEndReferralConfiguration);
  }
  get isForcedPayout() {
    return this.userRisk.forcedPayout;
  }

  get canCreateNewGroups() {
    return this.userRisk.createNewGroup;
  }

  get canUpdateGroups() {
    return this.userRisk.updateGroup;
  }

  get canHaveNewJoiner() {
    return this.userRisk.newJoiner;
  }

  get canWriteInChat() {
    return this.userRisk.writeInChat;
  }

  get isUSA() {
    return this.user.marketCountry === "US";
  }

  get maxActivations() {
    return this.referral.maxActivations;
  }

  get percentage() {
    return (this.activatedUsers / this.maxActivations) * 100;
  }

  clear() {
    this.set("canActivateFreeForYou", false);
  }
}

const userStore = new UserStore();
export default userStore;
