import React, { PureComponent } from 'react';
import Touchable from '../natives/Touchable';
import Div from '../natives/Div';
import Icon from '../natives/Icon';

class CheckBox extends PureComponent {
  render() {
    const { value, disabled, onPress } = this.props;

    // To avoid repeated color expressions we just declare it once here
    const color = disabled ? 'normal-gray' : this.props.color || 'black';

    const size = this.props.size || 18;

    return (
      <Touchable
        disabled={disabled}
        onPress={onPress}
        style={`jcc aic br1 rp cn${size} bw2 b-${color}`}
        animation='zoom'
      >
        {value ? (
          <Div style={`ap atn2 aln2 cn${size} bg-${color} br1`} />
        ) : null}
        {value ? (
          <Icon
            name='md-checkmark'
            color={color === 'white' ? 'black' : 'white'}
            style='zi1'
            size={14}
          />
        ) : null}
      </Touchable>
    );
  }
}

export default CheckBox;
