import _ from 'lodash'
import Regex from '../various/regex'
import momentStore from '../stores/moment'

function getRegexExplanation(regex) {
  return { id: `Regex.${regex || 'default'}` }
}

function validateForm(validator, store, entry, form) {
  let a, b

  // Start with a truthy value
  b = true

  // If any of the parameters is undefined just abort the operation
  if (_.isUndefined(validator) || _.isUndefined(store) || _.isUndefined(entry)) return false

  // The variable a is the store entry
  a = _.get(store, entry, '')
  if (_.isNumber(a)) a = a.toString()
  if (_.isNull(a)) a = ''

  const { min, max, regex, eq, bool, incl, number, between } = validator

  if (min) b = number ? a >= min : a.length >= min && !a.split('\n').join('').match(Regex.empty)
  if (!b) return form ? { id: `Validation.min${number ? 'Number' : ''}`, inject: [min] } : false

  if (max) b = number ? a <= max : a.length <= max
  if (!b) return form ? { id: `Validation.max${number ? 'Number' : ''}`, inject: [max] } : false

  if (regex) b = a.match(Regex[regex])
  if (!b) return form ? getRegexExplanation(regex) : false

  if (eq) b = a === _.get(store, eq)
  if (!b) return form ? { id: 'Validation.eq' } : false

  if (bool) b = a === '1' || a === true
  if (!b) return form ? { id: 'Validation.bool' } : false

  if (incl) b = incl.indexOf(a) > -1
  if (!b) return form ? { id: 'Validation.incl' } : false

  if (between) b = momentStore.e(a).isBetween(between.min, between.max)
  if (!b)
    return form ? { id: 'Validation.between', inject: [between.min.format('YYYY'), between.max.format('YYYY')] } : false

  return true
}

const Validate = {
  exec: validateForm
}
export default Validate
