import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline';
import { QueryParamProvider } from 'use-query-params';
import { I18nextProvider } from 'react-i18next';
import theme from '@bit/together-price.core.theme';
import AppLinearProgress from '@bit/together-price.core.components.app-linear-progress';
import SnackbarProvider from '@bit/together-price.core.components.snackbar-provider';
import TPSWRConfig from '@bit/together-price.core.components.tp-swr-config'
import Spy from './shared/components/Spy';
import Toast from './shared/overlays/Toast';
import Div from './shared/natives/Div';
import StandardModal from './shared/components/StandardModal';
import Loader from './shared/components/Loader';
import VersionModal from './shared/modals/VersionModal';
import FormModal from './shared/components/FormModal';
import DevNavigator from './shared/components/DevNavigator';
import DropdownModal from './shared/components/DropdownModal';
import DialogModal from './shared/components/DialogModal';
import AppFeedback from './modules/commons-v2/AppFeedback/AppFeddback';
import i18n from './i18n';
import DynamicStatusBar from './theme/DynamicStatusBar';
import AppUrlListener from './shared/components/AppUrlListener';
import MaintenancePage from './shared/components/MaintenancePage';
import Routes from './routes/Routes';
import OnlyIfSupportedVersion from './components/OnlyIfSupportedVersion';
import VersionNotSupported from './components/VersionNotSupported';
import BackHandler from './components/BackHandler';
import NetworkConnection from './shared/components/NetworkConnection';
import AppReview from './shared/components/AppReview';
import ExpirationControl from './shared/components/ExpirationControl';
import { generateClassName } from './theme';
import { ErrorBoundary } from './shared/components/LazyRoute';
import ServerError from './shared/components/ServerError';
import OneSignalNotification from './shared/components/OneSignalNotification';

const { REACT_APP_BASENAME: appBasename } = process.env;

const App = () => (
  <RecoilRoot>
    <TPSWRConfig>
      <MaintenancePage>
        <ScopedCssBaseline
          classes={{ root: 'ffp bg-white lg-bg-lighter-violet' }}
        >
          <StylesProvider generateClassName={generateClassName}>
            <MuiThemeProvider theme={theme}>
              <NetworkConnection>
                <AppFeedback>
                  <Router basename={appBasename}>
                    <SnackbarProvider />
                    <OneSignalNotification />
                    <AppUrlListener />
                    <BackHandler />
                    <ExpirationControl />
                    <QueryParamProvider ReactRouterRoute={Route}>
                      <DynamicStatusBar />
                      <I18nextProvider i18n={i18n}>
                        <Div style='wp100'>
                          <AppReview />
                          <Spy />
                          <Loader />
                          <Toast />
                          <StandardModal />
                          <DialogModal />
                          <VersionModal />
                          <FormModal />
                          <DropdownModal />
                          {process.env.REACT_APP_ENV === 'DEVELOPMENT' && (
                            <DevNavigator />
                          )}
                          <Suspense fallback={<AppLinearProgress />}>
                            <ErrorBoundary fallback={<ServerError />}>
                              <OnlyIfSupportedVersion
                                fallback={<VersionNotSupported />}
                              >
                                <Routes />
                              </OnlyIfSupportedVersion>
                            </ErrorBoundary>
                          </Suspense>
                        </Div>
                      </I18nextProvider>
                    </QueryParamProvider>
                  </Router>
                </AppFeedback>
              </NetworkConnection>
            </MuiThemeProvider>
          </StylesProvider>
        </ScopedCssBaseline>
      </MaintenancePage>
    </TPSWRConfig>
  </RecoilRoot>
);

export default App;
