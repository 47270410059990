import { Device } from '@capacitor/device';

export class Recorder {
  private instance;

  constructor() {
    this.instance = Device.getInfo()
      .then(async ({ platform }) => {
        if (platform === 'web') {
          return import('./hotjar');
        }
        return import('./smartlook');
      }).then(({ default: instance }) => instance);
  }

  init() {
    return this.instance.then((i) => i.init());
  }

  route(route: string) {
    return this.instance.then((i) => i.route(route));
  }

  modal(modal: string) {
    return this.instance.then((i) => i.modal(modal));
  }

  user(userId: number) {
    return this.instance.then((i) => i.user(userId));
  }

  identify(userInfo: any, userNetworkInfo: any) {
    return this.instance.then((i) => i.identify(userInfo, userNetworkInfo));
  }

  funnel(funnel: string) {
    return this.instance.then((i) => i.funnel(funnel));
  }
}

export default Recorder;
