import Queue from 'queue';
import { set } from 'lodash';
import { defaultDetectWebpImage, detectWebpSupport, WebpMachine } from 'tp-webp-hero';
import { Webp } from 'tp-webp-hero/libwebp/dist/webp.js';
import Store from '../various/store';

class FixedWebpMachine extends WebpMachine {
  constructor() {
    const opts = {
      webp: new Webp(),
      webpSupport: detectWebpSupport(),
      detectWebpImage: defaultDetectWebpImage,
    };
    opts.webp.Module.doNotCaptureKeyboard = true;
    super(opts);
  }
}

class ImageStore extends Store {
  queue

  webp

  factory() {
    this.queue = Queue({ autostart: true, concurrency: 1 });
    this.webp = new FixedWebpMachine();

    set(this.webp, 'detectWebpImage', () => true);
  }

  decode(img) {
    this.queue.push(async () => this.webp.polyfillImage(img)
      .catch((e) => console.error(`error polyfilling image ${img.src}`, e)));
  }
}

const imageStore = new ImageStore();
export default imageStore;
