import React, { Component } from 'react';
import { isBoolean, isObject } from 'lodash';
import Column from '../layout/Column';
import Span from '../natives/Span';
import Tooltip from '../components/Tooltip';
import Icon from '../natives/Icon';

class FormItem extends Component {
  get title() {
    const { entry, title, color, inject } = this.props;

    return isBoolean(title) && !title ? null : (
      <Span
        id={title}
        value={entry}
        style={`ts14 ${color || 'black'}`}
        inject={inject}
        casing='capitalize'
      />
    );
  }

  render() {
    const {
      id,
      children,
      width,
      style,
      margin,
      tooltip,
      border,
      required,
    } = this.props;
    return (
      <Column
        id={id}
        style={`${style} tfr rp aifs ${margin || 'mv3'} ${
          isBoolean(border) && !border ? '' : 'bwb1 b-normal-gray'
        } ${width || 'wp100'}`}
      >
        {isObject(tooltip) ? (
          <Tooltip
            title={tooltip.title}
            description={tooltip.description}
            inject={tooltip.inject}
            icon={tooltip.icon || true}
          >
            {this.title}
          </Tooltip>
        ) : (
          this.title
        )}
        {required && (
          <Icon name='ios-medical' style='ap at0 ar0' color='red' size='8' />
        )}
        {children}
      </Column>
    );
  }
}

export default FormItem;
