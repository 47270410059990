const Regex = {
  name: /^[a-zA-ZÀ-ÖØ-öø-ÿ'\s]+$/,
  surname: /^[a-zA-ZÀ-ÖØ-öø-ÿ'\-\s]+$/,
  password: /^((?=.*[a-z])(?=.*\d)(?=.*[A-Z]).{8,40})$/,
  email: /[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/,
  city: /^[a-zA-ZÀ-ÖØ-öø-ÿ'\s]+$/,
  number: /^[\d.]+$/,
  textFind: /(m\w*\d+|p\w*\d+|ff|tal|tac|tar|asc|asfs|asfe|toe)/gm,
  textReplace: /(m\w*\d+|p\w*\d+|ff)/gm,
  cardReplace: /.{4}/gm,
  link: /(http|www)(s)?(:\/\/|\.).+(.\w+|:\d+)(.+)?/gm,
  empty: /^\s*$/gm,
  iban: /^[a-zA-Z\-\d]+$/gm,
  address: /^[a-zA-ZÀ-ÖØ-öø-ÿ',\-\s\d]+$/gm
}
export default Regex
