import React, { useEffect } from 'react';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { styled } from '@material-ui/core';

const IosStatusBar = styled('div')(
  {
    position: 'fixed',
    top: 0,
    zIndex: 1300,
    height: 'env(safe-area-inset-top)',
    width: '100vw',
  },
  {
    name: 'IosStatusBar',
  }
);

type StatusBarThemeProps = {
  style?: 'light' | 'dark';
  color?: string;
};

const StatusBarTheme: React.FC<StatusBarThemeProps> = React.memo(
  ({ style = 'light', color = '#ffffff' }) => {
    useEffect(() => {
      (
        document.querySelector("meta[name='theme-color']") as HTMLMetaElement
      ).content = color;
      if (!Capacitor.isPluginAvailable('StatusBar')) {
        return;
      }
      StatusBar.setStyle({
        style: style === 'light' ? Style.Light : Style.Dark,
      });
      StatusBar.setOverlaysWebView({ overlay: false });
      StatusBar.setBackgroundColor({
        color,
      });
    }, [style, color]);
    if (Capacitor.getPlatform() === 'ios') {
      return (
        <IosStatusBar
          style={{
            backgroundColor: color,
          }}
        />
      );
    }
    return <></>;
  }
);

export default StatusBarTheme;
