import React from 'react';
import Touchable from '../natives/Touchable';

const TouchableRow = (props) => {
  const {
    disabled, style, children, width, useNative, onPress,
  } = props;
  return (
    <Touchable
      disabled={disabled}
      onPress={onPress}
      style={`${width || 'wp100'} fr aic ${style}`}
      useNative={useNative}
    >
      {children}
    </Touchable>
  );
};

export default TouchableRow;
