import { observable } from 'mobx';
import { isEmpty } from 'lodash';
import Store from '../various/store';
import Err from '../utils/error';
import Ums from '../utils/ums';
import toastStore from './toast';
import { CryptoStatus } from '../various/enums';
import authStore from './auth';
import postStore from './post';
import tpasswordStore from './tpassword';
import createStore from './create';

export class CryptoStore extends Store {
  @observable confirmPassword;

  @observable hasSecret;

  @observable hasSeenSecrets;

  @observable item;

  @observable masked;

  @observable usernameMasked;

  @observable passwordMasked;

  @observable status;

  @observable tos;

  factory() {
    this.confirmPassword = '';
    this.hasSecret = false;
    this.hasSeenSecrets = false;
    this.item = {
      username: '',
      password: '',
      street: '',
      cap: '',
      city: '',
      link: '',
    };
    this.masked = true;
    this.usernameMasked = true;
    this.passwordMasked = true;
    this.status = CryptoStatus.PENDING;
    this.tos = false;
  }

  set googleLocations(value) {
    if (!value) {
      this.resetLocations();
      return;
    }

    value.forEach((prop) => {
      prop.types.reverse().forEach((type) => {
        switch (type) {
          case 'route':
            this.set('item.street', prop.long_name);
            break;
          case 'locality':
          case 'administrative_area_level_3':
          case 'administrative_area_level_2':
            this.set('item.city', prop.long_name);
            break;
          case 'postal_code':
            this.set('item.cap', prop.long_name);
            break;
          default:
            break;
        }
      });
    });
  }

  resetLocations() {
    this.set('item.street', '');
    this.set('item.city', '');
    this.set('item.street', '');
  }

  async getVault() {
    if (!postStore.id) return false;

    const hasSecret = await Ums.get(
      `sharing/secrets/sharingPostHasSecret/${postStore.id}`,
      authStore.token
    );
    this.set('hasSecret', hasSecret);

    if (Err.check(hasSecret) || !hasSecret)
      return this.set('status', CryptoStatus.ERROR);

    const secrets = await Ums.get(
      `sharing/secrets/getSecrets/${postStore.id}`,
      authStore.token
      );
    if (Err.check(secrets) || isEmpty(secrets))
      return this.set('status', CryptoStatus.ERROR);

    this.set('item', JSON.parse(secrets.secret.match(/{.*}/gm)[0]));
    this.set('hasSeenSecrets', secrets.hasSeenSecrets);
    this.set('status', CryptoStatus.SUCCESS);

    return true;
  }

  async setVault(tpasswordType) {
    let payload;
    let response;
    payload = {
      sharingPostId: postStore.id,
      secret: JSON.stringify(this.item),
      ...(tpasswordType || createStore.tpasswordType
        ? { secretType: tpasswordType || createStore.tpasswordType }
        : {}),
    };
    response = await Ums.post(
      'sharing/secrets/setSecrets',
      payload,
      authStore.token
    );
    if (Err.check(response)) {
      toastStore.error('CryptoStore.setVault');
      return false;
    }

    tpasswordStore.getJoiners();
    tpasswordStore.getRating();

    this.getVault();

    toastStore.success('CryptoStore.setVault');
    return true;
  }

  toggleMask() {
    this.set('masked', !this.masked);
  }

  toggleUsernameMask() {
    this.set('usernameMasked', !this.usernameMasked);
  }

  get isVaultEmpty() {
    return !this.hasSecret;
  }
}

const cryptoStore = new CryptoStore();
export default cryptoStore;
