import React, { Component } from 'react'
import Div from '../natives/Div'

class Container extends Component {
  render() {
    const { style, children, widthStyle } = this.props

    return <Div style={widthStyle || `cnp100 ${style}`}>{children}</Div>
  }
}

export default Container
