function removeWhiteSpaces(a, b) {
  return a.replace(/[ ]/g, '');
}

function parseMaskedPhone(a, b) {
  return a.replace(/[ |+]/g, '');
}

// function removeEuro (a, b) {
//   return a.replace('€ ', '').replace(/,/g, '.')
// }

// function convertBirthday (a, b) {
//   return (a.substring(6, 10) + a.substring(3, 5) + a.substring(0, 2))
// }

const creditCard = {
  type: 'custom',
  options: {
    mask: '**** **** **** ****',
    getRawValue: removeWhiteSpaces,
  },
};

const expiryMonth = {
  type: 'custom',
  options: {
    mask: '99',
  },
};

const expiryYear = {
  type: 'custom',
  options: {
    mask: '9999',
  },
};

const expiryDate = {
  type: 'custom',
  options: {
    mask: '99/99',
  },
};

const phoneNumber = {
  type: 'custom',
  options: {
    mask: '*99999999999999999999999',
    getRawValue: parseMaskedPhone,
    // translation: {
    //   '#': val => {
    //     return val === '+' ? '+' : '9'
    //   }
    // }
  },
};

const euro = {
  type: 'money',
  options: {
    precision: 2,
    unit: '€ ',
    separator: '.',
  },
};

const swedenKrone = {
  type: 'money',
  options: {
    precision: 2,
    unit: 'kr ',
    separator: '.',
  },
};

const danishKrone = {
  type: 'money',
  options: {
    precision: 2,
    unit: 'kr. ',
    separator: '.',
  },
};

const czechKrone = {
  type: 'money',
  options: {
    precision: 2,
    unit: 'Kč ',
    separator: '.',
  },
};
const zloti = {
  type: 'money',
  options: {
    precision: 2,
    unit: 'zl ',
    separator: '.',
  },
};
const forintHungarian = {
  type: 'money',
  options: {
    precision: 2,
    unit: 'Ft ',
    separator: '.',
  },
};

const pound = {
  type: 'money',
  options: {
    precision: 2,
    unit: '£ ',
    separator: '.',
  },
};

const dollar = {
  type: 'money',
  options: {
    precision: 2,
    unit: '$ ',
    separator: '.',
  },
};

const cvc = {
  type: 'custom',
  options: {
    mask: '999',
  },
};

const birthday = {
  type: 'custom',
  options: {
    mask: '9999-99-99',
  },
};

const Mask = {
  creditCard,
  expiryMonth,
  expiryYear,
  expiryDate,
  cvc,
  phoneNumber,
  euro,
  birthday,
  currency: {
    EUR: euro,
    GBP: pound,
    USD: dollar,
    DKK: danishKrone,
    PLN: zloti,
    SEK: swedenKrone,
    HUF: forintHungarian,
    CZK: czechKrone,
  },
};
export default Mask;
