import React, { Component } from 'react';
import BaseModal from '../components/BaseModal';
import Span from '../natives/Span';
import BigButton from '../components/BigButton';
import tools from '../assets/app/tools.svg';

class VersionModal extends Component {
  onPress = async () => {
    window.location.reload();
  };

  render() {
    if (process.env.NODE_ENV === 'development') return false;

    return (
      <BaseModal target="version" title="VersionModal.title" image={tools}>
        <Span id="VersionModal.description" style="tfsb tac" />
        <BigButton title="VersionModal.button" onPress={this.onPress} style="mt4" />
      </BaseModal>
    );
  }
}

export default VersionModal;
