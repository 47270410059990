import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Button from './Button';

@observer
class BigButton extends Component {
  render() {
    const { width, style } = this.props;

    return <Button {...this.props} style={`${width || 'wp100'} mnh50 h50 ${style}`} />;
  }
}

export default BigButton;
