import React, { Component } from 'react';
import t from '../stores/translate';
import { observer } from 'mobx-react';
import _ from 'lodash';
import Regex from '../various/regex';

@observer
class Title extends Component {
  render() {
    const { id, inject, casing, mask, outerStyle, numberOfLines, injectStyle } =
      this.props;

    let value, style, mpStyle, maskStyle;

    // Style is equal to the style given in the props
    style = this.props.style
      ? `${this.props.style} ${numberOfLines && 'toe'}`
      : '';

    // Get the translated value through the provided id, if not found show the raw value
    value = t.get(id, inject, casing) || t.casify(this.props.value, casing);

    // MP Style stands for margin and padding style, this is done to avoid using outerStyle when not necessary
    mpStyle = _.isString(style)
      ? (style.match(Regex.textFind) || []).join(' ')
      : '';
    if (mpStyle) style = style.replace(Regex.textReplace, '');

    return (
      <title id={id} className={`${mpStyle} ${outerStyle} ${style}`}>
        {_.isArray(value) ? (
          value.map((v, key) => {
            maskStyle = v.style
              ? `${style.replace(/(tft|tfr|tfsb|tfb)/gm, '')} ${
                  v.style
                } ${injectStyle}`
              : style;
            return (
              <title mask={mask} key={key} className={maskStyle}>
                {v.value}
              </title>
            );
          })
        ) : (
          <title className={style}>{value}</title>
        )}
      </title>
    );
  }
}

export default Title;
