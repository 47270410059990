import _ from 'lodash'

function parseObjectCamelCase(o) {
  return JSON.parse(JSON.stringify(o), (k, v) => (_.isObject(v) ? _.mapKeys(v, (_v, _k) => _.camelCase(_k)) : v))
}

function parseObjectSnakeCase(o) {
  return JSON.parse(JSON.stringify(o), (k, v) => (_.isObject(v) ? _.mapKeys(v, (_v, _k) => _.snakeCase(_k)) : v))
}

function parseObjectKeys(o) {
  let a

  Object.keys(o).forEach(b => a.push({ value: o[b], key: b }))

  return a
}

const Format = {
  objectCamelCase: parseObjectCamelCase,
  objectSnakeCase: parseObjectSnakeCase,
  objectKeys: parseObjectKeys
}
export default Format
