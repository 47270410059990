import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Span from '../natives/Span';
import BigButton from './BigButton';
import modalStore from '../stores/modal';
import BaseModal from './BaseModal';

@observer
class StandardModal extends Component {
  render() {
    const {
      title,
      image,
      description,
      button,
      inject,
      align,
      link,
      onPress,
      children,
      isButtonDisabled,
      width,
      buttonColor,
      closable = true,
      zIndex,
    } = modalStore.standard;

    const injectTitle = inject.title;
    const injectDescription = inject.description;
    const injectButton = inject.button;

    return (
      <BaseModal
        target="standard"
        title={title}
        inject={injectTitle}
        image={image}
        width={width}
        closable={closable}
        zIndex={zIndex}
      >
        {description && (
        <Span
          id={description}
          inject={injectDescription}
          style={`mb6 ${align || 'tac asc'}`}
          link={link}
        />
        )}
        {children}
        <BigButton
          title={button}
          color={buttonColor}
          onPress={onPress}
          disabled={isButtonDisabled}
          inject={injectButton}
        />
      </BaseModal>
    );
  }
}

export default StandardModal;
