import { action, observable } from 'mobx';
import {
  filter,
  find,
  findKey,
  get,
  has,
  isEmpty,
  isNull,
  isString,
} from 'lodash';
import debounceAsync from 'debounce-async';
import Chance from 'chance';
import moment from 'moment';
import Server from '../utils/server';
import Err, { getErrorMessage, hasError } from '../utils/error';
import authStore from './auth';
import {
  NetworkStatus,
  PostStatus,
  PostStep,
  Relation,
} from '../various/enums';
import Post from '../utils/post';
import { PostModel } from '../various/models';
import networkStore from './network';
import sharingStore from './sharing';
import userStore from './user';
import appStore from './app';
import navigatorStore from './navigator';
import Store from '../various/store';
import Retention from '../utils/retention';
import toastStore from './toast';
import chat from './chat';
import momentStore from './moment';
import Network from '../utils/network';
import stripeStore from './stripe';
import Financial from '../utils/financial';
import notificationStore from './notification';
import ratingStore from './rating';
import Errors from '../utils/error';
import cardStore from './card';
import t from './translate';

const mockPromoTokens = [
  {
    active: true,
    promoTag: 'viral_loop',
    amount: { value: 2.5, currency: 'EUR' },
    promoId: 4,
    userPromotionId: 51833,
    status: 'ACTIVE',
    discountOn: 'AMOUNT',
  },
  {
    active: true,
    promoTag: 'refund_token',
    amount: { value: 2.5, currency: 'EUR' },
    promoId: 4,
    userPromotionId: 51833,
    status: 'ACTIVE',
    discountOn: 'FEE',
  },
];

class PostStore extends Store {
  @observable adminDetails;

  @observable expiration;

  @observable history;

  @observable id;

  @observable joiners;

  @observable payment;

  @observable post;

  @observable promo;

  @observable promoTokens;

  @observable relationship;

  @observable settings;

  @observable status;

  @observable step;

  @observable tos;

  @observable userRelation;

  @observable userStatus;

  @observable users;

  @observable uuid;

  @observable suggestions;

  @observable promoTokensReservationId;

  @observable postDetails;

  @observable plans;

  @observable userInvited;

  @observable wasInvited;

  @observable focusChat;

  @observable uploadImageDrawer;

  @observable editTitleDrawer;

  @observable exJoiner;

  factory() {
    this.uploadImageDrawer = false;
    this.adminDetails = PostModel.admin;
    this.history = [];
    this.id = '';
    this.joiners = PostModel.members;
    this.payment = {
      wallet: 0,
      creditCard: 0,
    };
    this.post = PostModel.details;
    this.promo = PostModel.promo;
    this.relationship = {
      friend: 0,
      family: 0,
      roommate: 0,
      teammate: 0,
    };
    this.settings = {};
    this.status = PostStatus.PENDING;
    this.step = PostStep.CHECKOUT;
    this.tos = 0;
    this.userRelation = '';
    this.userStatus = '';
    this.users = PostModel.members;
    this.uuid = '';
    this.suggestions = {};
    // TODO change with call to server
    this.promoTokens = mockPromoTokens;
    this.focusChat = false;
    this.editTitleDrawer = false;
    this.exJoiner = false;
  }

  /**
   * Gets all the post informations
   *
   * @param {number} id
   * @returns {boolean}
   */
  get = debounceAsync(async (id) => {
    if (!id) return this.set('status', PostStatus.ERROR);
    this.set('status', PostStatus.PENDING);
    // Get the needed post through its id from the server
    const isExperience = JSON.parse(
      userStore.configurations['frontEnd.experiences'] || '[]'
    ).includes(Number(id));
    const post = await Network.get(
      isExperience ? `experiences/${id}` : `sharingPost/${id}`,
      authStore.token
    );

    if (Err.check(post)) return this.set('status', PostStatus.ERROR);
    await this.getActivableServicePlans(post.details.groupTag, false);

    const adminIsOnline =
      post.me.status === 'owner' ||
      (await notificationStore.isAdminOnline(post.admin.id));
    // Convert the joiners and users objects to array for later use
    post.members = [post.admin, ...Object.values(post.members || {})];
    // Set fallback variables till I manage to finish the refactor
    post.details = {
      ...post.details,
      adminName: post.admin.name,
      adminSurname: post.admin.surname,
      postTitle: post.details.title,
    };
    post.admin = {
      ...post.admin,
      adminIsOnline,
    };
    // Initialize the settings
    post.settings = {
      totalSlots: post.details.totalSlots.toString(),
      file: { link: post.details.image },
      changeCover: false,
      description: post.details.description,
      title: post.details.title,
      isPublic: post.details.isPublic,
      price: post.details.priceNoFee.value,
    };
    // Pass post id to chatStore
    chat.setStore();

    // Save the fetched data inside the store
    this.set('id', id);
    this.set('post', post.details);
    this.set('joiners', post.members || []);
    this.set('userRelation', {
      relation: post.me.relationWithAdmin,
    });
    this.set('userStatus', post.me.status);
    this.set('userInvited', post.me.invited);
    this.set('wasInvited', post.me.wasInvited);
    this.set('adminDetails', post.admin);
    this.set('settings', post.settings);
    this.set('promoTokensReservationId', post.promoTokensReservationId);
    this.set('exJoiner', post.me.exJoiner);
    this.set('expiration', {});

    this.resetCheckout();

    await this.getPromo(id, post.admin.id);

    if (this.isUserJoinedOrSuspended) await ratingStore.hasUserRated(id);
    ratingStore.set(
      'isOpen',
      this.isInGroupByOneMonth && !ratingStore.hasUserRatedGroup
    );

    if (this.userStatus === 'joiner') await this.getSharingPostUserDetails();

    const promoTokens = await this.getPromoTokens();
    this.set('promoTokens', hasError(promoTokens) ? [] : promoTokens);

    // If the code arrived here then it is a success
    this.set('status', PostStatus.SUCCESS);
    return true;
  }, 500);

  async getPromoTokens() {
    if (this.promoTokensReservationId) {
      return Retention.get(
        `/tokens/users/reserved/${this.promoTokensReservationId}`,
        authStore.token
      );
    }
    if (this.isUserOwned) {
      return [];
    }
    if (
      !this.isUserOldJoiner &&
      (this.isUserNotJoined || this.isUserPending || this.isUserAccepted)
    ) {
      return Retention.post(
        '/tokens/payment',
        {
          totalAmount: this.post.priceWithFee,
          fee: this.post.fee,
          frequency: this.post?.frequency?.toUpperCase(),
        },
        authStore.token,
        {
          'content-type': 'application/json',
        }
      );
    }
    // TODO: If a joiner still does not need to renew, the discount is not showed
    if (
      this.isUserOldJoinerCanEnter ||
      this.isLoggedUserRenewable ||
      this.isRenew
    ) {
      return Retention.post(
        '/tokens/renew',
        {
          totalAmount: this.post.priceWithFee,
          fee: this.post.fee,
          frequency: this.post?.frequency?.toUpperCase(),
        },
        authStore.token,
        {
          'content-type': 'application/json',
        }
      );
    }
    return [];
  }

  createPostToken = async () => {
    const response = await Network.post(
      `/sharingPost/createInviteLink?postId=${this.id}`,
      {},
      authStore.token
    );
    if (Errors.check(response)) return null;
    return response;
  };

  /**
   *
   * @param {number} id
   * @returns {boolean}
   */
  async getPromo(id, adminId) {
    const onError = () => {
      this.set('promo', {});
      return false;
    };
    if (adminId !== userStore.user.id) return onError();
    // If the user is the admin of the post then get the promo status through retention
    const promo = await Retention.get(
      `promo/get_post_promo?sharing_post_id=${id}&promo_tag=free_4_ever`,
      authStore.token
    );
    if (Err.check(promo) || isNull(promo)) return onError();

    if (!isEmpty(promo)) this.set('promo.free4Ever', promo);
    else return onError();

    return true;
  }

  /**
   * Tries to let the user join the current group
   *
   * @returns {boolean}
   */
  async join() {
    // Prepare the payload for the join request of the post
    const payload = {
      // The type of relationship is obtained by finding the entry with a truthy value (1) and
      // by converting the result key name to string
      relation: findKey(this.relationship, (entry) => entry === 1).toString(),
      // The message was once used, we just pass an empty array to the server for now
      postId: this.id,
      promoTag:
        get(userStore.user, 'promoToUse.promoTag') ||
        get(userStore.user, 'crossSelling.promoTag'),
      promoFrequency: userStore.user?.promoToUse?.frequency,
    };
    // Try to make the join request through the server
    const response = await Network.post(
      'sharingRequests/createSharingRequest',
      payload,
      authStore.token
    );
    if (Err.check(response)) return toastStore.error('PostPayment.join');

    // If the code arrived here it means that the request
    // was successful and we can say the the request is pending
    this.set('userStatus', 'pending');

    // Propagate this change
    this.propagate();

    return true;
  }

  /**
   * Tries to let the user join the current group
   *
   * @returns {boolean}
   */
  async joinExperience() {
    // Prepare the payload for the join request of the post
    const payload = {
      // The type of relationship is obtained by finding the entry with a truthy value (1) and
      // by converting the result key name to string
      relation: this.post.relation,
      // The message was once used, we just pass an empty array to the server for now
      postId: this.id,
      promoTag:
        get(userStore.user, 'promoToUse.promoTag') ||
        get(userStore.user, 'crossSelling.promoTag'),
      promoFrequency: userStore.user?.promoToUse?.frequency,
    };
    // Try to make the join request through the server
    const response = await Network.post(
      'sharingRequests/createSharingRequest',
      payload,
      authStore.token
    );
    if (Err.check(response)) return toastStore.error('PostPayment.join');

    // If the code arrived here it means that the request
    // was successful and we can say the the request is pending
    this.set('userStatus', 'pending');

    // Propagate this change
    this.propagate();

    return true;
  }

  async getLastPaySharingRequest() {
    const response = await Network.get(
      `requests/getLastPaySharingRequests?sharingPostId=${this.id}`,
      authStore.token
    );
    if (Err.check(response)) return null;
    return response;
  }

  async getPaySharingRequestId() {
    // The payload for the payment needs two things,
    // the sharingPostId and a randomly generated uuid by the client
    const payload = {
      sharingPostId: this.id,
      idempotencyId: this.uuid,
      paymentMethodId: cardStore.paymentMethodId
    };
    // Try to pay and get the paySharingRequestId along with a status to check how are things going
    const paySharingRequestId = await Network.post(
      'requests/element/paySharingRequest',
      payload,
      authStore.token
    );
    if (Err.check(paySharingRequestId)) {
      if (paySharingRequestId.code !== 'ECONNABORTED') this.generateUUID();
      return toastStore.error('PostPayment.pay');
    }

    return paySharingRequestId;
  }

  async getPaymentInfo(paySharingRequestId) {
    const response = await Network.get(
      `requests/checkPaySharingRequests/${paySharingRequestId}`,
      authStore.token
    );
    if (Err.check(response)) return {};
    return response;
  }

  async handlePayment3DSecure(clientSecret, paySharingRequestId) {
    const headers = {
      'content-type': 'application/json',
    };
    // Trigger the 3d secure popup
    const secure = await stripeStore.handleAction(clientSecret);

    // If 3ds fails call cancel the payment intent
    if (Err.check(secure)) {
      await Network.post(
        `requests/cancel/${paySharingRequestId}`, 
        {},
        authStore.token, 
        {
          'content-type': 'application/json',
        }
      )
      this.generateUUID();
      return toastStore.error('PostPayment.pay')
    };
    // Once the user successfully handled the 3d secure confirm the payment
    const payment = await Network.post(
      'requests/confirmPayment',
      paySharingRequestId,
      authStore.token,
      headers
    );
    if (Err.check(payment)) return toastStore.error('PostPayment.pay');
    return true;
  }

  async getPayRequestStatus() {
    if (this.userStatus === 'inside') {
      const paySharingRequest = await this.getLastPaySharingRequest();
      if (paySharingRequest?.status === 'SUBMITTED') {
        this.set('userStatus', 'request_submitted');
      }
    }
  }

  /**
   * Tries to let the user pay for the current group
   *
   * @returns {boolean}
   */
  async pay() {
    let info;
    let success;
    const paySharingRequestId = await this.getPaySharingRequestId();
    if (!paySharingRequestId) return false;

    for (let i = 0; i < 10; ) {
      info = await this.getPaymentInfo(paySharingRequestId);
      switch (info.status) {
        case 'SUCCESS':
          success = true;
          i = 10;
          break;
        case 'REQUIRES_ACTION':
          success = await this.handlePayment3DSecure(
            info.clientSecret,
            paySharingRequestId
          );
          await new Promise((resolve) => setTimeout(resolve, 4000));
          i = 10;
          break;
        case 'ERROR':
        case 'FAILED':
        case undefined:
          toastStore.error(`Stripe.${(info.errorCode || '').toLowerCase()}`);
          i = 10;
          break;
        default:
          break;
      }
      await new Promise((resolve) => setTimeout(() => resolve(i++), 1000));
    }

    if (!success) return this.generateUUID();

    // Reload the post
    this.reload();

    // Propagate this change
    this.propagate();

    return true;
  }

  /**
   * Invites an user to the current post
   *
   * @param {number} id
   * @returns {boolean}
   */
  async invite(id) {
    const payload = {
      relation: this.userRelation.relation,
      postId: this.id,
      userId: id,
    };
    const response = await Network.post(
      'sharingPost/createSharingInvite',
      payload,
      authStore.token
    );
    if (Err.check(response)) return false;

    return true;
  }

  /**
   * Lets the admin edit his group details
   *
   * @returns {Promise<boolean>}
   */
  async edit() {
    const payload = new window.FormData();
    payload.append(
      'wrapper',
      new Blob(
        [
          JSON.stringify({
            title: this.settings.title,
            description: this.settings.description,
          }),
        ],
        {
          type: 'application/json',
        }
      )
    );
    payload.append('totalSlots', this.settings.totalSlots);
    if (this.settings.file.file) {
      payload.append('file', this.settings.file.file);
      payload.append('changeCover', true);
    } else if (this.settings.file.link) {
      payload.append('coverLink', this.settings.file.link);
      payload.append('changeCover', true);
    }
    payload.append('isPublic', this.settings.isPublic);
    // payload.set('price', this.settings.price)
    payload.append('sharingPostId', this.id);
    const response = await Network.post(
      'sharingPost/update',
      payload,
      authStore.token
    );
    if (Err.check(response)) {
      const message = getErrorMessage(response);
      toastStore.error(
        [
          'Server.VISIBILITY_NOT_UPDATABLE',
          'VISIBILITY_NOT_UPDATABLE',
        ].includes(message)
          ? 'CreateStore.publish.visibilityNotUpdatable'
          : 'EditPostModal.edit'
      );
      return false;
    }

    // Reload the post after editing it
    await this.reload();

    // Propagate this change
    this.propagate();

    return true;
  }

  async editTitle(value) {
    const payload = new window.FormData();
    payload.append(
      'wrapper',
      new Blob(
        [
          JSON.stringify({
            title: value,
            description: this.post.description, // avoid from possible description change
          }),
        ],
        {
          type: 'application/json',
        }
      )
    );
    payload.append('sharingPostId', this.id);
    const response = await Network.post(
      'sharingPost/update',
      payload,
      authStore.token
    );
    if (Err.check(response)) return false;

    // Reload the post after editing it
    await this.reload();

    // Propagate this change
    this.propagate();

    return true;
  }

  /**
   * Deletes a group
   *
   * @returns {boolean}
   */
  async delete() {
    const response = await Network.post(
      'sharingPost/suspendPost',
      this.id,
      authStore.token,
      {
        'content-type': 'application/json',
      }
    );
    if (Err.check(response)) return false;
    // Reload the post after editing it
    await this.reload();
    // Propagate this change
    this.propagate();
    return true;
  }

  /**
   * Cancel post suspension
   *
   * @returns {boolean}
   */
  async cancelSuspension() {
    const response = await Network.post(
      'sharingPost/reActivatePost',
      this.id,
      authStore.token,
      {
        'content-type': 'application/json',
      }
    );
    if (Err.check(response))
      return toastStore.error('PostStore.cancelSuspension');
    // Reload the post after editing it
    await this.reload();
    // Propagate this change
    this.propagate();
    return toastStore.success('PostStore.cancelSuspension');
  }

  /**
   * Updates the autoRenew variable
   *
   * @returns {boolean}
   */
  async updateAutorenew() {
    const response = await Server.post(
      `sharing_post/${this.id}/update_autorenew`,
      null,
      authStore.token
    );
    return !Err.check(response);
  }

  /**
   * Lets the user quit the current group
   *
   * @returns {boolean}
   */
  async quit(payload) {
    let response;

    response = await Network.post('sharingPost/quit', payload, authStore.token);
    if (Err.check(response)) return false;

    // Reload the post after quitting
    this.reload();

    // Propagate this change
    this.propagate();

    return true;
  }

  /**
   * Lets the admin kick a joner
   *
   * @param {number} joinerId
   */
  async kick(joinerId) {
    const payload = {
      joinerId,
      sharingPostId: this.id,
    };
    const kicked = await Network.post(
      'sharingPost/suspendJoiner',
      payload,
      authStore.token
    );
    if (Err.check(kicked)) return false;
    // Reload the post after kicking an user
    await this.reload();
    // Propagate this change
    this.propagate();
    return true;
  }

  /**
   * Sends a notification/email to the joiner that he has to renew his plan
   *
   * @param {number} userId
   */
  async rememberPayment(userId) {
    const payload = {
      receiver: userId,
      sharingPostId: this.id,
    };
    const response = await Network.post(
      '/paymentReminder',
      payload,
      authStore.token
    );
    if (Err.check(response)) return false;

    return true;
  }

  /*
   * Get additional information, just like the wallet history, but inside the post.
   */
  async getSharingPostUserDetails() {
    const response = await Financial.get(
      `wallet/getLastSharingPostPayment?sharingPostId=${this.post?.id}`,
      authStore.token
    );
    if (Err.check(response)) return this.set('postDetails', {});
    this.set('postDetails', response);
    return true;
  }

  /*
   * Cancel a request made to a post you have not joined yet
   */
  async cancel() {
    const response = await Network.post(
      'sharingRequests/cancelSharingRequest',
      this.id,
      authStore.token,
      {
        'content-type': 'application/json',
      }
    );
    if (Err.check(response)) return false;
    // Reload the post after canceling a request
    await this.reload();
    // Propagate this change
    this.propagate();
    return true;
  }

  /**
   * Hide banner for Free4Ever
   *
   * @returns {boolean}
   * */
  async hideF4eBanner(path = null) {
    const result = await Retention.post(
      `promo/hideBanner/${this.post?.id}`,
      {},
      authStore.token
    );
    if (Err.check(result)) return false;
    if (path) navigatorStore.stack(path);
    return true;
  }

  /**
   * Ask for the refund caused by f4e promo
   *
   * @returns {boolean}
   */
  async redeemF4E() {
    const payload = {
      user_promotion_id: this.f4e.userPromotionId,
    };
    const redeem = await Retention.post(
      'promo/apply_promo_credit',
      payload,
      authStore.token
    );
    if (Err.check(redeem)) return toastStore.error('F4EPostBanner.redeem');
    await this.getPromo(this.id, this.adminDetails.id);
    return toastStore.success('F4EPostBanner.redeem');
  }

  goToChat() {
    if (appStore.desktop) window.scrollTo(0, document.body.scrollHeight);
    if (appStore.mobile) navigatorStore.stack(`/post/${postStore.id}/chat`);
  }

  @action
  async getSuggestionsJoiner() {
    if (!this.id) return false;
    const response = await Network.get(
      `/suggested/${this.id}?byAdmin=false`,
      authStore.token
    );
    if (Err.check(response)) return false;
    this.set('suggestions.joiner', Object.values(response) || []);
  }

  @action
  async getSuggestionsAdmin() {
    if (!this.id) return false;
    const response = await Network.get(
      `/suggested/${this.id}?byAdmin=true`,
      authStore.token
    );
    if (Err.check(response)) return false;
    this.set('suggestions.admin', Object.values(response) || []);
  }

  /**
   * Propagate the changes made in a post to the posts containers which are
   * network and sharing stores, once the user will visit those pages again
   * all the data will be fetched again with the new changes
   */
  propagate() {
    // Reset the network/sharing stores to make this change propagate
    const services = networkStore.services;
    networkStore.reset();
    sharingStore.reset();
    networkStore.set('services', services);
    // Reload the promo token to use object
    userStore.getPromoTokenToUse();
    userStore.getCrossSelling();
  }

  /**
   * Reload the post
   */
  async reload() {
    await this.get(this.id);
    await chat.store.init();
  }

  @action generateUUID() {
    const chance = new Chance();
    const uuid = chance.guid();
    this.set('uuid', uuid);
  }

  @action resetCheckout() {
    this.relationship = {
      friend: 0,
      family: 0,
      roommate: 0,
      teammate: 0,
    };
    this.tos = 0;
  }

  get _relationship() {
    return findKey(this.relationship, (r) => r === 1) || '';
  }

  get _relationships() {
    let relationships;
    // We have to know which relationships are disabled for the user during the join request,
    // we do it through this handy method
    const disabled = Post[
      `disabledRelationships${this.isCustom ? 'Custom' : ''}`
    ](this.post.relation);
    // We prepare the relationships array that will be later used for a checkbox form,
    // to find the disabled fields we just have to check if they are in the disabled array obtained before
    relationships = [
      {
        entry: Relation.FRIEND.toLowerCase(),
        title: 'Post.friendTitle',
        disabled: disabled.indexOf(Relation.FRIEND) > -1,
      },
      {
        entry: Relation.FAMILY.toLowerCase(),
        title: 'Post.familyTitle',
        disabled: disabled.indexOf(Relation.FAMILY) > -1,
      },
      {
        entry: Relation.ROOMMATE.toLowerCase(),
        title: 'Post.rommateTitle',
        disabled: disabled.indexOf(Relation.ROOMMATE) > -1,
      },
      {
        entry: Relation.TEAMMATE.toLowerCase(),
        title: 'Post.teammateTitle',
        disabled: disabled.indexOf(Relation.TEAMMATE) > -1,
      },
    ];

    if (isString(this.userRelation) && !isEmpty(this.userRelation)) {
      relationships = filter(
        relationships,
        (r) => r.entry === this.userRelation && !r.disabled
      );

      if (!isEmpty(relationships)) {
        relationships[0].disabled = true;
        this.set(`relationship.${relationships[0].entry}`, 1);
      }
    }

    return relationships;
  }

  get _service() {
    // Get the selected service
    return (
      networkStore.services?.find(
        (service) => service.id === this.post?.serviceGroupId
      ) || {}
    );
  }

  get _plan() {
    // Get the selected service
    return this.plans?.find((plan) => plan.id === this.post?.serviceId) || {};
  }

  /**
   * Returns the service slots as an array of objects for the FormDropdown component
   *
   * @returns {array}
   */
  get _slots() {
    // no plan exit
    if (!this._plan) return [];
    // if plan total availability is not null than user can modify post availability.
    if (this._plan?.totalSlots) {
      const availability = this._plan.totalSlots || 10;
      return [...new Array(availability)].map((_, i) => ({
        input: (i + 1).toString(),
        output: (i + 1).toString(),
        disabled: (i < availability - 1 && this.f4e) || this.isCustom,
      }));
    }
    // otherwise if plan has not set the maximum availability user can't change this value
    // (without the joiner authorization and with the support team) or admins can make pay a
    // different price without he knows(and accept) the change of the price, so we will return as
    // option the one which is already selected as the only selectable.
    return [...new Array(10)].map((_, i) => ({
      input: (i + 1).toString(),
      output: (i + 1).toString(),
      disabled: i !== this.settings?.totalSlots - 1,
    }));
  }

  get isRenew() {
    return (
      ['joiner', 'suspended', 'ended'].includes(this.userStatus) ||
      this.exJoiner
    );
  }

  get daysToRenewal() {
    const start = momentStore.e(this.expiration.expiredDate, 'YYYY-MM-DD');
    return Math.ceil(momentStore.e.duration(start.diff(Date.now())).asDays());
  }

  get f4e() {
    return has(this.promo, 'free4Ever') && this.promo.free4Ever;
  }

  get hasNeverRenewed() {
    const end = momentStore
      .e(this.loggedUser.joinerSince, 'YYYY-MM-DD')
      .add(1, 'M');
    const start = momentStore.e(this.expiration.expiredDate, 'YYYY-MM-DD');
    return momentStore.e.duration(start.diff(end)).asDays() <= 0;
  }

  get isInGroupByOneMonth() {
    const given = moment(this.loggedUser.joinerSince, 'YYYY-MM-DD');
    const current = moment().startOf('day');
    return moment.duration(current.diff(given)).asDays() > 30;
  }

  get isActive() {
    return this.post.status === 'active';
  }

  get isAutoPromo() {
    return this.autoPromo && String(this.autoPromo.postId) === String(this.id);
  }

  get isAutoPromoExpired() {
    const start = momentStore.e(this.autoPromo.renewDay, 'YYYY-MM-DD');
    return momentStore.e.duration(start.diff(Date.now())).asDays() <= 6;
  }

  get isClosed() {
    return this.post.status === 'suspended' || this.post.status === 'ended';
  }

  get isCustom() {
    return has(this._service, 'tag') ? this._service.tag === 'custom' : false;
  }

  get isFull() {
    return this.post.freeSlots === 0;
  }

  get isLoggedUserExpired() {
    const joiner = this.loggedUser;
    if (
      !joiner ||
      !has(joiner, 'expiration.expired') ||
      !has(joiner, 'expiration.date')
    )
      return false;
    return joiner.expiration.expired;
  }

  get isLoggedUserRenewable() {
    return this.loggedUser && this.loggedUser.renewable && this.isUserJoined;
  }

  get isUserOldJoinerCanEnter() {
    return this.isUserOldJoiner && this.isUserNotJoined;
  }

  get isPromoAppliable() {
    return (
      (this.isUserNotJoined || this.isUserAccepted || this.isUserPending) &&
      userStore.hasPromoToken &&
      ((userStore.hasSentRequestWithPromoToken &&
        parseFloat(userStore.user.promoToUse.sharingRequest.sharingPostId) ===
          parseFloat(this.id)) ||
        !userStore.hasSentRequestWithPromoToken)
    );
  }

  get isServiceSubscribable() {
    return this._service.subscribable === true;
  }

  get isSuspended() {
    return this.post.status === 'suspended';
  }

  get isUserAccepted() {
    return this.userStatus === 'inside';
  }

  get isUserOldJoiner() {
    return this.exJoiner === true;
  }

  get isUserConfirm() {
    return this.userStatus === 'confirm';
  }

  get isUserJoined() {
    return this.userStatus === 'joiner';
  }

  get isUserJoinedOrSuspended() {
    return this.isUserJoined || this.isUserSuspended;
  }

  get isUserNotJoined() {
    return this.userStatus === 'visitor';
  }

  get isUserOwned() {
    return this.userStatus === 'owner';
  }

  get currentService() {
    const service = networkStore.services?.find(
      ({ id }) => id === this.post.serviceGroupId
    );
    if (!service) return {};
    return {
      ...service,
      secretsAllowed: Object.values(service.secretsAllowed || {}),
    };
  }

  get activeTpasswordTypes() {
    return this.currentService.secretsAllowed;
  }

  get isUserPending() {
    return this.userStatus === 'pending';
  }

  get isUserSuspended() {
    return this.userStatus === 'suspended';
  }

  async getActivableServicePlans(serviceTag, onlyActivable) {
    const plans = await Network.get(
      `sharingServices/plans?tag=${serviceTag}&onlyActivable=${onlyActivable}`,
      authStore.token
    );
    if (Err.check(plans)) return false;
    this.set('plans', Object.values(plans));
    this.set('status.services', NetworkStatus.SERVICES.SUCCESS);
    return true;
  }

  get link() {
    switch (this.post.serviceTag) {
      case 'netflix':
        return 'https://www.netflix.com/';
      case 'spotify':
        return 'https://www.spotify.com/';
      case 'now_tv':
        return 'https://www.nowtv.com/';
      case 'disney_plus':
      case 'disney_plus_annual_preorder':
        return 'https://www.disneyplus.com/';
      default:
        return '';
    }
  }

  get loggedUser() {
    return find(this.joiners, (j) => j?.id === userStore.user.id) || {};
  }

  get loggedUserExpirationDate() {
    return (
      has(this.loggedUser, 'expiredAt') &&
      momentStore.e(this.loggedUser.expiredAt).format('DD MMMM YYYY')
    );
  }

  get loggedUserExpirationDateMinusSix() {
    return (
      has(this.loggedUser, 'expiredAt') &&
      momentStore
        .e(this.loggedUser.expiredAt)
        .subtract(6, 'days')
        .format('DD MMMM YYYY')
    );
  }

  get isExperience() {
    return JSON.parse(
      userStore.configurations['frontEnd.experiences'] || '[]'
    ).includes(Number(this.post.id));
  }
}

const postStore = new PostStore();
export default postStore;
