import { Hidden } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useRouteMatch } from 'react-router-dom';
import useRouteSettings from 'src/hooks/useRouteSettings';
import MobileHeader from './MobileHeader';
import PathTitles from './PathTitles';

export const EXTRACTED = [
  '/post/:id',
  '/login',
  '/sign-up',
  '/sign-up_2',
  '/verify_email',
  '/verify-email',
  '/reset-password',
  '/promo/tracking',
  '/complete-sign-up',
  '/forgot-password',
  '/pin-login',
  '/pin-auth',
  '/disney-plus-landing',
  '/i/:inviteId/:sourceSystem',
];
const NewHeader: FunctionComponent = ({ children }) => {
  const isExtracted = useRouteMatch(EXTRACTED);
  const { noBack, titleLine } = useRouteSettings();
  return (
    <>
      <Hidden mdUp>
        {isExtracted ? (
          children
        ) : (
          <MobileHeader
            title={<PathTitles />}
            noBack={noBack}
            titleLine={titleLine}
          />
        )}
      </Hidden>
      {children && <Hidden smDown>{children}</Hidden>}
    </>
  );
};

export default NewHeader;
