import { action, observable } from 'mobx'
import { findIndex } from 'lodash'
import Store from '../various/store'

class TopTabStore extends Store {
  @observable active
  @observable collector
  @observable history

  factory() {
    this.active = 0
    this.collector = {}
    this.history = ['contact', 'trust', 'received', 'pending', 'requests', 'private', 'available']
  }

  /**
   * Inserts the given tab inside the history
   *
   * @param {string} tab
   */
  @action push(tab) {
    if (!this.has(tab)) this.history.push(tab)
  }

  /**
   * Initializes a TopTab component
   *
   * @param {string} target
   * @param {string} key
   */
  @action init(target, key) {
    this.collector[target] = key
  }

  /**
   * Checks if the given tab is inside the history
   *
   * @param {string} tab
   */
  has(tab) {
    return findIndex(this.history, h => h === tab) > -1
  }
}

const topTabStore = new TopTabStore()
export default topTabStore
