import { Capacitor } from '@capacitor/core';
import { Market } from '@awesome-cordova-plugins/market';
import { Preferences } from '@capacitor/preferences';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  makeStyles,
  Theme,
  Button,
  fade,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import moment from 'moment';
import ratingStore from 'src/shared/stores/rating';
import toastStore from 'src/shared/stores/toast';
import authStore from 'src/shared/stores/auth';
import trust from './assets/trust.svg';
import starEmpty from './assets/star-empty.svg';
import starFilled from './assets/star-filled.svg';
import StyledRating from '../StyledRating';

const useStyles = makeStyles<Theme, { size?: number }>((theme) => ({
  root: {
    padding: theme.spacing(4, 0),
  },
  container: {
    backgroundColor: fade(theme.palette.primary.main, 0.3),
  },
  title: { fontWeight: 600, fontSize: '21px' },
  body: {
    fontWeight: 500,
    fontSize: '16px',
  },
  starsWrapper: {
    margin: theme.spacing(6, 0),
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  icon: {
    margin: ({ size }) => `0 ${size}px`,
  },
  button: {
    boxShadow: 'none',
    textTransform: 'none',
    height: 55,
    margin: theme.spacing(2, 0),
  },
  disabled: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.common.white} !important`,
    opacity: 0.5,
  },
}));

// ? INFO(suley): The conditions of review
/**
 * Is the device a mobile phone? If yes continue
 * Did user rate before? if no, continue.
 * Did user click later button more than 5 times? If no, continue
 * Did user enter the app more than 5 times? if yes, continue.
 * Is date of last later click older than 5 days? If yes, continue.
 * Show the popup
 */
const AppReview = () => {
  const [ratingRef, setRatingRef] = useState(null);
  const classes = useStyles({
    size: (ratingRef?.clientWidth || 240) / 10 - 24,
  });

  const [open, setOpen] = useState(false);
  const [rating, setRating] = useState(0);

  const isLoggedEnough = async () => {
    // keeps number of times that user enters the app.
    const { value = '0' } = await Preferences.get({ key: 'AppReview.count' });
    await Preferences.set({ key: 'AppReview.count', value: `${+value + 1}` });
    return +value >= 4;
  };

  const isDeclined = async () => {
    // keeps number of times that user clicks the later button.
    const { value = '0' } = await Preferences.get({ key: 'AppReview.later' });
    return +value >= 5;
  };

  // returns that last click is older than 5 days
  const isValid = async () => {
    const { value = Date.now() } = await Preferences.get({ key: 'AppReview.ttl' });
    return +value > Date.now();
  };

  const redirectToStore = () => Market.open('com.togetherprice');

  const onLater = async () => {
    const data = moment().add(5, 'd').valueOf();
    // postpone review poup by 5 days
    await Preferences.set({ key: 'AppReview.ttl', value: `${data}` });
    // increment number of later clicks by 1
    const { value = '0' } = await Preferences.get({ key: 'AppReview.later' });
    await Preferences.set({ key: 'AppReview.later', value: `${+value + 1}` });
    // close popup
    setOpen(false);
  };

  const onSubmit = () => {
    ratingStore.rateApp(rating).then((res) => {
      if (res) {
        // set status as valid and dont show popup anymore
        setOpen(false);
        if (rating >= 4) redirectToStore();
      } else {
        toastStore.error('AppReview.rate');
      }
    });
  };

  useEffect(() => {
    const platform = Capacitor.getPlatform();
    if (authStore.token && platform !== 'web') {
      ratingStore.getHasUserRatedApp().then((hasRated) => {
        if (!hasRated) {
          Promise.all([isLoggedEnough(), isDeclined(), isValid()]).then(
            ([loggedEnough, declined, valid]) => {
              if (loggedEnough && !declined && !valid) {
                setTimeout(() => {
                  setOpen(authStore.token); // if user still logged
                }, 5_000);
              }
            }
          );
        }
      });
    }
  }, [authStore.token]);

  return (
    <Dialog
      classes={{ paper: classes.root, container: classes.container }}
      onClose={undefined}
      open={open}
      fullWidth
      maxWidth='xs'
    >
      <DialogTitle disableTypography>
        <Grid xs={12} container direction='column' alignItems='center'>
          <img src={trust} alt='Trust' />
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid xs={12} container direction='column' alignItems='center'>
          <Grid xs={12} item>
            <Typography
              variant='h5'
              component='h1'
              color='primary'
              align='center'
              className={classes.title}
            >
              <Trans i18nKey='AppReview.title' />
            </Typography>
          </Grid>
          <Grid xs={12} item>
            <Typography
              variant='subtitle1'
              color='textSecondary'
              align='center'
              className={classes.body}
            >
              <Trans i18nKey='AppReview.body' />
            </Typography>
          </Grid>
          <Grid
            xs={12}
            ref={(r) => {
              setRatingRef(r);
            }}
            item
            className={classes.starsWrapper}
          >
            <StyledRating
              icon={
                <img src={starFilled} alt='filled' className={classes.icon} />
              }
              emptyIcon={
                <img src={starEmpty} alt='empty' className={classes.icon} />
              }
              value={rating}
              onChange={(_, r) => setRating(r)}
              precision={1}
            />
          </Grid>
          <Button
            className={classes.button}
            classes={{ disabled: classes.disabled }}
            disabled={rating === 0}
            variant='contained'
            onClick={onSubmit}
            color='primary'
            fullWidth
          >
            <Trans i18nKey='AppReview.submit' />
          </Button>
          <Button
            className={classes.button}
            variant='text'
            color='primary'
            onClick={onLater}
            fullWidth
          >
            <Trans i18nKey='AppReview.later' />
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AppReview;
