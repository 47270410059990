import { Device } from '@capacitor/device';
import { PushNotificationPlugin } from './plugin';

const OneSignalAppID = process.env.REACT_APP_ONESIGNAL;

export class OneSignal {
  instance: Promise<PushNotificationPlugin>;

  constructor() {
    this.instance = Device.getInfo()
      .then(async ({ platform }) => {
        if (platform === 'web') {
          return import('./onesignal-web');
        }
        return import('./onesignal-native');
      }).then(({
        default: plugin,
      }) => plugin.create());
  }

  async init(onDisplay: () => void) {
    return this.instance
      .then((i) => i.initialize(OneSignalAppID, onDisplay))
      .catch((e) => console.error('cannot initialize OneSignal', e));
  }

  isConsentTriggerable(): Promise<boolean> {
    return this.instance
      .then((i) => i.isConsentTriggerable())
      .catch(() => false);
  }

  setUserId(id: number) {
    return this.instance
      .then((i) => i.setUserId(id))
      .catch((e) => console.error('cannot set user id', e));
  }

  showNativePrompt(): Promise<any> {
    return this.instance
      .then((i) => i.showNativePrompt())
      .catch((e) => console.error('cannot show native prompt', e));
  }
}

export default {
  OneSignal,
};
