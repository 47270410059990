import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import toastStore from '../stores/toast';
import Span from '../natives/Span';
import Touchable from '../natives/Touchable';
import Icon from '../natives/Icon';

@observer
class Toast extends Component {
  onPress = () => {
    toastStore.kill();
    if (!isEmpty(toastStore.link)) window.open(toastStore.link, '_blank');
  };

  render() {
    let textColor = '';
    // Based on the specified background color of the toast, set the most fitting text color for it
    switch (toastStore.color) {
      case 'white':
        textColor = 'black';
        break;
      case 'blue':
        textColor = 'white';
        break;
      case 'black':
        textColor = 'white';
        break;
      case 'gray':
        textColor = 'white';
        break;
      case 'light-gray':
        textColor = 'black';
        break;
      default:
        textColor = 'white';
    }
    return (
      <Touchable
        onPress={this.onPress}
        style={`${toastStore.style} Container bg-${toastStore.color} ${textColor} wp100 fr aic jcsb p4 lg-p6 fp at0 zi999999 apl50 at-esait`}
        animated={{
          opacity: toastStore.opacity,
          transform: `translateX(-50%) translateY(-${toastStore.translateY}px)`,
        }}
      >
        {typeof toastStore.hint === 'string' ? (
          <Span
            id={toastStore.hint}
            value={toastStore.hint}
            style={`ts14 lg-ts16 pr3 ${textColor}`}
            inject={
              !isEmpty(toastStore.inject) ? [toastStore.inject] : undefined
            }
          />
        ) : (
          toastStore.hint
        )}
        <Icon name={toastStore.icon} color={textColor} size={24} />
      </Touchable>
    );
  }
}

export default Toast;
