import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router';
import getTokenExpirationTime from 'src/shared/functions/getTokenExpirationTime';
import authStore from 'src/shared/stores/auth';
import toastStore from 'src/shared/stores/toast';

export type ExpirationControlProps = {
  token: string;
};
const ExpirationControl: FC<ExpirationControlProps> = ({ token }) => {
  const history = useHistory();
  useEffect(() => {
    if (token) {
      const expirationTime = getTokenExpirationTime(token);
      if (expirationTime > 0) {
        const timer = setTimeout(() => {
          toastStore.error('Auth.expiredToken');
          history.replace('/logout', {});
        }, expirationTime);
        return () => {
          clearTimeout(timer);
        };
      }
    }
    return () => {};
  }, [token]);
  return <div />;
};

export default observer(() => <ExpirationControl token={authStore.token} />);
