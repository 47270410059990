import { action, computed, observable } from 'mobx';
import {
  cloneDeep,
  filter,
  find,
  findIndex,
  get,
  has,
  isArray,
  isEmpty,
  set,
} from 'lodash';
import { ChatStatus } from '../various/enums';
import Store from '../various/store';
import momentStore from './moment';
import Err from '../utils/error';
import postStore from './post';
import authStore from './auth';
import userStore from './user';
import appStore from './app';

class ChatStore extends Store {
  @observable message;

  @observable messengers;

  @observable paging;

  @observable pinned;

  @observable private;

  @observable resolvers;

  @observable scrollable;

  @observable socket;

  @observable closedByUser = false;

  @observable status;

  @observable writing;

  factory() {
    this.socket = {};
    this.private = {};
    this.message = '';
    this.messengers = [];
    this.writing = {
      id: -1,
      name: '',
    };
    this.pinned = {};
    this.paging = {
      current: 0,
      max: 1,
    };
    this.scrollable = false;
    this.status = ChatStatus.CLOSE;
    this.resolvers = {
      getUnreadMessages: () => {},
      sendMessage: () => {},
      pinMessage: () => {},
      unpinMessage: () => {},
    };
  }

  /**
   * Initializes the WebSocket and binds its listeners
   *
   * @returns {Promise}
   */
  @action
  async init() {
    if (
      this.status === ChatStatus.PENDING ||
      this.status === ChatStatus.OPEN ||
      this.status === ChatStatus.AUTHORIZED
    )
      return true;
    if (!this.isAuthorized) {
      this.set('status', ChatStatus.NOT_AUTHORIZED);
      return false;
    }

    return new Promise((resolve) => {
      try {
        let socket;
        // Set the status to pending
        this.status = ChatStatus.PENDING;
        // Initialize a new WebSocket
        socket = new window.WebSocket(
          process.env.REACT_APP_CHAT_WEBSOCKET,
          authStore.token
        );
        this.socket = socket;
        // Bind the listeners to the function in this store
        socket.onopen = (open) => {
          this.onOpen(open);
          resolve(true);
        };
        socket.onerror = (error) => this.onError(error);
        socket.onmessage = (message) => this.onMessage(message);
        socket.onclose = (close) => this.onClose(close);
      } catch (e) {
        resolve(Err.create(e));
      }
    });
  }

  /**
   * Triggered when the websocket connection is open,
   * inside of it we send a JoinChat message to the server
   * to check if we are authorized or not
   */
  @action onOpen() {
    this.send({ postId: postStore.id }, 'JoinChat');
  }

  /**
   * Trigger when the websocket connection fails
   */
  @action onError(error) {
    this.status = ChatStatus.ERROR;
    return Err.create(error);
  }

  /**
   * Triggered when the websocket receives a new message
   *
   * @param {MessageEvent} payload
   */
  @action
  async onMessage(payload) {
    const {
      message: {
        unreadMessages,
        content,
        sentDate,
        sender,
        status,
        messengers,
        messageId,
        lastSeenMessageId,
        message,
        userId,
        lastPage,
      },
      type,
    } = JSON.parse(payload.data);

    switch (type) {
      case 'JoinChat':
        return this.join(status);
      case 'SendMessage':
        if (status === 'MESSAGE_SENT') {
          this.addMessage(
            {
              id: messageId,
              content,
              sender,
              sentDate,
            },
            true,
            true
          );
          this.setLastSeenMessageId(messageId);
          this.resolvers.sendMessage(messageId);
        } else {
          this.resolvers.sendMessage(Err.create(status));
        }
        break;
      case 'GetUnreadMessages':
        if (status === 'SUCCESS') {
          this.addMessages(unreadMessages);
          this.paging.max = lastPage;
          this.resolvers.getUnreadMessages(unreadMessages);
        } else {
          this.resolvers.getUnreadMessages(Err.create(status));
        }
        break;
      case 'GetMessengers':
        if (status === 'SUCCESS') this.setMessengers(messengers);
        break;
      case 'PinMessage':
        switch (status) {
          case 'PINNED':
            this.setPinnedMessage(messageId);
            this.getPinnedMessage();
            this.resolvers.pinMessage(messageId);
            break;
          case 'UNPINNED':
            this.unpinAllMessages();
            this.getPinnedMessage();
            this.resolvers.unpinMessage(true);
            break;
          default:
            this.resolvers.pinMessage(Err.create(status));
            this.resolvers.unpinMessage(Err.create(status));
            break;
        }
        break;
      case 'SetLastSeenMessage':
        if (status === 'SUCCESS')
          this.updateLastSeenMessage(lastSeenMessageId, userId);
        break;
      case 'UserDisconnected':
        this.setUserDisconnected(userId);
        break;
      case 'MessengerIsWriting':
        if (status === 'SUCCESS') this.setMessengerIsWriting(userId);
        break;
      case 'GetPinnedMessage':
        if (status === 'SUCCESS') this.setPinnedMessage(message);
        break;
      default:
        break;
    }
  }

  commonCloseFunction = () => {
    this.set('socket', {});
    if (this.status !== ChatStatus.NOT_AUTHORIZED) {
      this.set('status', ChatStatus.CLOSE);
    }
    this.setUserDisconnected(userStore.user.id);
  };

  /**
   * Triggered when the websocket is closed
   */
  @action onClose(close) {
    // if the session is closed or terminated by user
    if (!(close.code === 1000 || (close.code === 1006 && this.closedByUser))) {
      this.commonCloseFunction();
    }
    this.set('closedByUser', false);
  }

  @action join(status) {
    switch (status) {
      case 'AUTHORIZED':
        this.status = ChatStatus.AUTHORIZED;
        this.getUnreadMessages();
        this.getMessengers();
        this.getPinnedMessage();
        this.setLastSeenMessageId();
        return true;
      case 'NOT_AUTHORIZED':
        this.status = ChatStatus.NOT_AUTHORIZED;
        this.close();
        return false;
      default:
        return false;
    }
  }

  /**
   * Sends a new message/payload to the websocket
   *
   * @param {Object} message
   * @param {String} type
   */
  send(message, type) {
    let payload;

    payload = {
      message,
      type,
    };
    payload = JSON.stringify(payload);

    this.socket.send(payload);
  }

  /**
   * Send a new message to the websocket
   */
  async sendMessage(message) {
    return new Promise((resolve) => {
      this.send(
        { content: message || this.message, postId: postStore.id },
        'SendMessage'
      );
      this.set('message', '');
      this.set('resolvers.sendMessage', resolve);
    });
  }

  async getUnreadMessages() {
    if (this.paging.current > this.paging.max) return false;

    return new Promise((resolve) => {
      this.send(
        {
          postId: postStore.id,
          lastReceivedMessageId: 0,
          page: this.paging.current,
        },
        'GetUnreadMessages'
      );

      this.set('resolvers.getUnreadMessages', resolve);
      this.paging.current++;
    });
  }

  getMessengers() {
    this.send({ postId: postStore.id }, 'GetMessengers');
  }

  /**
   * Adds a new message locally
   *
   * @param {Object} message
   * @param {boolean} sent
   * @param {boolean} scrollable
   */
  @action addMessage(message, sent, scrollable) {
    let sections;
    let sentDate;
    let timestamp;

    sections = cloneDeep(this.private);

    sentDate = momentStore.e(message.sentDate);
    timestamp = momentStore
      .e(0)
      .set({
        year: sentDate.year(),
        month: sentDate.month(),
        date: sentDate.date(),
      })
      .valueOf();

    if (!has(sections, timestamp)) set(sections, timestamp, [message]);
    else {
      set(
        sections,
        timestamp,
        sent
          ? [...get(sections, timestamp), message]
          : [message, ...get(sections, timestamp)]
      );
    }

    this.private = sections;
    this.scrollable = scrollable;

    return true;
  }

  /**
   * Adds a list of messages locally
   *
   * @param {Array} messages
   */
  @action addMessages(messages) {
    if (!isArray(messages)) return Err.type('ChatStore', 'setMessages');

    messages.forEach((message) =>
      this.addMessage(message, false, this.paging.current === 1)
    );

    return true;
  }

  /**
   * Sets a list of messengers given by the GetMessengers
   *
   * @param {Array} messengers
   */
  @action setMessengers(messengers) {
    if (!isArray(messengers)) return Err.type('ChatStore', 'addMessengers');

    this.messengers = messengers;

    return true;
  }

  @action setPinnedMessage(pinnedMessage) {
    let index;
    let clone;

    this.unpinAllMessages();

    index = {
      section: -1,
      message: -1,
    };

    index.section = findIndex(Object.values(this.private), (section) =>
      find(section, (message) => message.id === pinnedMessage.id)
    );
    index.section = Object.keys(this.private)[index.section];
    if (isEmpty(this.private[index.section])) return false;

    index.message = this.private[index.section].findIndex(
      (message) => message.id === pinnedMessage.id
    );
    if (index.message === -1) return false;

    clone = cloneDeep(this.private);

    clone[index.section][index.message].pinned = true;

    this.private = clone;
    this.pinned = pinnedMessage;
  }

  @action unpinAllMessages() {
    let clone;

    clone = cloneDeep(this.private);

    Object.values(clone).forEach((section) =>
      section.forEach((message) => {
        message.pinned = false;
      })
    );

    this.private = clone;
    this.pinned = {};
  }

  @action updateLastSeenMessage(messageId, userId) {
    let index;

    index = this.messengers.findIndex((messenger) => messenger.id === userId);
    if (index === -1) return false;

    this.messengers[index].lastSeenMessageId = messageId;

    return true;
  }

  @action setUserDisconnected(userId) {
    let index;

    index = this.messengers.findIndex((messenger) => messenger.id === userId);
    if (index === -1) return false;

    this.messengers[index].status = 'OFFLINE';

    return true;
  }

  @action setMessengerIsWriting(userId) {
    this.writing = {
      id: userId,
      name: this.getProfile(userId).name,
    };
    setTimeout(() => {
      this.set('writing', '');
    }, 5000);
  }

  close() {
    try {
      this.socket.close(1000);
    } catch (e) {
      try {
        this.socket.terminate(1000);
      } catch (e) {
        return e;
      }
    } finally {
      this.commonCloseFunction();
      this.set('closedByUser', true);
    }
  }

  async pinMessage(id) {
    return new Promise((resolve) => {
      this.send(
        { postId: postStore.id, messageId: id, pinned: true },
        'PinMessage'
      );
      this.set('resolvers.pinMessage', resolve);
    });
  }

  async unpinMessage(id) {
    return new Promise((resolve) => {
      this.send(
        { postId: postStore.id, messageId: id, pinned: false },
        'PinMessage'
      );
      this.set('resolvers.unpinMessage', resolve);
    });
  }

  setLastSeenMessageId() {
    this.send({ postId: postStore.id }, 'SetLastSeenMessage');
  }

  onWriting() {
    this.send(
      { postId: postStore.id, userId: userStore.user.id },
      'MessengerIsWriting'
    );
  }

  getPinnedMessage() {
    this.send({ postId: postStore.id }, 'GetPinnedMessage');
  }

  /**
   * Gets the profile of the user
   *
   * @param {number} id
   */
  getProfile(id) {
    let index;
    let profile;

    index = this.messengers.findIndex((j) => j.id === id);
    if (index === -1) return {};

    profile = this.messengers[index];

    return profile;
  }

  /**
   * Check if an user is online
   *
   * @param {number} id
   */
  isUserOnline(id) {
    let profile;
    let online;

    profile = this.getProfile(id);
    if (isEmpty(profile)) return false;

    online = profile.status === 'ONLINE';

    return online;
  }

  /**
   * Returns the list of messengers which viewed this message
   *
   * @param {number} messageId
   * @param {number} userId
   * @returns {Array}
   */
  messageSeenBy(messageId, userId) {
    return filter(
      this.messengers,
      (m) =>
        m.lastSeenMessageId === messageId &&
        m.id !== userId &&
        m.id !== userStore.user.id
    );
  }

  getUserLastLogin(id) {
    let user;

    user = this.getProfile(id);
    if (isEmpty(user) || !user.lastLogin) return 'Chat.lastLoginUnknown';

    return momentStore.e(user.lastLogin).fromNow();
  }

  @computed get _messengers() {
    return cloneDeep(this.messengers);
  }

  @computed get _private() {
    return cloneDeep(this.private);
  }

  @computed get _privateKeys() {
    return Object.keys(this.private).sort((a, b) => parseInt(a) - parseInt(b));
  }

  get height() {
    const height = `calc(100vh - 134px${
      !isEmpty(this.pinned) ? ' - 50px' : ''
    }`;
    return appStore.mobile
      ? { minHeight: height, maxHeight: height, height }
      : {};
  }

  get isAuthorized() {
    return postStore.isUserOwned || postStore.isUserJoinedOrSuspended;
  }
}

const chatStoreWS = new ChatStore();
export default chatStoreWS;
