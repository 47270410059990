/* eslint-disable import/no-cycle */
import { computed, observable } from 'mobx';
import { cloneDeep, filter, findIndex, reduce } from 'lodash';
import Err from '../utils/error';
import authStore from './auth';
import Server from '../utils/server';
import { NetworkStatus } from '../various/enums';
import { CategoryModel, ServiceModel } from '../various/models';
import Store from '../various/store';
import Network from '../utils/network';
import Ums from '../utils/ums';
import Paging from '../various/paging';
import userStore from './user';
import toastStore from './toast';
import searchStore from './search';
import navigatorStore from './navigator';
import createStore from './create';
import postStore from './post';
import profileStore from './profile';
import convertToSharingPost from '../functions/convertToSharingPost';

const allTags = [
  'custom',
  'disney_plus',
  'hbo',
  'prime_video',
  'nintendo_switch_online',
  'youtube_premium',
  'amazon_music',
  'now_tv',
  'apple_music',
  'google_play_music',
  'tidal',
  'office_365',
  'steam',
  'xbox_live',
  'dropbox',
  'kaspersky',
  'invictus',
  'karaoke_one',
  'nord_vpn',
  'surfshark',
  'espn',
  'hulu',
  'hbo',
  'youtube_premium',
  'audible',
  'deezer',
  'plex',
  'pure_vpn',
  'one_password',
  'mubi',
  'storytel',
  'gaia',
  'jungle_scout',
  'marvel_unlimited',
  'curiosity_stream',
  'setapp',
  'prime_video',
  'purevpn',
  'onepassword',
  'movistar',
  'orange',
  'nubico',
];
const orderIt = [
  'custom',
  'netflix',
  'spotify',
  'disney_plus',
  'prime_video',
  'nintendo_switch_online',
  'youtube_premium',
  'office_365',
  'audible',
  'gaia',
  'marvel',
  'apple_music',
];

const orderEs = [
  'custom',
  'netflix',
  'spotify',
  'disney_plus',
  'hbo',
  'prime_video',
  'nintendo_switch_online',
  'youtube_premium',
  'orange_tv',
  'apple_music',
  'gaia',
  'office_365',
  'marvel',
  'movistar',
];

const tagOrders = () => {
  switch (userStore.user.country) {
    case 'IT':
      return [...orderIt, ...allTags];
    case 'ES':
      return [...orderEs, ...allTags];
    default:
      return ['custom', 'netflix', 'spotify', ...allTags];
  }
};

const byTag = () => {
  const order = tagOrders();
  const tagOrder = (tag) => order.indexOf(tag) + 1 || 999;
  return ({ tag: tag1 }, { tag: tag2 }) => tagOrder(tag1) - tagOrder(tag2);
};

class NetworkStore extends Store {
  @observable banner;

  @observable userPosts;

  @observable categories;

  @observable feed;

  @observable filter;

  @observable isNetworkFull;

  @observable network;

  @observable networkFull;

  @observable service;

  @observable serviceFull;

  @observable isServiceFull;

  @observable serviceTag;

  @observable servicePlan;

  @observable services;

  @observable status;

  @observable plans;

  @observable f4eServices;

  @observable monthlyPlan;

  @observable pageZeroSize;

  factory() {
    this.feed = new Paging(() => this.getPosts('feed'));
    this.network = new Paging(
      () => this.getPosts('network')
      // () => userStore.fromOnboarding && trackingStore.send('ON_BOARDING', this.network.data.filter((n) => !n.banner)),
    );
    this.service = new Paging(() =>
      this.getServicePosts(
        createStore?._plan?.id
          ? [createStore?._plan?.id]
          : this.plans.map((plan) => plan.id)
      )
    );
    this.serviceFull = new Paging(() => this.getFullServicePosts());
    this.networkFull = new Paging(() => this.getFullPosts('networkFull'));
    this.isNetworkFull = false;
    this.isServiceFull = false;
    this.categories = CategoryModel;
    this.services = ServiceModel;
    this.filter = 'all';
    this.serviceTag = '';
    this.servicePlan = '';
    this.banner = {
      referral: true,
    };
    this.status = {
      services: NetworkStatus.SERVICES.PENDING,
      userPosts: NetworkStatus.USERPOSTS.PENDING,
    };
    this.plans = [];
    this.f4eServices = [];
    this.monthlyPlan = undefined;
    this.userPosts = new Paging(() =>
      this.getUserPosts(profileStore.profile?.id)
    );
  }

  resetService() {
    this.service.getFirstPage();
    // ? INFO(suley): there should not be any full post in first loading
    this.set('serviceFull', new Paging(() => this.getFullServicePosts()));
    this.set('isServiceFull', false);
  }

  /**
   * Gets the network posts filtered by network and category
   *
   * @param {string} target
   * @param {string} category
   * @returns {boolean}
   */
  async getPosts(target) {
    let posts;
    const isNewQuery = userStore.configurations['frontEnd.query.on.es.enabled'];
    // Get all the posts from the server for the specified network and category
    if (isNewQuery) {
      const services = this.services
        .map(({ tag }) => tag)
        .map((item) => `&tags=${item}`)
        .join('');
      posts = await Network.get(
        `carousel/search?direction=desc&props=createdAt&page=${this[target].current}${services}&size=24`,
        authStore.token
      );
      if (Object.values(posts).length < 24) {
        this.set('isNetworkFull', true);
        this.networkFull.getFirstPage();
      }
    } else {
      posts = await Network.get(
        `${target}?page=${this[target].current}`,
        authStore.token
      );
    }
    if (Err.check(posts)) {
      return this.set(
        `status.${target}`,
        NetworkStatus[target.toUpperCase()].ERROR
      );
    }
    if (isNewQuery) {
      posts = convertToSharingPost(posts);
    } else {
      posts = await this.getPostsRating(posts);
    }
    return posts;
  }

  /**
   * Gets the network posts filtered by network and category
   *
   * @param {string} target
   * @param {string} category
   * @returns {boolean}
   */
  async getFullPosts(target) {
    const services = this.services
      .map(({ tag }) => tag)
      .map((item) => `&tags=${item}`)
      .join('');
    const posts = await Network.get(
      `carousel/search?direction=desc&props=createdAt&page=${this[target].current}${services}&size=24&onlyCompleted=true`,
      authStore.token
    );

    if (Err.check(posts)) {
      const realTarget = target.toLowerCase().split('full')[0];
      return this.set(
        `status.${realTarget}`,
        NetworkStatus[realTarget.toUpperCase()].ERROR
      );
    }
    const newPosts = convertToSharingPost(posts);
    return newPosts;
  }

  /**
   * Gets the categories
   *
   * @deprecated
   * @returns {boolean}
   */
  async getCategories() {
    let categories;
    let all;

    // Get all categories from the server
    categories = await Server.get('sharing_posts/categories', authStore.token);
    if (Err.check(categories)) return false;

    // Convert the data object to an array for later use
    categories = Object.values(categories.data);

    // Create a fake All category because it does not exist in the given response
    all = {
      category: 'all',
      description: 'Shows all posts',
      id: 0,
    };

    // Through the unshift method insert it at the start of the categories array
    categories.unshift(all);

    // Save it inside the store
    this.set('categories', categories);

    return true;
  }

  async getUserPosts(id) {
    const posts = await Network.get(
      `/getUserActivePosts?userId=${id}&page=${this.userPosts.current}`,
      authStore.token
    );
    if (Err.check(posts)) {
      return this.set('status.userPosts', NetworkStatus.USERPOSTS.ERROR);
    }

    const newPosts = await this.getPostsRating(posts);

    return newPosts;
  }
  /**
   * Gets the services
   *
   * @returns {boolean}
   */

  /**
   * Gets all the available services
   *
   * @returns {boolean}
   */
  async getAvailableServices() {
    const services = await Network.get(
      'sharingServices/groups',
      authStore.token
    );
    if (Err.check(services)) return false;

    this.saveService(services);

    return true;
  }

  /**
   * Gets all the available services
   * @param0 {string}
   * @param1 {boolean}
   * @returns {Object}
   */
  async getActivableServicePlans(serviceTag, onlyActivable) {
    const plans = await Network.get(
      `sharingServices/plans?tag=${serviceTag}&onlyActivable=${onlyActivable}`,
      authStore.token
    );
    if (Err.check(plans)) return false;

    this.set('plans', Object.values(plans));

    this.set('status.services', NetworkStatus.SERVICES.SUCCESS);

    return true;
  }

  saveService(inServices) {
    // Convert the data object to an array for later use
    const services = Object.values(inServices);
    // Add the save field to the service entries
    for (const i in services) {
      services[i].save = false;
    }

    // Save it inside the store
    this.set('services', services);

    // If the code arrived here then it is a success
    this.set('status.services', NetworkStatus.SERVICES.SUCCESS);
  }

  /**
   * Gets the posts of a specific service
   *
   * @param {number} id
   */
  async getServicePosts(serviceIds = null) {
    if (!serviceIds || serviceIds.length === 0) return false;
    // Get the posts for the specified service from the server
    let posts;
    // the plan that user selected from dropdown
    const plan = createStore?._plans.find(
      ({ output }) => output === this.servicePlan
    )?.tag;

    // ? INFO(suley): First API call is for Elastic Search
    if (userStore.configurations['frontEnd.query.on.es.enabled']) {
      posts = await Network.get(
        `carousel/search?direction=desc&props=createdAt&page=${
          this.service.current
        }&size=24&tags=${plan || this.serviceTag}`,
        authStore.token
      );
      if (Object.values(posts).length < 24) {
        this.set('isServiceFull', true);
        this.serviceFull.getFirstPage();
      }
    } else {
      posts = await Network.get(
        `sharingServices/posts?page=${this.service.current}&tag=${
          this.serviceTag
        }&${serviceIds
          ?.map((id) => ['servicesIds', id]?.join('='))
          ?.join('&')}`,
        authStore.token
      );
    }
    if (Err.check(posts)) return false;
    if (userStore.configurations['frontEnd.query.on.es.enabled']) {
      posts = convertToSharingPost(posts);
    } else {
      posts = await this.getPostsRating(posts);
    }
    if (this.service.current === 0) {
      this.set(
        'pageZeroSize',
        Object.values(filter(posts, (post) => post?.details?.freeSlots > 0))
          .length
      );
    }
    return posts;
  }

  /**
   * Gets the posts of a specific service
   *
   * @param {number} id
   */
  async getFullServicePosts() {
    const plan = createStore?._plans.find(
      ({ output }) => output === this.servicePlan
    )?.tag;

    const posts = await Network.get(
      `carousel/search?direction=desc&props=createdAt&page=${
        this.serviceFull.current
      }&size=24&tags=${plan || this.serviceTag}&onlyCompleted=true`,
      authStore.token
    );
    if (Err.check(posts)) return false;
    const newPosts = convertToSharingPost(posts);
    return newPosts;
  }

  async getPostsRating(posts) {
    let indexes;
    let ratings;

    indexes = reduce(
      posts,
      (r, v) => [...r, ...(v.details.id ? [v.details.id] : [])],
      []
    );
    ratings = await Ums.post(
      'sharing/secrets/getStatus',
      indexes,
      authStore.token
    );
    if (Err.check(ratings)) ratings = {};

    posts = reduce(
      posts,
      (r, v) => [
        ...r,
        {
          ...v,
          rating: ratings[v.details.id],
        },
      ],
      []
    );

    return posts;
  }

  @computed get _servicesForCreate() {
    const services = cloneDeep(this.services);
    if (this.customIndex > -1) {
      services[this.customIndex].name = 'ServiceList.custom';
    }
    return services.sort(
      (service1, service2) =>
        NetworkStore.serviceOrder(service1.tag) -
        NetworkStore.serviceOrder(service2.tag)
    );
  }

  @computed get _servicesForNetwork() {
    const services = cloneDeep(this.services);
    if (this.customIndex > -1) {
      services[this.customIndex].name = 'Services.custom';
    }
    return services.sort(byTag());
  }

  get currentService() {
    return this.services.find((s) => s.tag === this.serviceTag) || {};
  }

  serviceByTag(tag) {
    return this.services.find((s) => s.tag === tag) || {};
  }

  get customIndex() {
    return findIndex(this.services, (s) => s.tag === 'custom');
  }

  get savedServices() {
    return reduce(
      this.services,
      (r, v, k) => {
        if (v.save) r.push(v.id);
        return r;
      },
      []
    );
  }

  async servicesF4E() {
    const availableServices = [];

    Object.keys(userStore.user?.f4e?.details?.serviceGroupTag ?? {}).map((x) =>
      availableServices.push(userStore.user?.f4e?.details?.serviceGroupTag[x])
    );

    this.set(
      'f4eServices',
      filter(this.services, (service) =>
        availableServices.includes(service.tag)
      )
    );

    return true;
  }

  getAvailableF4UServices(tag) {
    this.set(
      'f4eServices',
      filter(this.services, (service) => service.tag === tag)
    );
  }

  async setTrackUserLoop(payload) {
    const response = await Network.post(
      'userTracker/trackLoop',
      payload,
      authStore.token,
      {
        'content-type': 'application/json',
      }
    );
    if (Err.check(response)) return false;
    return response;
    // return {};
  }

  async checkIfPosMustBeHidden(postId) {
    const response = await Network.post(
      'checkIfPosMustBeHidden',
      postId,
      authStore.token,
      {
        'content-type': 'application/json',
      }
    );
    if (Err.check(response)) return false;
    return response;
  }

  async checkIfUserShouldJoinGroup(postId, url, searchBarQuery) {
    if (!postStore.userInvited && (await this.checkIfPosMustBeHidden(postId))) {
      toastStore.error('Post.CantReceiveRequests');
      this.network.reset();
      searchStore.search(searchBarQuery);
      navigatorStore.stack(url);
      await searchStore.posts.getFirstPage();
      return false;
    }
    return true;
  }

  static serviceOrder(tag) {
    switch (tag) {
      case 'custom':
        return 0;
      case 'netflix':
        return 1;
      case 'spotify':
        return 2;
      case 'disney_plus':
      case 'disney_plus_annual_preorder':
        return 3;
      default:
        return 4;
    }
  }

  clear() {
    this.set('monthlyPlan', undefined);
  }

  get _monthlyPlan() {
    return this.monthlyPlan;
  }

  get _plans() {
    return this.plans;
  }
}

const networkStore = new NetworkStore();
export default networkStore;
