import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { get, isString, isEmpty, isArray } from 'lodash';
import t from '../stores/translate';
import Regex from '../various/regex';

@observer
class Span extends Component {
  onPress = (pressable) => {
    if (!pressable) return false;

    const { link } = this.props;
    if (isString(link) && !isEmpty(link))
      window.open(link.match(Regex.link) ? link : get(t.links, link));
  };

  render() {
    const {
      id,
      inject,
      casing,
      mask,
      outerStyle,
      numberOfLines,
      animated,
    } = this.props;

    let { canBePressed } = this.props;

    if (canBePressed === undefined) canBePressed = true;

    let value;
    let style;
    let mpStyle;
    let maskStyle;
    let onPress;

    // Style is equal to the style given in the props
    style = this.props.style
      ? `${this.props.style} ${numberOfLines && 'toe'}`
      : '';

    // Get the translated value through the provided id, if not found show the raw value
    value = t.get(id, inject, casing) || t.casify(this.props.value, casing);
    // MP Style stands for margin and padding style, this is done to avoid using outerStyle when not necessary
    mpStyle = isString(style)
      ? (style.match(Regex.textFind) || []).join(' ')
      : '';
    if (mpStyle) style = style.replace(Regex.textReplace, '');

    return (
      <span id={id} className={`${mpStyle} ${outerStyle}`} style={animated}>
        {isArray(value) ? (
          value.map((v, key) => {
            maskStyle = v.style
              ? `${style.replace(/(tft|tfr|tfsb|tfb)/gm, '')} ${v.style}`
              : style;
            onPress = () => this.onPress(Boolean(v.style) && canBePressed);
            return (
              <span
                onClick={onPress}
                mask={mask}
                key={key}
                className={maskStyle}
              >
                {v.value}
              </span>
            );
          })
        ) : (
          <span className={style}>{value}</span>
        )}
      </span>
    );
  }
}

export default Span;
