import React, { Component } from 'react';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { CircularProgress } from '@material-ui/core';
import Span from '../natives/Span';
import Touchable from '../natives/ts/Touchable';
import Icon from '../natives/Icon';
import Row from '../layout/Row';
import Validate from '../utils/validate';
import toastStore from '../stores/toast';

@observer
class Button extends Component {
  spinner = false;

  mounted = true;

  componentWillUnmount = () => {
    this.mounted = false;
  };

  onPress = async (e) => {
    if (!this.onValidate()) return toastStore.error('Button.press');
    if (this.spinner) return false;

    this.showSpinner();

    try {
      if (_.isFunction(this.props.onPress)) await this.props.onPress(e);
      if (this.mounted) this.hideSpinner();
    } catch (e) {
      if (this.mounted) this.hideSpinner();
      console.error(e);
    }
  };

  showSpinner = () => {
    this.spinner = true;
    this.forceUpdate();
  };

  hideSpinner = () => {
    this.spinner = false;
    this.forceUpdate();
  };

  onValidate = () => {
    let valid = true;

    const { entries, validators, store } = this.props;

    // If any of the needed props is undefined then just abort the validation operation and return true
    if (
      _.isUndefined(entries) ||
      _.isUndefined(validators) ||
      _.isUndefined(store)
    )
      return true;

    // For every entry inside the entries array
    for (let i = 0; i < entries.length; i++) {
      let entry;

      // Save the entry inside this shorter variable
      entry = entries[i];

      // Check if it is valid by using this pre-defined method that will take the corresponding validator, current store and entry, then compare it to the actual valid variable with an AND operator
      valid = Validate.exec(validators[i], store, entry) && valid;
    }

    return valid;
  };

  render() {
    const {
      title,
      icon,
      style,
      textStyle,
      iconStyle,
      align,
      outline,
      radius,
      disabledStyle,
      useNative,
      children,
      inject,
      clipboard,
      append,
      lower,
    } = this.props;
    const { spinner } = this;

    let disabled;
    let textColor;
    let color;
    let size;
    let position;

    // If the disabled props is a boolean then use it to handle the disabled state of the button, if it not the just use the validate method
    disabled = this.props.disabled;

    textColor = this.props.color === 'white' ? 'violet' : 'white';
    color = this.props.color || 'violet';

    textColor = outline ? color : textColor;

    size = this.props.size || 20;
    position = this.props.position || 'rp';

    return (
      <Touchable
        disabled={disabled || spinner}
        onPress={this.onPress}
        onPressDisabled={this.props.onPressDisabled}
        style={`${style} ${position} fr aic ${align || 'jcc'} ${
          radius || 'br1'
        } ${outline && 'bw1'} ${
          !(disabled && String(disabledStyle || '').includes('b-')) &&
          `b${!outline ? 'g' : ''}-${color}`
        } ${disabled ? disabledStyle || 'o50' : 'enabled'}`}
        clipboard={clipboard}
        useNative={useNative}
      >
        {spinner && (
          <Row style='hp100 ap at0 al0 zi1 jcc aic'>
            <CircularProgress color='primary' size={size} />
          </Row>
        )}
        <Row style={`cnp100 fr ${align || 'jcc'}`}>
          {_.isString(icon) ? (
            <Icon
              name={icon}
              color={textColor}
              size={size}
              style={`${iconStyle || 'mr2'} ${spinner && 'o0'}`}
            />
          ) : (
            icon
          )}
          {title ? (
            <Span
              id={title}
              value={title}
              style={`tac fw500 ${textStyle} ${textColor}`}
              outerStyle={spinner && 'o0'}
              casing={!lower && 'upper'}
              inject={inject}
            />
          ) : (
            children
          )}
        </Row>
        {append}
      </Touchable>
    );
  }
}

export default ({ title, onPress, ...props }) => (
  <Button title={title} onPress={onPress} {...props} />
);
