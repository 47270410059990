import React, { PureComponent } from 'react';
import { isString } from 'lodash';
import imageStore from '../stores/image';
import blank from '../assets/app/blank.jpg';
import defaultAvatar from "./avatar.png";
const cloudFrontUrl = 'https://d1ug1wtffjdh7z.cloudfront.net/';
const cleanImageUrl = (url) => (url?.includes(cloudFrontUrl) ? cloudFrontUrl + url?.replace(new RegExp(cloudFrontUrl, 'ig'), '') : url);
class Image extends PureComponent {

  state = {
    uri: this.props.uri,
    loading: true,
    loaded: false,
  }

  componentDidMount = () => this.onMount()

  componentDidUpdate = () => this.onMount()

  onMount = async () => this.setState({ uri: await this.getUri() })

  onError = (e) => {
    if (this.isFromCloudFront) imageStore.decode(e.currentTarget);
  }

  onLoad = () => {
    this.setState({ loading: false, loaded: true });
  }

  render() {
    const {
      contain, filter, style, source,
    } = this.props;
    const { loaded, uri } = this.state;

    return (
      <>
        <img
          alt=""
          src={blank}
          style={{ objectFit: contain ? 'contain' : 'cover', ...filter, ...(loaded && { display: 'none' }) }}
          className={style}
        />
        <img
          alt=""
          src={String(uri).endsWith("avatars/default-avatar.png") ? defaultAvatar : cleanImageUrl(source || uri)}
          onError={this.onError}
          onLoad={this.onLoad}
          style={{ objectFit: contain ? 'contain' : 'cover', ...filter, ...(!loaded && { display: 'none' }) }}
          className={style}
        />
      </>
    );
  }

  async getUri() {
    return this.safeUriFromProps;
  }

  get isFromCloudFront() {
    return this.safeUriFromProps.match(/cloudfront/);
  }

  get safeUriFromProps() {
    return isString(this.props.uri) ? this.props.uri : '';
  }
}

export default (props) => (
  <Image
    {...props}
    uri={cleanImageUrl(props.uri)}
  />
);
