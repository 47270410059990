import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const NordigenFallback: FC = () => {
  const location = useLocation();

  const init = async () => {
    const urlParams = new URLSearchParams(location.search);
    const referenceID = urlParams.get('ref');
    const error = urlParams.get('error') || '';
    const details = urlParams.get('details') || '';
    // share data between pages
    const bc = new BroadcastChannel('nordigen_channel');
    const response = {
      ref: referenceID,
      error,
      details,
    };
    bc.postMessage(JSON.stringify(response));
    bc.close();
    window.close();
  };

  useEffect(() => {
    init();
  }, []);

  return <div />;
};

export default NordigenFallback;
