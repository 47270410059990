import modalStore from '../stores/modal'
import navigatorStore from '../stores/navigator'
import gift from '../assets/autopromo/gift-popup.svg'
import attention from '../assets/autopromo/attention-popup.svg'
import userStore from '../stores/user'
import postStore from '../stores/post'
import { PostStep } from '../various/enums'

class AutoPromoModal {
  constructor({ mode } = {}) {
    const expired = postStore.isLoggedUserExpired || postStore.isAutoPromoExpired
    this.params = {
      ...postStore.autoPromo,
      expired
    }
    switch (mode) {
      case 'info':
        this.openInGroupModal()
        return
      case 'card':
        this.openCardModal()
        return
      case 'home':
      default:
        this.openGoToGroupModal()
        return
    }
  }

  updateAutoPromoOnBoarding() {
    return userStore.updateOnBoarding('auto_promo', {
      view: true
    })
  }
  closeModal = async () => {
    await this.updateAutoPromoOnBoarding()
    modalStore.success('standard')
    return
  }

  goToPost = () => {
    const { postId } = this.params
    modalStore.success('standard')
    navigatorStore.stack(`/post/${postId}`)
  }

  openPay = async () => {
    await this.updateAutoPromoOnBoarding()
    modalStore.success('standard')
    postStore.set('step', PostStep.PAYMENT)
    navigatorStore.stack(`/post/${postStore.id}/payment`)
  }

  updateFunnelDashboard() {
    userStore.updateFunnel('auto_promo_dashboard_popup')
  }

  updateFunnelHowTo() {
    userStore.updateFunnel('auto_promo_how_to_popup')
  }

  openGoToGroupModal() {
    const { postTitle } = this.params
    modalStore.setStandard(
      'AutoPromoModal',
      'title',
      'description',
      gift,
      'button',
      this.goToPost,
      null,
      {
        title: [postTitle]
      },
      'tac',
      null,
      null,
      false,
      undefined,
      undefined,
      false
    )
    this.updateFunnelHowTo()
  }

  openInGroupModal() {
    const { postTitle, expired } = this.params
    const prefix = expired ? '.Expired' : ''
    const image = expired ? attention : gift
    const buttonColor = expired ? 'red' : 'violet'
    modalStore.setStandard(
      `AutoPromoModal.InGroup${prefix}`,
      'title',
      'description',
      image,
      'button',
      expired ? this.openPay : this.closeModal,
      null,
      {
        title: [postTitle],
        description: [expired ? postTitle : 'AutoPromoModal.InGroup.paymentMethod']
      },
      'tal',
      null,
      null,
      false,
      undefined,
      buttonColor,
      expired
    )
    this.updateFunnelDashboard()
  }

  openCardModal() {
    modalStore.set('creditCard', 'pending', null, '', {
      title: 'AutoPromo.CreditCardModal.title',
      description: 'AutoPromo.CreditCardModal.description',
      button: 'AutoPromo.CreditCardModal.button',
      inject: [this.params.postTitle],
      init: () => userStore.updateFunnel('auto_promo_link_card_popup'),
      onSave: () => userStore.updateFunnel('auto_promo_save_card')
    })
  }
}

export default AutoPromoModal
