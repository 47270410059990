import React, { Component } from 'react';
import { isBoolean, isEmpty, isFunction } from 'lodash';
import FormCheckbox from './FormCheckbox';
import FormDropdown from './FormDropdown';
import FormInput from './FormInput';
import Validate from '../utils/validate';
import PromoInput from './PromoInput';
import FormItem from './FormItem';
import GeoSuggestForm from './GeoSuggestForm/GeoSuggestForm';
import FormSwitch from './FormSwitch';

class Form extends Component {
  valid = true;

  getForm = (store, entry, index) => {
    const props = {
      disabled: this.props.disabled,
      store,
      ...entry,
      key: index,
    };
    if (this.props.validators) props.validator = this.props.validators[index];

    if (isBoolean(entry.visible) && !entry.visible) return false;
    switch (entry.form) {
      case 'checkbox':
        return <FormCheckbox {...props} />;
      case 'switch':
        return <FormSwitch {...props} />;
      case 'dropdown':
        return <FormDropdown {...props} />;
      case 'input':
        return <FormInput {...props} />;
      case 'promoinput':
        return <PromoInput {...props} />;
      case 'tooltip':
        return <FormItem {...props} />;
      case 'geosuggest':
        return <GeoSuggestForm {...props} />;
      case 'children':
        return entry.children;
      default:
        return false;
    }
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.validate();
    if (isFunction(this.props.onSubmit) && this.valid) this.props.onSubmit();
  };

  validate = () => {
    this.props.entries.forEach((entry) => {
      this.valid =
        this.valid &&
        Validate.exec(entry.validator, this.props.store, entry.entry, false);
    });
  };

  render() {
    const { id, entries, store, style, inline } = this.props;

    if (isEmpty(entries) && isEmpty(store)) return false;

    return (
      <form
        id={id}
        className={`wp100 df fr fw jcsb ${style}`}
        style={inline}
        onSubmit={this.onSubmit}
      >
        {entries.map((entry, index) => this.getForm(store, entry, index))}
        <button type='submit' hidden />
      </form>
    );
  }
}

export default Form;
