import React, { PureComponent } from 'react';

class Row extends PureComponent {
  render() {
    const { id, animated, style, children, reference, ...remaningProps } =
      this.props;
    return (
      <section
        ref={reference}
        id={id}
        style={animated}
        className={style}
        {...remaningProps}
      >
        {children}
      </section>
    );
  }
}

export default Row;
