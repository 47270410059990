/* eslint-disable class-methods-use-this */
/* eslint-disable consistent-return */
import { action, observable } from 'mobx';
import { Money } from '@bit/together-price.core.monetary';
import {
  InterestsModel,
  PrivacyModel,
  SettingNotificationModel,
  UserSettingModel,
} from '../various/models';
import { SettingStatus } from '../various/enums';
import Server from '../utils/server';
import authStore from './auth';
import Err, { getErrorMessage } from '../utils/error';
import toastStore from './toast';
import appStore from './app';
import t from './translate';
import Store from '../various/store';
import momentStore from './moment';
import userStore from './user';
import Format from '../utils/format';
import Ums from '../utils/ums';
import walletStore from './wallet';
import modalStore from './modal';
import { getI18n } from 'react-i18next';

class SettingStore extends Store {
  @observable activeCountries;

  @observable cities;

  @observable countries;

  @observable darkMode;

  @observable interests;

  @observable notifications;

  @observable privacy;

  @observable status;

  @observable tutorial;

  @observable user;

  @observable mobileBandwidthSavings;

  @observable pinProvider;

  factory() {
    this.cities = [];
    this.countries = [];
    this.activeCountries = [];
    this.darkMode = false;
    this.interests = InterestsModel;
    this.notifications = SettingNotificationModel;
    this.privacy = PrivacyModel;
    this.status = SettingStatus.PENDING;
    this.tutorial = 0;
    this.user = UserSettingModel;
    this.mobileBandwidthSavings = false;
  }

  /**
   * Gets the logged user settings
   *
   * @returns {boolean}
   */
  async get() {
    const { token } = authStore;
    return Promise.all([
      Ums.get('/getUserInfo', token),
      Ums.get('/getProfile', token),
      Ums.get('/getGeneralInfo', token),
      Ums.get('/getInterest', token),
      Ums.get('/getPrivacy', token),
      Ums.get('/getNotify', token),
      Ums.get('/getApplicationSettings', token),
      this.getCountries(),
    ]).then(
      ([
        userInfo,
        profile,
        generalInfo,
        interests,
        privacy,
        notifications,
        applicationSettings,
      ]) => {
        if (
          [profile, generalInfo, interests, privacy, notifications].some(
            Err.check
          )
        ) {
          this.set('status', SettingStatus.ERROR);
          return;
        }
        this.set('interests', interests);
        this.set('notifications', notifications);
        this.set('privacy', {
          ...privacy,
          lang: userStore.user.lang === 'es' ? 'ca' : userStore.user.lang,
        });
        this.set('user', {
          ...userInfo,
          ...profile,
          ...generalInfo,
        });
        this.set(
          'mobileBandwidthSavings',
          applicationSettings.mobileBandwidthSavings
        );
        this.set('status', SettingStatus.SUCCESS);
      }
    );
  }

  async update() {
    return Promise.all([
      this.updateInterests(),
      this.updateAccount(),
      this.updateSettings(),
    ]);
  }

  async updateInterests() {
    const response = await Ums.post(
      'updateInterest',
      {
        filmSeries: this.interests.filmSeries,
        music: this.interests.music,
        photography: this.interests.photography,
        design: this.interests.design,
        tech: this.interests.tech,
        courses: this.interests.courses,
        healtFitness: this.interests.healtFitness,
        sport: this.interests.sport,
        gaming: this.interests.gaming,
        coworking: this.interests.coworking,
        management: this.interests.management,
        marketing: this.interests.marketing,
      },
      authStore.token
    );
    if (Err.check(response)) return toastStore.error('ProfileModal.update');
    return toastStore.success('ProfileModal.update');
  }

  async updateAccount() {
    const payload = {
      name: this.user.name,
      surname: this.user.surname,
      email: this.user.email,
      city: this.user.city,
      countryResidence: this.user.countryResidence,
      countryBirth: this.user.countryBirth,
      postalCode: this.user.postalCode,
      address: this.user.address,
      sex: this.user.sex,
      birthday: momentStore.e.isDate(this.user.birthday)
        ? momentStore.e(this.user.birthday).format('YYYY-MM-DD')
        : this.user.birthday,
      lang: this.privacy.lang,
    };
    const response = await Ums.post('updateAccount', payload, authStore.token);
    if (Err.check(response)) {
      const stripeError = String(response.__e__.code || '').toLowerCase();
      if (stripeError) {
        return toastStore.error(`Stripe.${stripeError}`);
      }
      const errorMessage = getErrorMessage(response);
      return toastStore.error(errorMessage || 'ProfileModal.update');
    }
    // Update the user object inside the userStore for the validations
    userStore.set('user', {
      ...userStore.user,
      ...Format.objectCamelCase(payload),
    });
    return toastStore.success('ProfileModal.update');
  }

  async updateSettings() {
    const response = await Ums.post(
      'updateSettings',
      {
        ...this.notifications,
        ...this.privacy,
        lang: this.privacy.lang,
        mobileBandwidthSavings: this.mobileBandwidthSavings,
      },
      authStore.token
    );
    if (Err.check(response)) return toastStore.error('ProfileModal.update');
    return toastStore.success('ProfileModal.update');
  }

  /**
   * @async
   * Updates the logged user avatar
   *
   * @param {object} image
   * @returns {Promise<boolean>}
   */
  @action
  async changeAvatar(image) {
    // Prepare the payload with the service id and the image that has been chosen before
    const payload = new window.FormData();
    payload.append('data', image.file, image.file.name);
    // Open a toast saying that the image is uploading
    toastStore.info('SettingStore.changeAvatar');
    const response = await Ums.post('updateAvatar', payload, authStore.token, {
      'content-type': 'multipart/form-data',
    });
    if (Err.check(response)) return false;
    this.set('user.profileImage', image.base64);
    userStore.set('user.image', image.base64);
    return true;
  }

  /**
   * Removes the logged user avatar
   *
   * @returns {boolean}
   */
  @action
  async deleteAvatar() {
    const deleted = await Server.put(
      'users/delete_avatar',
      null,
      authStore.token
    );
    if (Err.check(deleted)) return false;
    this.set('user.profileImage', '');
    return true;
  }

  /**
   * Removes the logged user account
   *
   * @returns {boolean}
   */
  @action
  async deleteAccount() {
    const deleted = await Ums.delete('delete', null, authStore.token);

    if (Err.check(deleted)) return toastStore.error(deleted.__e__.message);
    appStore.clearStorage();
    return toastStore.success('DeleteAccountModal.delete',null,null,'');
  }

  @action
  async verifyAndDeleteUser(code) {
    const result = await Ums.post(
      'user/delete/verifyAndDeleteUser',
      {
        userId: userStore.user.userId,
        verificationCode: code,
      },
      authStore.token
    );

    if (Err.check(result)) {
      return toastStore.error(result.__e__.message);
    }

    appStore.clearStorage();
    return toastStore.success('DeleteAccountModal.delete');
  }

  @action
  askForDeleteAccount = async () => {
    const result = await Ums.post(
      'user/delete/askVerificationForDelete',
      {},
      authStore.token
    );

    if (Err.check(result)) {
      return toastStore.error(
        getI18n().t([`Error.${result.__e__.message}`]),
        null,
        null,
        ''
      );
    }

    this.set(
      'pinProvider',
      result.status === 'VERIFICATION_SENT_EMAIL' ? 'email' : 'phone'
    );

    toastStore.success(
      getI18n().t(`Success.DeleteAccountModal.${result.status}`, {
        email: userStore.user.email,
        phone: userStore.user.phone,
      })
    );
    modalStore.pending('deleteAccountValidationModal');
  };

  showDeleteAccountWithMoneyDialog = () => {
    modalStore.idle('dialog');

    return modalStore.setDialog(
      'DeleteAccountModalWithMoney',
      false,
      this.askForDeleteAccount,
      undefined,
      {
        description: [Money.toString(walletStore.availableBalance)],
      }
    );
  };

  showDeleteAccountDialog = () => {
    modalStore.setDialog(
      'DeleteAccountModal',
      false,
      walletStore.availableBalance?.value
        ? this.showDeleteAccountWithMoneyDialog
        : this.askForDeleteAccount
    );
  };

  async toggleDarkMode() {
    if (this.darkMode) {
      document.documentElement.style.setProperty('--white', '#141414');
      document.documentElement.style.setProperty('--black', '#CDCDCC');
      document.documentElement.style.setProperty('--light-gray', '#282828');
      document.documentElement.style.setProperty('--medium-gray', '#232323');
      document.documentElement.style.setProperty('--normal-gray', '#1e1e1e');
      document.documentElement.style.setProperty('--gray', '#191919');
    } else {
      document.documentElement.style.setProperty('--white', '#ffffff');
      document.documentElement.style.setProperty('--black', '#323233');
      document.documentElement.style.setProperty('--light-gray', '#fdfbfb');
      document.documentElement.style.setProperty('--medium-gray', '#f0f0f0');
      document.documentElement.style.setProperty('--normal-gray', '#c5c3c3');
      document.documentElement.style.setProperty('--gray', '#575557');
    }
  }

  async getCountries() {
    return Promise.all([
      import('../assets/countries/names.json'),
      import('../assets/countries/codes.json'),
      this.getActiveCountries(),
    ]).then(([names, codes]) => {
      const countries = names.default.map((name, i) => ({
        input: name,
        output: codes.default[i].toUpperCase(),
      }));
      this.set('countries', countries);
    });
  }

  async getCities() {
    const cities = await import('../assets/countries/cities.json');
    this.set('cities', Object.values(cities));
  }

  async getActiveCountries() {
    const activeCountries = await Ums.get(
      '/settings/getActiveCountries',
      authStore.token
    );
    if (Err.check(activeCountries)) return false;
    this.set('activeCountries', activeCountries);
    return true;
  }

  async getActiveStates(countryCode) {
    return Ums.get(`/settings/getActiveStates/${countryCode}`, authStore.token);
  }

  async v2GetActiveCountries() {
    const activeCountries = await Ums.get(
      '/settings/getActiveCountries',
      authStore.token
    );
    if (Err.check(activeCountries)) return false;
    this.set('activeCountries', activeCountries);
    return activeCountries;
  }

  get _cities() {
    return this.cities.slice();
  }

  get _countries() {
    return this.countries.slice();
  }

  get _genders() {
    return Object.values(t.s.gender);
  }

  get _residenceCountries() {
    return this.activeCountries.map((country) => ({
      input: country.name,
      output: country.code,
    }));
  }

  get birthdayBetween() {
    return {
      min: momentStore.e('1900-01-01'),
      max: momentStore.e(Date.now()).subtract('18', 'years'),
    };
  }
}

const settingStore = new SettingStore();
export default settingStore;
