import { createGenerateClassName, createTheme } from '@material-ui/core/styles';

export const generateClassName = createGenerateClassName({
  disableGlobal: true,
  productionPrefix: 'tp-root',
  seed: 'tp-root',
});

const theme = createTheme({
  palette: {
    primary: {
      main: '#835BF9',
      dark: '#6A3EEA',
      light: '#EAE8FE',
      contrastText: '#F9F9FC',
    },
    secondary: {
      main: '#FB4D68',
      contrastText: '#fff',
    },
  },
  typography: {
    h1: {
      fontFamily: 'Poppins',
      fontWeight: 600,
    },
    h2: {
      fontFamily: 'Poppins',
      fontWeight: 600,
    },
    subtitle1: {
      fontFamily: 'Poppins',
      fontWeight: 500,
    },
    subtitle2: {
      fontFamily: 'Poppins',
      fontWeight: 500,
    },
    body1: {
      fontFamily: 'Poppins',
      fontWeight: 400,
    },
    body2: {
      fontFamily: 'Poppins',
      fontWeight: 400,
    },
    caption: {
      fontFamily: 'Poppins',
      fontWeight: 500,
    },
    overline: {
      fontFamily: 'Poppins',
      fontWeight: 400,
    },
    button: {
      fontFamily: 'Poppins',
      fontWeight: 500,
    },
    allVariants: {
      fontFamily: 'Poppins',
    },
  },
  overrides: {
    MuiButton: {
      sizeLarge: {
        height: '50px',
      },
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': {
          fontFamily: 'Poppins',
        },
      },
    },
  },
  spacing: 4,
});

export default theme;
