import {action, observable} from 'mobx';
import {SupportStatus} from '../various/enums';
import authStore from './auth';
import Err from '../utils/error';
import Server from '../utils/server';
import t from './translate';
import userStore from './user';
import Store from '../various/store';
import walletStore from './wallet';
import Ums from '../utils/ums';
import Rest from '../utils/rest';

class SupportStore extends Store {
  @observable faqs;

  @observable message;

  @observable status;

  @observable type;

  // new flow
  @observable firstAsk;

  @observable secondAsk;

  @observable supportCategories;

  @observable supportSubCategories;

  @observable subject;

  @observable content;

  factory() {
    this.faqs = {
      platform: [],
      post: [],
    };
    this.message = '';
    this.type = '';
    this.status = SupportStatus.PENDING;
    this.firstAsk = '';
    this.supportCategories = [];
    this.supportSubCategories = [];
    this.subject = '';
    this.content = '';
  }

  /**
   * Gets the faqs
   */
  @action
  async getFaqs() {
    const faqs = await Ums.get('/support/faq', authStore.token);
    if (Err.check(faqs)) {
      this.set('status', SupportStatus.ERROR);
      return false;
    }
    // Save the faqs inside the store
    this.set('faqs.platform', faqs);
    // Well, it is a fucking success of course

    this.set('status', SupportStatus.SUCCESS);
    return true;
  }

  async getPostFaqs() {
    const Faqs = await import('../assets/post/faqs.json');
    this.set('faqs.post', Object.values(Faqs.default));
  }

  /**
   * NEW SUPPORT ENDPOINTS
   */

  async sendTicketToSupport(payload) {
    const response = await Ums.post(
      'support/ticket',
      payload,
      authStore.token,
      null
    );
    if (Err.check(response)) return false;
    return true;
  }

  async getSupportCategories(language) {
    const response = await Ums.get(
      `support/categories?language=${language}`,
      authStore.token
    );
    if (Err.check(response)) return false;

    this.set('supportCategories', response);

    return true;
  }

  async getSubSupportCategories(parentCategoryId, language) {
    const response = await Ums.get(
      `support/subcategories?category=${parentCategoryId}&language=${language}`,
      authStore.token
    );
    if (Err.check(response)) return false;

    this.set('supportSubCategories', response);

    return true;
  }

  get _supportCagetogies() {
    return this.supportCategories?.map((category) => ({
      input: category.categoryName,
      output: category.categoryId,
    }));
  }

  get _supportSubCagetogies() {
    return this.supportSubCategories?.map((subCategory) => ({
      input: subCategory.subCategoryName,
      output: subCategory.subCategoryId,
    }));
  }

  @action
  async sendQuestion() {
    let groupId;
    let payload;
    let token;
    let response;

    switch (t.l) {
      case 'it':
        groupId = 43000594258;
        break;
      case 'es':
        groupId = 43000594461;
        break;
      default:
        groupId = 43000594258;
        break;
    }

    payload = {
      description: this.message,
      group_id: groupId,
      subject: `User ID: ${userStore.user.id} - App Version: ${process.env.REACT_APP_VERSION}`,
      email: userStore.user.email,
      priority: 1,
      status: 2,
      tags: [this.type],
    };

    token = `${window.btoa(process.env.REACT_APP_FRESHDESK)}:x`;

    response = await Rest.post(
      'https://togetherprice.freshdesk.com/api/v2/tickets',
      payload,
      token
    );
    if (Err.check(response)) return false;

    this.set('message', '');

    return true;
  }

  async sendLatamPayoutRequest() {
    const response = await Rest.post(
      'https://togetherprice.freshdesk.com/api/v2/tickets',
      {
        description: `Transfer: € ${walletStore.availableBalance.value} - Country: ${walletStore.latam.country}`,
        group_id: 43000608121,
        subject: `User ID: ${userStore.user.id} - App Version: ${process.env.REACT_APP_VERSION}`,
        email: userStore.user.email,
        email_config_id: 43000133447,
        priority: 1,
        status: 2,
      },
      `${window.btoa(process.env.REACT_APP_FRESHDESK)}:x`
    );
    if (Err.check(response)) return false;
    userStore.updateOnBoarding('latam_payout');
    return true;
  }

  get platform() {
    return this.faqs.platform.slice();
  }

  get post() {
    return this.faqs.post.slice();
  }

  get types() {
    return Object.values(t.s.contactTypes);
  }

  clear() {
    this.set('firstAsk', '');
    this.set('secondAsk', '');
    this.set('supportCategories', []);
    this.set('supportSubCategories', []);
    this.set('subject', '');
    this.set('content', '');
  }
}

const supportStore = new SupportStore();
export default supportStore;
