import React from 'react';
import { Switch as DefaultSwitch, withStyles } from '@material-ui/core';

type Props = {
  value: number | boolean;
  disabled?: boolean;
  onPress?: () => void;
  color?: 'primary' | 'secondary' | 'default';
  size?: 'small' | 'medium';
};
const PurpleSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: '#835BF9',
    },
    '&$checked + $track': {
      backgroundColor: '#835BF9',
    },
  },
  checked: {},
  track: {},
})(DefaultSwitch);
const Switch = ({
  value,
  disabled,
  onPress,
  color = 'default',
  size = 'medium',
}: Props) => (
  <PurpleSwitch
    disabled={disabled}
    color={color}
    size={size}
    checked={!!value}
    onChange={onPress}
  />
);

export default Switch;
