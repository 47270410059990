import { isFunction } from 'lodash';
import thumbsUp from '../assets/post/thumbs-up-circle.png';
import modalStore from '../stores/modal';
import navigatorStore from '../stores/navigator';
import notificationStore from '../stores/notification';
import postStore from '../stores/post';
import toastStore from '../stores/toast';

export type SendRequestOptions = {
  id: string;
  type: string;
  action: string;
  sharingRequestId?: number;
  sharingInviteId?: number;
  userId?: number;
  sharingPostId?: number;
  callback?: () => void;
};

const sendRequest = async ({
  id,
  action,
  type,
  sharingInviteId,
  sharingRequestId,
  userId,
  callback,
  sharingPostId,
}: SendRequestOptions) => {
  const response = await notificationStore.setRequest(
    id,
    type,
    action,
    sharingRequestId,
    sharingInviteId,
    userId
  );
  if (!response) return false;

  if (isFunction(callback)) callback();

  if (
    action === 'accept' &&
    type ===
      'App\\Notifications\\Admin\\FastCheckout\\FastCheckoutSharingRequest'
  ) {
    modalStore.setStandard(
      'RequestAcceptedModal',
      'title',
      'description',
      thumbsUp,
      'button',
      () => {
        modalStore.success('standard');
        postStore.goToChat();
      }
    );
    navigatorStore.stack(`/post/${sharingPostId}`);
  }
  if (
    action === 'accept' &&
    (type === 'App\\Notifications\\Joiner\\NewSharingInvite' ||
      type === 'App\\Notifications\\Admin\\FakeSharingRequest')
  ) {
    navigatorStore.stack(`/post/${sharingPostId}`);
  }

  if(type === 'App\\Notifications\\NewContactRequest'){
    toastStore.success(`Request.${action}`)
  }
  return true;
};

export default sendRequest;
