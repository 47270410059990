import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import captchaStore from '../stores/captcha';

const callIfDef = (fn: () => any) => {
  if (typeof fn === 'function') return fn();
  return undefined;
};

export type AppReCaptchaProps = {
  onExpired?: () => void,
  onErrored?: () => void,
  onChange?: () => void,
}
const AppReCaptcha: React.FC<AppReCaptchaProps> = ({ onErrored, onChange, onExpired }) => (
  <ReCAPTCHA
    ref={(ref) => captchaStore.setRecaptchaHandler(ref)}
    sitekey={process.env.REACT_APP_RECAPTCHA}
    size="invisible"
    onErrored={onErrored}
    onExpired={() => {
      captchaStore.resetRecaptchaHandler();
      callIfDef(onExpired);
    }}
    onChange={() => {
      captchaStore.resetRecaptchaHandler();
      callIfDef(onChange);
    }}
  />
);

export default AppReCaptcha;
